import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-table-row-container",
    templateUrl: "./table-row-container.component.html",
    styleUrls: ["./table-row-container.component.scss"]
})
export class TableRowContainerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
