import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { interval } from "rxjs";
import { delay, map, startWith, switchMap } from "rxjs/operators";
import { NotificationService } from "src/app/notification/services/notification.service";

import {
    LoadAllNotificationsSuccessful,
    LoadSystemMessagesSuccessful,
    MarkNotificationsAsReadSuccessful,
    NotificationActions,
    NotificationActionTypes,
} from "../actions/notification.actions";
import { LoadNotificationsSuccessful } from "./../actions/notification.actions";

@Injectable()
export class NotificationEffects {

    @Effect()
    loadShortNotifications$ = this.actions$.pipe(
        ofType(NotificationActionTypes.LoadShortNotificationList),
        switchMap(() =>
            interval(60000)
            .pipe(
                startWith(0),
                switchMap(() => this.notificationService.getNotifications()
                    .pipe(
                        map((result) => new LoadNotificationsSuccessful(result))
                    )
                )
            )
        )
    );

    @Effect()
    loadSystemMessages$ = this.actions$.pipe(
        ofType(NotificationActionTypes.LoadSystemMessages),
        switchMap(() =>
            interval(60000)
            .pipe(
                startWith(0),
                switchMap(() => this.notificationService.getSystemMessages()
                    .pipe(
                        map((result) => new LoadSystemMessagesSuccessful(result))
                    ))
        )
    ));


    @Effect()
    loadAllNotifications$ = this.actions$.pipe(
        ofType(NotificationActionTypes.LoadNotifications),
        switchMap((action) => this.notificationService.getNotificationsForPage(action.page)
            .pipe(
                map(result => new LoadAllNotificationsSuccessful(result))
            )
        )
    );

    @Effect()
    markNotificationsAsRead$ = this.actions$.pipe(
        ofType(NotificationActionTypes.MarkNotificationsAsRead),
        switchMap((action) => this.notificationService.markNotificationsAsViewed(action.notifications)
            .pipe(
                delay(5000),
                map(result => new MarkNotificationsAsReadSuccessful())
            )
        )
    );

    constructor(private actions$: Actions < NotificationActions > , private notificationService: NotificationService) {}
}
