import { Action } from "@ngrx/store";
import { SystemMessage } from "src/app/admin/models/systemMessage.model";
import { Notification } from "src/app/notification/models/notification.model";

export enum NotificationActionTypes {
    LoadShortNotificationList = "[Notification] Load Notifications",
        LoadNotificationsSuccessful = "[Notification API] Load Notifications Successful",
        LoadNotifications = "[Notification] Loading Notifications",
        LoadAllNotificationsSuccessful = "[Notification API] Load Notifications for View",
        LoadSystemMessages = "[System Messages] Loading System Messages",
        LoadSystemMessagesSuccessful = "[System Messages] System Messages Loaded",
        MarkNotificationsAsRead = "[Notification List] Mark Notifications As Read",
        MarkNotificationsAsReadSuccessful = "[Notification API] Mark Notifications Successful",

}

export class LoadShortNotificationList implements Action {
    readonly type = NotificationActionTypes.LoadShortNotificationList;
}

export class LoadNotifications implements Action {
    readonly type = NotificationActionTypes.LoadNotifications;
    constructor(public page: number) {}
}

export class LoadNotificationsSuccessful implements Action {
    readonly type = NotificationActionTypes.LoadNotificationsSuccessful;
    constructor(public payload: any) {}
}

export class LoadAllNotificationsSuccessful implements Action {
    readonly type = NotificationActionTypes.LoadAllNotificationsSuccessful;
    constructor(public payload: any) {}
}

export class LoadSystemMessages implements Action {
    readonly type = NotificationActionTypes.LoadSystemMessages;
    constructor() {}
}

export class LoadSystemMessagesSuccessful implements Action {
    readonly type = NotificationActionTypes.LoadSystemMessagesSuccessful;
    constructor(public systemMessages: SystemMessage[]) {}
}

export class MarkNotificationsAsRead implements Action {
    readonly type = NotificationActionTypes.MarkNotificationsAsRead;
    constructor(public notifications: Notification[]) {}
}

export class MarkNotificationsAsReadSuccessful implements Action {
    readonly type = NotificationActionTypes.MarkNotificationsAsReadSuccessful;
    constructor() {}
}

export type NotificationActions = LoadShortNotificationList |
    LoadNotifications |
    LoadNotificationsSuccessful |
    LoadAllNotificationsSuccessful |
    LoadSystemMessages |
    LoadSystemMessagesSuccessful |
    MarkNotificationsAsRead |
    MarkNotificationsAsReadSuccessful;
