import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotificationViewComponent } from "src/app/notification/pages/notification-view/notification-view.component";

import { AuthGuard } from "../shared/guards/auth-guard.service";

const routes: Routes = [{
    path: "",
    component: NotificationViewComponent,
    canActivate: [AuthGuard]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NotificationRoutingModule {}
