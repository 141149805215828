import {InvoiceStatuses} from "src/app/shared/enums/statuses.enum";
import {PsaClientDto} from "src/app/shared/models/psa-client-dto";

import {DickerDataClient} from "./dickerDataClient.model";
import {SubscriptionPsaMappingData} from "./subscriptionPsaMappingData.model";

export class Invoice {
    id: number;
    name: string;
    createdAt: string;
    syncedAt: string;
    invoiceStatus: InvoiceStatuses;
    clients: InvoiceClient[];
    isSyncable: boolean;
    invoiceType: InvoiceType;
}

export class InvoiceClient {
    dickerDataClientDetails: DickerDataClient;
    mappedInvoiceDetailLineItems: MappedInvoiceDetailLineItems[];

    // reusing this model but be careful some of the property names
    // might be different from the payload given by DickerData
    psaClientDetails: PsaClientDto;
}

export class MappedInvoiceDetailLineItems {
    tenantId: string;
    calculatedSubscriptionPrice: number;
    azureCost: number;
    shippedQty: number;
    stockCode: string;
    stockDescription: string;
    subscriptionPsaMappingData: SubscriptionPsaMappingData;
    type: number;
    unitPrice: number;
    usageEndDate: string;
    usageStartDate: string;
    cycleUnitPrice: number;
    termStart: string;
    termEnd: string;
    azureItems: MappedInvoiceDetailLineItems[];
    isExpanded = false;
}

export enum InvoiceType {
    NCE = 0,
    Azure = 1
}

