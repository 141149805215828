import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { getSigninUser, LoginState } from "src/app/login/store/reducers/login.reducer";
import { User } from "src/app/shared/models/user.model";
import {UserTypes} from "../enums/types.enum";



@Injectable({
    providedIn: "root"
})
export class AdminConsoleGuard implements CanActivate {
    constructor(private authStore: Store<LoginState>) { }

    canActivate(): Observable<boolean> {
        return this.authStore.select(getSigninUser)
            .pipe(
                map((user: User) => {
                    return canAccessAdminConsole(user.userType);
                })
            );
    }
}

export function canAccessAdminConsole(userType: number): boolean {
    return userType === UserTypes.MspAdmin;
}
