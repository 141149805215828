import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ManualReportLastRun, Msp, VendorTypes } from "src/app/msp/models/msp.model";
import { MspUserLists } from "src/app/msp/models/user-lists.model";

import { AutotaskAllocationCode } from "../../models/autotask-allocation-code.model";
import { MspAutotaskConfig } from "../../models/msp-autotask-config.model";
import { XeroAccount } from "../../models/xero-account.model";
import { XeroContact } from "../../models/xero-contact.model";
import { XeroTenant } from "../../models/xero-tenant.model";
import * as actions from "../actions/company-settings.actions";
import { ApiCredentials, apiTypes } from "./../../models/msp-api-details.model";
import { MspXeroConfig } from "./../../models/msp-xero-config.model";

export const companySettingsFeatureKey = "companySettings";

export const companySettingsAdapter = createEntityAdapter<ApiCredentials>({
    selectId: api => api.apiType
});

export interface CompanySettingsState extends EntityState<ApiCredentials> {
    loading: boolean;
    lodingAutotaskMaterialCodes: boolean;
    loadingXeroContacts: boolean;
    autotaskMaterialCodes: AutotaskAllocationCode[];
    autotaskConfig: MspAutotaskConfig;
    isXeroAuthenticated: boolean;
    isXeroFullySetup: boolean;
    mspCreationStage: string;
    mspUsersForUserList: MspUserLists;
    msp: Msp;
    reportConfigs: any[];
    mspCreationStages: string[];
    manualReportLastRun: ManualReportLastRun;
    xeroConfig: MspXeroConfig;
    xeroContacts: XeroContact[];
    xeroTenants: XeroTenant[];
    xeroAccounts: XeroAccount[];
}

export const initialState: CompanySettingsState = {
    ids: [],
    entities: {},
    loading: false,
    lodingAutotaskMaterialCodes: false,
    loadingXeroContacts: false,
    autotaskMaterialCodes: [],
    autotaskConfig: null,
    isXeroAuthenticated: false,
    isXeroFullySetup: undefined,
    msp: new Msp(),
    mspCreationStage: "",
    mspCreationStages: [],
    manualReportLastRun: new ManualReportLastRun(),
    mspUsersForUserList: {
        whiteListed: [],
        blackListed: []
    },
    reportConfigs: [],
    xeroConfig: null,
    xeroContacts: [],
    xeroTenants: [],
    xeroAccounts: []
};

export function companySettingsReducer(state = initialState, action: actions.CompanySettingsActions): CompanySettingsState {
    // Creating a new msp for updating as state.msp is read-only object.
    const newMsp: Msp = JSON.parse(JSON.stringify(state.msp));
    switch (action.type) {
        case actions.CompanySettingsActionTypes.setupNewMspCreation:
            return {
                ...state,
                loading: false,
                mspCreationStage: state.mspCreationStages[0]
            };

        case actions.CompanySettingsActionTypes.setupPartiallyCreatedMsp:
            // Figure out which step they are up to and set it to that. (will need to use the billing state ie the msp)

            const credentialTypes = [];
            let mspStage = "dickerdata";


            for (const key in state.entities) {
                if (state.entities.hasOwnProperty(key)) {
                    const element = state.entities[key];
                    credentialTypes.push(element.apiType);
                }
            }

            if (false === credentialTypes.includes(apiTypes.DickerData) && state.msp.vendorType === VendorTypes.dickerData) {
                mspStage = "dickerdata";
            } else if (state.msp.partnerCenterIntegrated === false) {
                mspStage = "microsoftCredentials";
            } else if (false === credentialTypes.includes(apiTypes.ConnectWise) && false === credentialTypes.includes(apiTypes.AutoTask)) {
                mspStage = "psa";
            } else {
                mspStage = "users";
            }

            return {
                ...state,
                loading: false,
                mspCreationStage: mspStage
            };

        case actions.CompanySettingsActionTypes.LoadCompanySettings:
            return {
                ...state,
                loading: true
            };

        case actions.CompanySettingsActionTypes.createMicrosoftDelegateDetails:
        case actions.CompanySettingsActionTypes.CreateAutoTaskCredentials:
        case actions.CompanySettingsActionTypes.CreateConnectwiseCredentials:
        case actions.CompanySettingsActionTypes.CreateMspDetails:
        case actions.CompanySettingsActionTypes.AuthPartnerCenter:
        case actions.CompanySettingsActionTypes.CreateMicrosoftMsp:
        case actions.CompanySettingsActionTypes.AddOrUpdateNceSettings:
        case actions.CompanySettingsActionTypes.UpdateAllowedUserList:
            return {
                ...state,
                loading: true
            };

        case actions.CompanySettingsActionTypes.LoadCompanySettingsSuccess:
            return companySettingsAdapter.addAll(action.companySettings, {
                ...state,
                loading: false
            });

        case actions.CompanySettingsActionTypes.SetStages:
            return {
                ...state,
                mspCreationStages: action.stages
            };

        case actions.CompanySettingsActionTypes.GetManualReportLastRunsSuccessful:
            return {
                ...state,
                manualReportLastRun: action.manualLastRuns
            };

        case actions.CompanySettingsActionTypes.GetUserListSuccessful:
            return {
                ...state,
                loading: false,
                mspUsersForUserList: action.userList
            };

        case actions.CompanySettingsActionTypes.GetMspDetailsSuccess:
            return {
                ...state,
                msp: action.msp
            };

        case actions.CompanySettingsActionTypes.GetReportNotificationConfigSuccessful:
            return {
                ...state,
                reportConfigs: action.configs
            };

        case actions.CompanySettingsActionTypes.LoadCompanySettingsFailed:
            return {
                ...state,
                loading: false
            };

        case actions.CompanySettingsActionTypes.CreateMspDetailsSuccess:
            return {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            };

        case actions.CompanySettingsActionTypes.AuthPartnerCenterSuccess:
            return {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            };

        case actions.CompanySettingsActionTypes.CreateMicrosoftMspSuccess:
            return {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            };

        case actions.CompanySettingsActionTypes.TestApplicationAccessSuccess:
            return {
                ...state,
                loading: false
            };

        case actions.CompanySettingsActionTypes.CreateAutoTaskCredentialsSuccess:
            return companySettingsAdapter.addOne(action.credential, {
                ...state,
                loading: false
            });

        case actions.CompanySettingsActionTypes.CreateConnectwiseCredentialsSuccess:
            return companySettingsAdapter.addOne(action.credential, {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            });

        case actions.CompanySettingsActionTypes.CreateMicrosoftCredentialsSuccess:
            return companySettingsAdapter.addOne(action.credential, {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            });

        case actions.CompanySettingsActionTypes.GetAutotaskMaterialCodes:
            return {
                ...state,
                lodingAutotaskMaterialCodes: true
            };

        case actions.CompanySettingsActionTypes.GetAutotaskMaterialCodesSuccess:
            return {
                ...state,
                lodingAutotaskMaterialCodes: false,
                autotaskMaterialCodes: action.materialCodes,
            };

        case actions.CompanySettingsActionTypes.GetAutotaskMaterialCodesFailed:
            return {
                ...state,
                lodingAutotaskMaterialCodes: false
            };

        case actions.CompanySettingsActionTypes.GetAutotaskMspConfig:
            return {
                ...state
            };

        case actions.CompanySettingsActionTypes.GetAutotaskMspConfigSuccess:
            return {
                ...state,
                autotaskConfig: action.autotaskConfig,
            };

        case actions.CompanySettingsActionTypes.GetAutotaskMspConfigFailed:
            return {
                ...state
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateAutotaskMspConfig:
            return {
                ...state
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateAutotaskMspConfigSuccess:
            return {
                ...state,
                autotaskConfig: action.autotaskConfig,
                mspCreationStage: getNextStage(state)
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateAutotaskMspConfigFailed:
            return {
                ...state
            };

        case actions.CompanySettingsActionTypes.GetXeroContacts:
            return {
                ...state,
                xeroContacts: [],
                loadingXeroContacts: true
            };

        case actions.CompanySettingsActionTypes.GetXeroContactsSuccess:
            return {
                ...state,
                xeroContacts: action.xeroContacts,
                loadingXeroContacts: false
            };

        case actions.CompanySettingsActionTypes.GetXeroContactsFailed:
            return {
                ...state,
                xeroContacts: [],
                loadingXeroContacts: false
            };

        case actions.CompanySettingsActionTypes.GetXeroAccounts:
            return {
                ...state,
                xeroAccounts: [],
                loadingXeroContacts: true
            };

        case actions.CompanySettingsActionTypes.GetXeroAccountsSuccess:
            return {
                ...state,
                xeroAccounts: action.xeroAccounts,
                loadingXeroContacts: false
            };

        case actions.CompanySettingsActionTypes.GetXeroAccountsFailed:
            return {
                ...state,
                xeroAccounts: [],
                loadingXeroContacts: false
            };

        case actions.CompanySettingsActionTypes.GetXeroTenants:
            return {
                ...state,
                isXeroAuthenticated: undefined
            };

        case actions.CompanySettingsActionTypes.GetXeroTenantsSuccess:
            return {
                ...state,
                xeroTenants: action.xeroTenants,
                isXeroAuthenticated: true
            };

        case actions.CompanySettingsActionTypes.GetXeroTenantsFailed:
            return {
                ...state,
                isXeroAuthenticated: false
            };

        case actions.CompanySettingsActionTypes.GetXeroMspConfig:
            return {
                ...state,
            };

        case actions.CompanySettingsActionTypes.GetXeroMspConfigSuccess:
            return {
                ...state,
                xeroConfig: action.xeroConfig
            };

        case actions.CompanySettingsActionTypes.GetXeroMspConfigFailed:
            return {
                ...state,
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateXeroMspConfig:
            return {
                ...state,
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateXeroMspConfigSuccess:
            let mspState = state.mspCreationStage;
            const xeroConfig = action.xeroConfig;

            if (xeroConfig.tenantId && xeroConfig.contactId) {
                mspState = getNextStage(state);
            }

            return {
                ...state,
                xeroConfig: action.xeroConfig,
                mspCreationStage: mspState
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateXeroMspConfigFailed:
            return {
                ...state,
            };

        case actions.CompanySettingsActionTypes.IsXeroFullySetup:
            return {
                ...state,
                isXeroFullySetup: undefined
            };

        case actions.CompanySettingsActionTypes.IsXeroFullySetupSuccess:
            return {
                ...state,
                isXeroFullySetup: action.isFullySetup,
            };

        case actions.CompanySettingsActionTypes.IsXeroFullySetupFailed:
            return {
                ...state,
            };

        case actions.CompanySettingsActionTypes.CreateDickerDataDetailsSuccess:
            return companySettingsAdapter.addOne(action.credential, {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            });

        case actions.CompanySettingsActionTypes.createMcrosoftDelegateDetailsSuccess:
            return {
                ...state,
                mspCreationStage: getNextStage(state),
                loading: false
            };

        case actions.CompanySettingsActionTypes.UpdateAllowedUserListSuccess:
            return {
                ...state,
                mspCreationStage: getNextStage(state),
                mspUsersForUserList: action.users,
                loading: false
            };

        case actions.CompanySettingsActionTypes.UpdateMspDetailsSuccess:
            return {
                ...state,
                msp: action.msp
            };

        case actions.CompanySettingsActionTypes.UpdateBillingStyle:
            return {
                ...state,
                loading: true
            };

        case actions.CompanySettingsActionTypes.AddOrUpdateNceSettingsSuccess:
            newMsp.mspNceSettings = action.mspNceSettings;
            return {
                ...state,
                msp: newMsp,
                loading: false
            };

        case actions.CompanySettingsActionTypes.UpdateBillingStyleSuccessful:
            newMsp.billingStyle = action.billingStyle.billingStyle;
            newMsp.isProrateEnabled = action.billingStyle.isProrated;
            return {
                ...state,
                msp: newMsp,
                loading: false,
                mspCreationStage: getNextStage(state)
            };

        case actions.CompanySettingsActionTypes.UpdateAutoTaskDetails:
        case actions.CompanySettingsActionTypes.UpdateConnectwiseDetails:
        case actions.CompanySettingsActionTypes.UpdateDickerDataCredentials:
        case actions.CompanySettingsActionTypes.UpdateMicrosoftCredentials:
            return companySettingsAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        default:
            return state;
    }
}

export const getCompanySettingsState = createFeatureSelector<CompanySettingsState>("companySettings");

export const getLoading = (state: CompanySettingsState) => state.loading;

export function getNextStage(state: CompanySettingsState): string {
    const currentStage = state.mspCreationStage;
    const currentStageIndex = state.mspCreationStages.findIndex(x => x === currentStage);
    return state.mspCreationStages[currentStageIndex + 1];
}

export const isLoading = createSelector(
    getCompanySettingsState,
    getLoading
);

export const islodingAutotaskMaterialCodes = createSelector(
    getCompanySettingsState,
    (state => state.lodingAutotaskMaterialCodes)
);

export const isLoadingXeroContacts = createSelector(
    getCompanySettingsState,
    (state => state.loadingXeroContacts)
);

export const isXeroAuthenticated = createSelector(
    getCompanySettingsState,
    (state => state.isXeroAuthenticated)
);

export const isXeroFullySetup = createSelector(
    getCompanySettingsState,
    (state => state.isXeroFullySetup)
);

export const getAutotaskMaterialCodes = createSelector(
    getCompanySettingsState,
    (state => state.autotaskMaterialCodes)
);

export const getAutotaskConfig = createSelector(
    getCompanySettingsState,
    (state => state.autotaskConfig)
);

export const getCurrentMspCreationStage = createSelector(
    getCompanySettingsState,
    (state => state.mspCreationStage)
);

export const getLastRunsForManualReports = createSelector(
    getCompanySettingsState,
    (state => state.manualReportLastRun)
);

export const getUserLists = createSelector(
    getCompanySettingsState,
    (state => state.mspUsersForUserList)
);

export const getMsp = createSelector(
    getCompanySettingsState,
    (state => state.msp)
);

export const getReportConfigs = createSelector(
    getCompanySettingsState,
    (state => state.reportConfigs)
);

export const getStages = createSelector(
    getCompanySettingsState,
    (state => state.mspCreationStages)
);

export const getXeroConfig = createSelector(
    getCompanySettingsState,
    (state => state.xeroConfig)
);

export const getXeroContacts = createSelector(
    getCompanySettingsState,
    (state => {
        const xeroContacts = [...state.xeroContacts];
        const sortedContacts = xeroContacts.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });

        return sortedContacts;
    })
);

export const getXeroTenants = createSelector(
    getCompanySettingsState,
    (state => {
        const xeroTenants = [...state.xeroTenants];
        const sortedTenants = xeroTenants.sort((a, b) => {
            const aName = a.tenantName.toLowerCase();
            const bName = b.tenantName.toLowerCase();

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });

        return sortedTenants;
    })
);

export const getXeroAccounts = createSelector(
    getCompanySettingsState,
    (state => {
        const xeroAccounts = [...state.xeroAccounts];
        const sortedAccounts = xeroAccounts.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });

        return sortedAccounts;
    })
);

export const getXeroRevenueAccounts = createSelector(
    getCompanySettingsState,
    (state => {
        const xeroAccounts = [...state.xeroAccounts];
        const sortedAccounts = xeroAccounts.filter(x => x.class === "REVENUE").sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });

        return sortedAccounts;
    })
);

export const getXeroExpenseAccounts = createSelector(
    getCompanySettingsState,
    (state => {
        const xeroAccounts = [...state.xeroAccounts];
        const sortedAccounts = xeroAccounts.filter(x => x.class === "EXPENSE").sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });

        return sortedAccounts;
    })
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = companySettingsAdapter.getSelectors(getCompanySettingsState);


