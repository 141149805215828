import {Directive, ElementRef, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {getMspType, LoginState} from "src/app/login/store/reducers/login.reducer";
import {PsaTypes} from "../../msp/models/msp.model";

@Directive({
    selector: "[appPsaName]"
})
export class PsaNameDirective implements OnInit {
    constructor(private store: Store<LoginState>, private el: ElementRef) {
    }

    ngOnInit(): void {
        const psaType = this.store.select(getMspType);
        psaType.subscribe(result => {
            if (!result) {
                return;
            }

            // Renaming for PSA -> Proper Text
            if (this.el.nativeElement.className.includes("mat-header-cell")) {
                if (this.el.nativeElement.firstChild.children) {
                    this.el.nativeElement.firstChild.children[0].innerText = (this.el.nativeElement.innerText as string).replace("PSA", result);
                }
            }

            // Renaming clients -> Contacts for Xero
            if (result === PsaTypes.Xero) {
                if (this.el.nativeElement) {
                    this.el.nativeElement.innerText = (this.el.nativeElement.innerText as string).replace("Client", "Contact");
                }
            }

        });
    }
}
