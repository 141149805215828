import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { nceProductIdPrefixes } from "../../../shared/enums/types.enum";
import { BillingActions, BillingActionTypes } from "../actions/billing.actions";
import { BillingProfile } from "./../../models/billing-profile.model";
import {
    AgreementType,
    AzureProductPicklist,
    DickerDataVendorProduct,
    Product,
    VendorProductPickListItem,
} from "./../../models/product-mapping.model";

export const billingFeatureKey = "billing";

export const billingProfileAdapter = createEntityAdapter<BillingProfile>();

export interface BillingState extends EntityState<BillingProfile> {
    loading: boolean;
    vendorProducts: VendorProductPickListItem[] | DickerDataVendorProduct[] | AzureProductPicklist[];
    psaProducts: Product[];
    agreementTypes: AgreementType[];
}

export const defaultState: BillingState = {
    loading: false,
    ids: [],
    entities: {},
    psaProducts: [],
    agreementTypes: [],
    vendorProducts: []
};

export const initialState: BillingState = billingProfileAdapter.getInitialState(defaultState);

export function billingReducer(state = initialState, action: BillingActions): BillingState {
    switch (action.type) {

        case BillingActionTypes.LoadBillingProfile:
            return {
                ...state,
                loading: true
            };

        case BillingActionTypes.LoadBillingProfileSuccess:
            return billingProfileAdapter.addAll(action.payload[0], {
                ...state,
                loading: false,
                agreementTypes: action.payload[1],
                psaProducts: action.payload[2],
                vendorProducts: action.payload[3]
            });

        case BillingActionTypes.LoadBillingProfileFailed:
            return {
                ...state,
                loading: false
            };

        case BillingActionTypes.ClearBillingProfile:
            return Object.assign({}, initialState);

        case BillingActionTypes.UpdateBillingProfileSuccess:
            if (state.ids.length > 0 && state.entities[state.ids[0]]) {
                const updateProfile = {
                    id: state.ids[0] as number,
                    changes: {
                        productMappings: action.payload.productMappings,
                        productProfiles: action.payload.productProfiles
                    }
                };
                return billingProfileAdapter.updateOne(updateProfile, state);
            }
            return state;

        case BillingActionTypes.AddNewProductMappingToStore:
            if (state.ids.length > 0 && state.entities[state.ids[0]]) {
                const entity = state.entities[state.ids[0] as number];
                const updateMappings = {
                    id: state.ids[0] as number,
                    changes: {
                        productMappings: [...entity.productMappings, action.mapping]
                    }
                };
                return billingProfileAdapter.updateOne(updateMappings, state);
            }
            return state;

        default:
            return state;
    }
}

// ---- SELECTORS ----
export const getBillingProfileState = createFeatureSelector<BillingState>(billingFeatureKey);

export const getPsaProducts = createSelector(
    getBillingProfileState,
    (state => state.psaProducts)
);
export const getAvailableProducts = createSelector(
    getPsaProducts,
    getBillingProfileState,
    (products, mappings) => products.filter(product => !mappings.entities[mappings.ids[0]].productMappings.some(mapping => mapping.productId === product.id))
);

export const getMappedPsaProducts = createSelector(
    getBillingProfileState,
    (state) => {
        if (state.ids.length === 0 || !state.entities[state.ids[0]]) {
            return [];  // If no ID is found or the entity is missing, return an empty array
        }
        const productMappings = state.entities[state.ids[0]].productMappings;
        const availableProductIds = productMappings.map(x => x.productId);
        return state.psaProducts.filter(x => availableProductIds.includes(x.id));
    }
);

export const getVendorItems = createSelector(
    getBillingProfileState,
    (state => {
        return state.vendorProducts;
        const msVendorSkusAvaliable = state.vendorProducts
            .filter(x => x.microsoftId ||
                // Includes NCE vendor items as they do not have the MicrosoftId
                (!x.microsoftId && (x.vendorProductId.toLowerCase().startsWith(nceProductIdPrefixes.p1y)
                    || x.vendorProductId.toLowerCase().startsWith(nceProductIdPrefixes.p1m))))
            .map(x => x.microsoftSku.toLowerCase());
        return state.vendorProducts.filter(x => msVendorSkusAvaliable.includes(x.microsoftSku.toLowerCase()));
    })
);

export const getMicrosoftLicenses = createSelector(
    getBillingProfileState,
    (state => state.vendorProducts)
);

export const getAllItems = createSelector(
    getBillingProfileState,
    (state => state)
);

export const getLoading = createSelector(
    getBillingProfileState,
    (state => state.loading)
);

export const getMsProductForVendorSku = (vendorSku: string) => createSelector(
    getBillingProfileState,
    (state => {
        return state.vendorProducts;
        // Logic for NCE licenses.
        if (vendorSku.startsWith("P", 0)) {
            // Example NCE P1Y:CFQ7TTC0LCHC:0002, we need to split on : and find a contains.
            const nceSearchString = vendorSku.split(":")[1];
            return state.vendorProducts
                .filter(x => x.microsoftId)
                .find(x => x.microsoftSku.toLowerCase().includes(nceSearchString.toLowerCase()));
        }

        return state.vendorProducts
            .filter(x => x.microsoftId)
            .find(x => x.microsoftSku.toLowerCase() === vendorSku.toLowerCase());
    })
);

export const getMsProductsForVendorCode = (vendorCode: string) => createSelector(
    getBillingProfileState,
    (state => {
        if (!vendorCode) {
            return [];
        }
        vendorCode = vendorCode.toLowerCase();
        const vendorProducts = state.vendorProducts.filter(
            x => x.vendorProductId.toLowerCase() === vendorCode
                || x.microsoftId.toLowerCase() === vendorCode
        );
        return vendorProducts;
    })
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = billingProfileAdapter.getSelectors(getBillingProfileState);
