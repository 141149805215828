import { AfterViewInit, Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-animated-logo",
  templateUrl: "./animated-logo.component.html",
  styleUrls: ["./animated-logo.component.css"]
})
export class AnimatedLogoComponent implements OnInit, AfterViewInit {
    @Output()
    animationComplete: EventEmitter < any > = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.animationComplete.emit(true), 1500);
  }

}
