import { InvoiceStatuses } from "./../enums/statuses.enum";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "invoiceStatus"
})

// Converts invoice status index to string
export class InvoiceStatusPipe implements PipeTransform {
    transform(statusIndex: number): string {
        return Object.values(InvoiceStatuses)[statusIndex];
    }
}
