import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";
import { isAuthenticated, LoginState } from "src/app/login/store/reducers/login.reducer";
import { Notification } from "src/app/notification/models/notification.model";
import {
    getShortNotificationArray,
    getSystemMessages,
    NotificationState,
} from "src/app/notification/notification/reducers/notification.reducer";
import { NotificationPopupComponent } from "src/app/shared/components/notification-popup/notification-popup.component";

import { AuthService } from "./../../../login/services/login.service";
import {
    LoadShortNotificationList,
    LoadSystemMessages,
    MarkNotificationsAsRead,
} from "./../../../notification/notification/actions/notification.actions";

@Component({
    selector: "app-main-page-layout",
    templateUrl: "./main-page-layout.component.html",
    styleUrls: ["./main-page-layout.component.scss"]
})
export class MainPageLayoutComponent implements OnInit {

    constructor(
        private store: Store<LoginState>,
        private notificationStore: Store<NotificationState>,
        private authService: AuthService,
        private modalService: NgbModal,
        private dialog: MatDialog
    ) { }

    isAuthenticated$: Observable<boolean>;
    notificationIcon = "notifications";

    // Notifications Observable
    notifications$ = this.notificationStore.select(getShortNotificationArray);
    systemMessages$ = this.notificationStore.select(getSystemMessages);
    numberOfNotifications: number;
    messagesRetrieved = false;

    notificationOptions = {
        important: "notification_important",
        none: "notifications",
        new: "notifications_active"
    };

    ngOnInit() {
        this.isAuthenticated$ = this.store.select(isAuthenticated);

        this.isAuthenticated$.subscribe(result => {
            if (result && false === this.messagesRetrieved) {
                this.notificationStore.dispatch(new LoadShortNotificationList());
                this.notificationStore.dispatch(new LoadSystemMessages());
            }
        });

        this.notifications$.pipe(
            tap((result: Notification[]) => {
                this.numberOfNotifications = result.length;
                const hasNonViewedNotifications = result.find(x => x.isViewed === false);
                if (hasNonViewedNotifications) {
                    this.notificationIcon = this.notificationOptions.important;
                } else {
                    this.notificationIcon = this.notificationOptions.none;
                }
            })
        ).subscribe();
    }

    notificationListRead() {
        this.notificationIcon = this.notificationOptions.none;
        this.notifications$.pipe(
            take(1)
        ).subscribe((notifications: Notification[]) => {
            if (notifications.find(x => x.isViewed === false)) {
                this.notificationStore.dispatch(new MarkNotificationsAsRead(notifications));
            }
        });
    }

    openNotification(notification: Notification) {
        // Make a popup for the notification!
        const dialogRef = this.dialog.open(NotificationPopupComponent, {
            data: {
                time: notification.createdAt,
                clientName: notification.mspPsaClientName,
                message: notification.message
            }
        });

    }
}
