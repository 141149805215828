import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LoadingDisplay, LoadingStatuses } from "src/app/shared/models/loading-display.model";
import { LoadingService } from "src/app/shared/services/loading.service";

@Injectable()
export class HttpLoadingInteceptor implements HttpInterceptor {
    endpointsToNotDisplay = ["logout", "notification"];

    constructor(private loadingService: LoadingService) {}
    intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
        let newLoadingItem: LoadingDisplay;
        if ((request.method !== "GET") &&
            undefined === this.endpointsToNotDisplay.find(x => request.url.includes(x))) {
            newLoadingItem = new LoadingDisplay(request.url, LoadingStatuses.processing, request.method, request.body);
            // tslint:disable-next-line:max-line-length
            this.loadingService.addNewLoadingItem(newLoadingItem);
        }

        return next.handle(request)
            .pipe(
                tap((event: HttpEvent < any > ) => {
                    if (event instanceof HttpResponse) {
                        console.log("Event Successed");
                        this.loadingService.removeLoadingWithSuccess(newLoadingItem);
                    }
                    // Setup Processing to success
                }),
                catchError((error: HttpErrorResponse) => {
                    // Setup processing to failed
                    this.loadingService.updateLoadingToFailed(newLoadingItem);
                    console.log("Event Failed");
                    return throwError(error);
                })
            );
    }
}
