import { ProductMapping } from "src/app/billing/models/product-mapping.model";


export class BillingProfile {
    id: number;
    productMappings: ProductMapping[];
}

export enum BillingProfileTypes {
    NCEandLegacy = 0,
    Azure = 1
}
