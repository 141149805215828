import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import {
    getProfileType,
    getSigninUser,
    isAzureFeatureEnabled,
    isInvoiceFeatureEnabled,
    LoginState,
} from "src/app/login/store/reducers/login.reducer";
import { canAccessAdminConsole } from "src/app/shared/guards/admin-console-guard.service";
import { NavbarRoute } from "src/app/shared/models/navbar-route.model";

import { getVendorType } from "../../../login/store/reducers/login.reducer";
import { ProfileTypes } from "../../enums/types.enum";

@Component({
    selector: "app-side-nav-bar",
    templateUrl: "./side-nav-bar.component.html",
    styleUrls: ["./side-nav-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavBarComponent implements OnInit {
    routes: Array<NavbarRoute>;
    bottomDisplayRoutes: Array<NavbarRoute>;

    vendorType$ = this.authStore.select(getVendorType);
    isInvoiceFeatureEnabled$ = this.authStore.select(isInvoiceFeatureEnabled);
    isAzureFeatureEnabled$ = this.authStore.select(isAzureFeatureEnabled);
    profileType$ = this.authStore.select(getProfileType);
    signInUser$ = this.authStore.select(getSigninUser);

    constructor(
        private router: Router,
        private authStore: Store<LoginState>,
        private ref: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        combineLatest([this.profileType$, this.isInvoiceFeatureEnabled$, this.isAzureFeatureEnabled$, this.signInUser$])
            .subscribe(([profileType, isInvoiceFeatureEnabled, isAzureFeatureEnabled, signInUser]) => {
                this.routes = [
                    {
                        display: profileType === ProfileTypes.item ? "Contacts" : "Clients",
                        route: "/clients",
                        icon: "group",
                        external: false
                    }
                ];

                switch (profileType) {
                    case ProfileTypes.item:
                        this.routes.push(...[
                            {
                                display: "Items",
                                route: "/items",
                                icon: "menu_book",
                                external: false
                            }
                        ]);
                        break;

                    case ProfileTypes.product:
                        this.routes.push(...[
                            {
                                display: "Licenses",
                                route: "/licenses",
                                icon: "description",
                                external: false
                            },
                            {
                                display: "Products",
                                route: "/products",
                                icon: "menu_book",
                                external: false
                            },
                            {
                                display: "Audit Logs",
                                route: "/audit",
                                icon: "pageview",
                                external: false
                            }
                        ]);
                }

                if (isInvoiceFeatureEnabled) {
                    this.routes.push({
                        display: "Invoices",
                        route: "/invoices",
                        icon: "cloud_sync",
                        external: false
                    });
                }

                if (isAzureFeatureEnabled) {
                    this.routes.push({
                        display: "Azure",
                        route: "/azure",
                        icon: "cloud",
                        external: false
                    });
                }

                if (signInUser !== null && canAccessAdminConsole(signInUser.userType)) {
                    this.routes.push({
                        display: "Admin Panel",
                        route: "/admin",
                        icon: "cake",
                        external: false
                    });
                }

                this.bottomDisplayRoutes = [
                    {
                        display: "Documentation",
                        route: "https://help.cspportal.cloud/",
                        icon: "folder",
                        external: true
                    },
                    {
                        display: "Company Settings",
                        route: "/msp/settings",
                        icon: "settings",
                        external: false
                    }
                ];

                // once value is set, we need to manually trigger change detection to get the latest nav bar
                this.ref.detectChanges();
            });
    }

    goToRoute(route: NavbarRoute) {
        window.open(route.route, "_blank");
    }

    isCurrentRoute(route: NavbarRoute): boolean {
        return true;
        return this.router.url.includes(route.route);
    }

}
