import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoadingService } from "src/app/shared/services/loading.service";

import { OpenSnackbar } from "../store/actions/snackbar.actions";
import { State } from "../store/reducers/snackbar.reducer";

@Injectable()
export class HttpFailedInterceptor implements HttpInterceptor {
    endpointsToNotDisplay = ["logout", "notification"];

    constructor(private loadingService: LoadingService, private sharedStore: Store<State>) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (
                        request.method === "GET" &&
                        (error.status !== 401 || request.url.includes("signin-callback"))
                         && error.error && error.error.message
                         // TEMP hotfix code:
                         && (error.error.message as string).includes("external") === false) {
                        // throw up an error message.
                        this.sharedStore.dispatch(new OpenSnackbar({
                            message: error.error.message,
                            action: "close"
                        }));
                    }
                    return throwError(error);
                })
            );
    }
}
