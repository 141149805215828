import { Component, OnInit } from "@angular/core";
import { LoadingDisplay, LoadingStatuses } from "src/app/shared/models/loading-display.model";

import { LoadingService } from "./../../services/loading.service";

@Component({
    selector: "app-loading-display",
    templateUrl: "./loading-display.component.html",
    styleUrls: ["./loading-display.component.scss"]
})
export class LoadingDisplayComponent implements OnInit {
    loadingObjects: LoadingDisplay[];
    statusProcessing: LoadingStatuses = LoadingStatuses.processing;
    statusSuccess: LoadingStatuses = LoadingStatuses.success;
    statusFailed: LoadingStatuses = LoadingStatuses.failed;


    constructor(private loadingService: LoadingService) { }

    ngOnInit() {
        this.loadingService.loadingSubject.subscribe(result => {
            this.loadingObjects = result;
        });
    }

    clearItem(item: LoadingDisplay) {
        this.loadingService.removeLoadingItem(item);
    }

}
