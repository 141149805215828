import {DragDropModule} from "@angular/cdk/drag-drop";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from "@angular/material";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {EffectsModule} from "@ngrx/effects";
import {NotificationModule} from "src/app/notification/notification.module";
import {NotificationPopupComponent} from "src/app/shared/components/notification-popup/notification-popup.component";
import {SnackbarEffects} from "src/app/shared/store/effects/snackbar.effects";

import {AnimatedLogoComponent} from "./components/animated-logo/animated-logo.component";
import {ConfirmDialogComponent} from "./components/confirm-dialog/confirm-dialog.component";
import {IconButtonComponent} from "./components/icon-button/icon-button.component";
import {InvoiceDescriptionEditViewComponent, } from "./components/invoice-description-edit-view/invoice-description-edit-view.component";
import {LoadingDisplayComponent} from "./components/loading-display/loading-display.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {MatTableHeaderComponent} from "./components/mat-table-header/mat-table-header.component";
import {SelectionListComponent} from "./components/selection-list/selection-list.component";
import {SideNavBarComponent} from "./components/side-nav-bar/side-nav-bar.component";
import {StatusDisplayComponent} from "./components/status-display/status-display.component";
import {
    SystemMessageDisplayComponent,
    SystemMessagePopupComponent,
} from "./components/system-message-display/system-message-display.component";
import {UserMiniDisplayComponent} from "./components/user-mini-display/user-mini-display.component";
import {CdkDetailRowDirective} from "./directives/cdk-detail-row.directive";
import {LoadingDirective} from "./directives/loading.directive";
import {PsaNameDirective} from "./directives/psa-name.directive";
import {MainPageLayoutComponent} from "./layouts/main-page-layout/main-page-layout.component";
import {TableRowContainerComponent} from "./layouts/table-row-container/table-row-container.component";
import {ClientNameFilterPipe} from "./pipes/client-name-filter.pipe";
import {InvoiceStatusPipe} from "./pipes/invoice-status.pipe";
import {IsNcePipe} from "./pipes/isNce.pipe";
import {PricePipe} from "./pipes/price.pipe";
import {SafePipe} from "./pipes/safe.pipe";
import {TwoDigitDecimalNumberDirective} from "./directives/two-decimal-number.directive";
import {IsAdminDirective} from "./directives/is-admin.directive";

@NgModule({
    declarations: [
        SideNavBarComponent,
        MainPageLayoutComponent,
        TableRowContainerComponent,
        StatusDisplayComponent,
        CdkDetailRowDirective,
        LoadingDirective,
        LoadingComponent,
        SelectionListComponent,
        LoadingDisplayComponent,
        ConfirmDialogComponent,
        NotificationPopupComponent,
        ClientNameFilterPipe,
        SafePipe,
        SystemMessagePopupComponent,
        IconButtonComponent,

        InvoiceDescriptionEditViewComponent,
        AnimatedLogoComponent,
        PsaNameDirective,
        TwoDigitDecimalNumberDirective,
        MatTableHeaderComponent,
        SystemMessageDisplayComponent,
        UserMiniDisplayComponent,
        InvoiceStatusPipe,
        IsNcePipe,
        PricePipe,
        IsAdminDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatSidenavModule,
        NotificationModule,
        MatListModule,
        MatMenuModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatInputModule,
        MatDividerModule,
        MatIconModule,
        MatSnackBarModule,
        MatSortModule,
        ReactiveFormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatDialogModule,
        MatChipsModule,
        NgSelectModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatDividerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        ScrollingModule,
        HttpClientModule,
        DragDropModule,
        RouterModule,
        EffectsModule.forFeature([SnackbarEffects])
    ],
    exports: [SideNavBarComponent,
        MainPageLayoutComponent,
        TableRowContainerComponent,
        StatusDisplayComponent,
        LoadingDisplayComponent,
        SelectionListComponent,
        AnimatedLogoComponent,
        LoadingComponent,
        MatTableHeaderComponent,
        LoadingDirective,
        SystemMessageDisplayComponent,
        PsaNameDirective,
        ClientNameFilterPipe,
        SafePipe,
        ConfirmDialogComponent,
        NotificationPopupComponent,
        SystemMessagePopupComponent,
        IconButtonComponent,
        InvoiceDescriptionEditViewComponent,
        InvoiceStatusPipe,
        IsNcePipe,
        PricePipe,
        IsAdminDirective
    ]
})

export class SharedModule {
}
