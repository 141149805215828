import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "isNce"
})

// checks if "name" has "nce" in it
export class IsNcePipe implements PipeTransform {
    transform(name: string): boolean {
        if (name) {
            const result: boolean = name.toLowerCase().split(" ").includes("nce");
            return result;
        }
        return false;
    }
}
