import { Action } from "@ngrx/store";

import { ItemProfile } from "../../models/itemProfile.model";
import { PsaClientsItemOverrides } from "./../../models/psaClientsItemOverrides.model";
import { BillingProfileTypes } from "src/app/billing/models/billing-profile.model";

export enum ItemActionTypes {
    GetItemProfiles = "[Item API] | Get Item Profiles | Ongoing",
    GetItemProfilesSuccess = "[Item API] | Get Item Profiles | Success",
    GetItemProfilesFailed = "[Item API] | Get Item Profiles | Failed",
    AddUpdateDeleteItems = "[Item API] | Add, Update, Or Delete Items | Ongoing",
    AddUpdateDeleteItemsSuccess = "[Item API] | Add, Update, Or Delete Items | Success",
    AddUpdateDeleteItemsFailed = "[Item API] | Add, Update, Or Delete Items | Failed",
    AddOrUpdateItems = "[Item API] | Add or Update Items | Ongoing",
    AddOrUpdateItemsSuccess = "[Item API] | Add or Update Items | Success",
    AddOrUpdateItemsFailed = "[Item API] | Add or Update Items | Failed",
    DeleteItems = "[Item API] | Delete Items | Ongoing",
    DeleteItemProfilesSuccess = "[Item Profiles] | Delete Items | Success",
    DeleteItemOverridesSuccess = "[Item Overrides] | Delete Items | Success",
    DeleteItemsFailed = "[Item API] | Delete Items | Failed",
    AddUpdateDeleteClientsOverrides = "[Item API] | Add, Update, Or Delete ClientsOverrides | Ongoing",
    AddUpdateDeleteClientsOverridesSuccess = "[Item API] | Add, Update, Or Delete ClientsOverrides | Success",
    AddUpdateDeleteClientsOverridesFailed = "[Item API] | Add, Update, Or Delete ClientsOverrides | Failed",
    AddOrUpdateClientsOverrides = "[Item API] | Add or Update ClientsOverrides | Ongoing",
    AddOrUpdateClientsOverridesSuccess = "[Item API] | Add or Update ClientsOverrides | Success",
    AddOrUpdateClientsOverridesFailed = "[Item API] | Add or Update ClientsOverrides | Failed",
    DeleteClientsOverrides = "[Item API] | Delete ClientsOverrides | Ongoing",
    DeleteClientsOverridesSuccess = "[Item API] | Delete ClientsOverrides | Success",
    DeleteClientsOverridesFailed = "[Item API] | Delete ClientsOverrides | Failed"
}

export class GetItemProfiles implements Action {
    readonly type = ItemActionTypes.GetItemProfiles;
    constructor(public profileType: BillingProfileTypes = BillingProfileTypes.NCEandLegacy) { }
}

export class GetItemProfilesSuccess implements Action {
    readonly type = ItemActionTypes.GetItemProfilesSuccess;
    constructor(public data: any[]) { }
}

export class GetItemProfilesFailed implements Action {
    readonly type = ItemActionTypes.GetItemProfilesFailed;
    constructor() { }
}

export class AddUpdateDeleteItems implements Action {
    readonly type = ItemActionTypes.AddUpdateDeleteItems;
    constructor(
        public itemProfiles: ItemProfile[],
        public itemProfileIds: number[],
        public itemOverrideIds: number[]
    ) { }
}

export class AddUpdateDeleteItemsSuccess implements Action {
    readonly type = ItemActionTypes.AddUpdateDeleteItemsSuccess;
    constructor() { }
}

export class AddUpdateDeleteItemsFailed implements Action {
    readonly type = ItemActionTypes.AddUpdateDeleteItemsFailed;
    constructor() { }
}

export class AddOrUpdateItems implements Action {
    readonly type = ItemActionTypes.AddOrUpdateItems;
    constructor(public itemProfiles: ItemProfile[]) { }
}

export class AddOrUpdateItemsSuccess implements Action {
    readonly type = ItemActionTypes.AddOrUpdateItemsSuccess;
    constructor(public itemProfiles: ItemProfile[]) { }
}

export class AddOrUpdateItemsFailed implements Action {
    readonly type = ItemActionTypes.AddOrUpdateItemsFailed;
    constructor() { }
}

export class DeleteItems implements Action {
    readonly type = ItemActionTypes.DeleteItems;
    constructor(public itemProfileIds: number[], public itemOverrideIds: number[]) { }
}

export class DeleteItemProfilesSuccess implements Action {
    readonly type = ItemActionTypes.DeleteItemProfilesSuccess;
    constructor(public itemProfileIds: number[]) { }
}

export class DeleteItemOverridesSuccess implements Action {
    readonly type = ItemActionTypes.DeleteItemOverridesSuccess;
    constructor(public itemOverrideIds: number[]) { }
}

export class DeleteItemsFailed implements Action {
    readonly type = ItemActionTypes.DeleteItemsFailed;
    constructor() { }
}

export class AddUpdateDeleteClientsOverrides implements Action {
    readonly type = ItemActionTypes.AddUpdateDeleteClientsOverrides;
    constructor(
        public clientsOverrides: PsaClientsItemOverrides[],
        public clientOverrideIds: number[]
    ) { }
}

export class AddUpdateDeleteClientsOverridesSuccess implements Action {
    readonly type = ItemActionTypes.AddUpdateDeleteClientsOverridesSuccess;
    constructor() { }
}

export class AddUpdateDeleteClientsOverridesFailed implements Action {
    readonly type = ItemActionTypes.AddUpdateDeleteClientsOverridesFailed;
    constructor() { }
}

export class AddOrUpdateClientsOverrides implements Action {
    readonly type = ItemActionTypes.AddOrUpdateClientsOverrides;
    constructor(
        public clientsOverrides: PsaClientsItemOverrides[]
    ) { }
}

export class AddOrUpdateClientsOverridesSuccess implements Action {
    readonly type = ItemActionTypes.AddOrUpdateClientsOverridesSuccess;
    constructor(
        public clientsOverrides: PsaClientsItemOverrides[]
    ) { }
}

export class AddOrUpdateClientsOverridesFailed implements Action {
    readonly type = ItemActionTypes.AddOrUpdateClientsOverridesFailed;
    constructor() { }
}

export class DeleteClientsOverrides implements Action {
    readonly type = ItemActionTypes.DeleteClientsOverrides;
    constructor(
        public clientOverrideIds: number[]
    ) { }
}

export class DeleteClientsOverridesSuccess implements Action {
    readonly type = ItemActionTypes.DeleteClientsOverridesSuccess;
    constructor(
        public clientOverrideIds: number[]
    ) { }
}

export class DeleteClientsOverridesFailed implements Action {
    readonly type = ItemActionTypes.DeleteClientsOverridesFailed;
    constructor() { }
}

export type ItemActions =
    GetItemProfiles |
    GetItemProfilesSuccess |
    GetItemProfilesFailed |
    AddUpdateDeleteItems |
    AddUpdateDeleteItemsSuccess |
    AddUpdateDeleteItemsFailed |
    AddOrUpdateItems |
    AddOrUpdateItemsSuccess |
    AddOrUpdateItemsFailed |
    DeleteItems |
    DeleteItemProfilesSuccess |
    DeleteItemOverridesSuccess |
    DeleteItemsFailed |
    AddUpdateDeleteClientsOverrides |
    AddUpdateDeleteClientsOverridesSuccess |
    AddUpdateDeleteClientsOverridesFailed |
    AddOrUpdateClientsOverrides |
    AddOrUpdateClientsOverridesSuccess |
    AddOrUpdateClientsOverridesFailed |
    DeleteClientsOverrides |
    DeleteClientsOverridesSuccess |
    DeleteClientsOverridesFailed;
