import { Action } from "@ngrx/store";

import { ProductMapping } from "./../../models/product-mapping.model";
import { ProfileTypes } from "src/app/shared/enums/types.enum";
import { BillingProfileTypes } from "../../models/billing-profile.model";

export enum BillingActionTypes {
    LoadBillingProfile = "[Billing View Table] | Loading Product List | Ongoing",
    LoadBillingProfileSuccess = "[Billing API] | Loading Product List | Success",
    LoadBillingProfileFailed = "[Billing API] | Loading Product List | Failed",
    ClearBillingProfile = "[Billing Reducer] | Clear Current Billing Profile Data",
    UpdateBillingProfile = "[Billing View Table] Update Billing Profile",
    UpdateBillingProfileSuccess = "[Billing API] Update Billing Profile Success",
    AddNewProductMappingToStore = "[Product Mapping API] Add New Product Mapping to Store"

}

export class LoadBillingProfile implements Action {
    readonly type = BillingActionTypes.LoadBillingProfile;
    constructor(public profileType: BillingProfileTypes = BillingProfileTypes.NCEandLegacy, public tenantId?: string) { }
}

export class LoadingBillingProfileSuccess implements Action {
    readonly type = BillingActionTypes.LoadBillingProfileSuccess;
    constructor(public payload: any[]) { }
}

export class LoadBillingProfileFailed implements Action {
    readonly type = BillingActionTypes.LoadBillingProfileFailed;
    constructor() { }
}

export class ClearBillingProfile implements Action {
    readonly type = BillingActionTypes.ClearBillingProfile;
    constructor() { }
}

export class UpdateBillingProfile implements Action {
    readonly type = BillingActionTypes.UpdateBillingProfile;
    constructor(public payload: any) { }
}

export class UpdateBillingProfileSuccess implements Action {
    readonly type = BillingActionTypes.UpdateBillingProfileSuccess;
    constructor(public payload: any) { }
}

export class AddNewProductMappingToStore implements Action {
    readonly type = BillingActionTypes.AddNewProductMappingToStore;
    constructor(public mapping: ProductMapping) { }
}


export type BillingActions = LoadBillingProfile | LoadingBillingProfileSuccess | UpdateBillingProfile | UpdateBillingProfileSuccess |
    LoadBillingProfileFailed | AddNewProductMappingToStore | ClearBillingProfile;
