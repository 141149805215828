import { Action } from "@ngrx/store";
import { BillingStylePost, ManualReportLastRun, MicrosoftMspPostCall, Msp } from "src/app/msp/models/msp.model";
import { MspUserLists } from "src/app/msp/models/user-lists.model";
import { MicrosoftDelegatedAdmin } from "src/app/shared/models/microsoft-delegated-admin.model";

import { AutotaskAllocationCode } from "../../models/autotask-allocation-code.model";
import { ApiCredentials } from "../../models/msp-api-details.model";
import { MspNceSettings } from "../../models/msp-nce-settings.model";
import { ReportNotificationConfig } from "../../models/msp.model";
import { XeroAccount } from "../../models/xero-account.model";
import { XeroContact } from "../../models/xero-contact.model";
import { XeroTenant } from "../../models/xero-tenant.model";
import { MspAutotaskConfig } from "./../../models/msp-autotask-config.model";
import { MspXeroConfig } from "./../../models/msp-xero-config.model";


export enum CompanySettingsActionTypes {
    setupNewMspCreation = "[Create MSP] Setup Fresh data",
    setupPartiallyCreatedMsp = "[Create MSP] Setup a partially setup msp",

    // Loading Actions
    LoadCompanySettings = "[Company Settings View] Load Company Settings",
    LoadCompanySettingsSuccess = "[Company Settings View] Load Company Settings Success",
    LoadCompanySettingsFailed = "[Company Settings View] Load Company Settings Failed",

    // DICKER DATA
    // Create
    CreateDickerDataDetails = "[DickerData Credentials View] Create DickerData Credentials",
    CreateDickerDataDetailsSuccess = "[DickerData API] Create DickerData Credentials Success",
    CreateDickerDataDetailsFailed = "[DickerData API] Create DickerData Credentials Failed",

    // Update
    UpdateDickerDataCredentials = "[DickerData Credentials View] Update DickerData Credentials",
    UpdateDickerDataCredentialsSuccess = "[DickerData API] Update DickerData Credentials Success",
    UpdateDickerDataCredentialsFailed = "[DickerData API] Update DickerData Credentials Failed",

    // Connectwise Actions
    // Create
    CreateConnectwiseCredentials = "[Connectwise Credentials View] Create Connectwise Credentials",
    CreateConnectwiseCredentialsSuccess = "[Connectwise API] Create Connectwise Credentials Success",
    CreateConnectwiseCredentialsFailed = "[Connectwise API] Create Connectwise Credentials Failed",

    // Update
    UpdateConnectwiseDetails = "[Connectwise Credentials View] Update Connectwise Credentials",
    UpdateConnectwiseDetailsSuccess = "[Connectwise API] Update Connectwise Credentials Success",
    UpdateConnectwiseDetailsFailed = "[Connectwise API] Update Connectwise Credentials Failed",

    // AUTOTASK
    // Create
    CreateAutoTaskCredentials = "[AutoTask Credentials View] Create AutoTask Credentials",
    CreateAutoTaskCredentialsSuccess = "[AutoTask API] Create AutoTask Credentials Success",
    CreateAutoTaskCredentialsFailed = "[AutoTask API] Create AutoTask Credentials Failed",

    // Update
    UpdateAutoTaskDetails = "[AutoTask Credentials View] Update AutoTask Api",
    UpdateAutoTaskDetailsSuccess = "[AutoTask API] Update AutoTask Api Success",
    UpdateAutoTaskDetailsFailed = "[AutoTask API] Update AutoTask Api Failed",

    // Get Autotask Material Codes
    GetAutotaskMaterialCodes = "[Autotask API] Get Autotask Material Codes",
    GetAutotaskMaterialCodesSuccess = "[Autotask API] Get Autotask Material Codes Success",
    GetAutotaskMaterialCodesFailed = "[Autotask API] Get Autotask Material Codes Failed",

    // Get Autotask Config
    GetAutotaskMspConfig = "[Autotask Msp Config API] Get Autotask Msp Config",
    GetAutotaskMspConfigSuccess = "[Autotask Msp Config API] Get Autotask Msp Config Success",
    GetAutotaskMspConfigFailed = "[Autotask Msp Config API] Get Autotask Msp Config Failed",

    // Add or Update Autotask Config
    AddOrUpdateAutotaskMspConfig = "[Autotask Msp Config API] Add Or Update Autotask Msp Config",
    AddOrUpdateAutotaskMspConfigSuccess = "[Autotask Msp Config API] Add Or Update Autotask Msp Config Success",
    AddOrUpdateAutotaskMspConfigFailed = "[Autotask Msp Config API] Add Or Update Autotask Msp Config Failed",

    // MICROSOFT DELEGATE
    // Create
    createMicrosoftDelegateDetails = "[DickerData API View] create MicrosoftDelegate Api",
    createMcrosoftDelegateDetailsSuccess = "[DickerData API View] create MicrosoftDelegate Api Success",
    createMicrosoftDelegateDetailsFailed = "[DickerData API View] create sMicrosoftDelegate Api Failed",

    // Update
    UpdateMicrosoftDelegateDetails = "[DickerData API View] Update MicrosoftDelegate Api",
    UpdateMicrosoftDelegateDetailsSuccess = "[DickerData API View] Update MicrosoftDelegate Api Success",
    UpdateMicrosoftDelegateDetailsFailed = "[DickerData API View] Update MicrosoftDelegate Api Failed",

    // Xero
    // Get Contacts
    GetXeroContacts = "[Xero API] Get Xero Contacts",
    GetXeroContactsSuccess = "[Xero API] Get Xero Contacts Success",
    GetXeroContactsFailed = "[Xero API] Get Xero Contacts Failed",

    // Get Tenants
    GetXeroTenants = "[Xero API] Get Xero Tenants",
    GetXeroTenantsSuccess = "[Xero API] Get Xero Tenants Success",
    GetXeroTenantsFailed = "[Xero API] Get Xero Tenants Failed",

    // Get Accounts
    GetXeroAccounts = "[Xero API] Get Xero Accounts",
    GetXeroAccountsSuccess = "[Xero API] Get Xero Accounts Success",
    GetXeroAccountsFailed = "[Xero API] Get Xero Accounts Failed",

    // Get Xero Config
    GetXeroMspConfig = "[Xero Msp Config API] Get Xero Msp Config",
    GetXeroMspConfigSuccess = "[Xero Msp Config API] Get Xero Msp Config Success",
    GetXeroMspConfigFailed = "[Xero Msp Config API] Get Xero Msp Config Failed",

    // Add or Update Xero Config
    AddOrUpdateXeroMspConfig = "[Xero Msp Config API] Add Or Update Xero Msp Config",
    AddOrUpdateXeroMspConfigSuccess = "[Xero Msp Config API] Add Or Update Xero Msp Config Success",
    AddOrUpdateXeroMspConfigFailed = "[Xero Msp Config API] Add Or Update Xero Msp Config Failed",

    // Check if Xero is fully setup
    IsXeroFullySetup = "[Xero API] Is Xero Fully Setup",
    IsXeroFullySetupSuccess = "[Xero API] Is Xero Fully Setup Success",
    IsXeroFullySetupFailed = "[Xero API] Is Xero Fully Setup Failed",


    // APPLICATION ID CHECK
    // Test
    TestApplicationAccess = "[Application API View] Test Application API Access",
    TestApplicationAccessSuccess = "[Application API] Test Application API Success",
    TestApplicationAccessFailed = "[Application API] Test Application API Failed",

    // NCE SYNC SETTINGS
    // UPDATE
    AddOrUpdateNceSettings = "[NCE Sync Settings] Add Or Update Settings",
    AddOrUpdateNceSettingsSuccess = "[NCE Sync Settings] Add Or Update Settings Success",

    // MSP DETAILS
    // Get
    GetMspDetails = "[Get MSP Page] Get MSP",
    GetMspDetailsSuccess = "[Create MSP API] Get MSP Success",
    // Create
    CreateMspDetails = "[Create MSP Page] Create MSP",
    CreateMspDetailsSuccess = "[Create MSP API] Create MSP Success",
    // Update
    UpdateMspDetails = "[Application API] Update Msp",
    UpdateMspDetailsSuccess = "[Application API] Update Msp Success",

    CreateMicrosoftMsp = "[Create MSP] Create Microsoft Based MSP",
    CreateMicrosoftMspSuccess = "[Create MSP] Create Microsoft Based Msp Successful",

    AuthPartnerCenter = "[Create MSP] Partner Center",
    AuthPartnerCenterSuccess = "[Create MSP] Partner Center Success",

    AuthXero = "[Create MSP] Auth Xero",
    AuthXeroSuccess = "[Create MSP] Auth Xero Success",

    // USERS
    // Get
    GetUserListSuccessful = "[UserList API] User LIst retreived",
    // Update
    UpdateAllowedUserList = "[Allowed User List] Update allowed users",
    UpdateAllowedUserListSuccess = "[Allowed User List] Update allowed users success",

    // MICROSOFT CREDENTIALS
    // Get
    GetIsPartnerCenterAuthenticated = "[Microsoft API] Get Is User Partner Center Authenticated",

    // Create
    CreateMicrosoftCredentials = "[Microsoft Credentials View] Create Microsoft Credentials",
    CreateMicrosoftCredentialsSuccess = "[Microsoft API] Create Microsoft Credentials Success",
    CreateMicrosoftCredentialsFailed = "[Microsoft API] Create Microsoft Credentials Failed",

    // Update
    UpdateMicrosoftCredentials = "[Microsoft Credentials View] Update Microsoft Credentials",
    UpdateMicrosoftCredentialsSuccess = "[Microsoft API] Update Microsoft Credentials Success",
    UpdateMicrosoftCredentialsFailed = "[Microsoft API] Update Microsoft Credentials Failed",

    // REPORT NOTIFICATION CONFIGS
    GetReportNotificationConfigSuccessful = "[Report Notification Config] get successful",
    SaveReportNotitification = "[Report Notification Config] Save Started",
    SaveReportNotificationSuccessful = "[Report Notification Config] Save Successful",

    // StAGES
    SetStages = "[Create MSP] Set stages for an MSP Creation",

    // BILLING STYLE
    UpdateBillingStyle = "[Billing Style] Update billing style",
    UpdateBillingStyleSuccessful = "[Billing Style] Update Billing style successful",

    ManuallyStartReport = "[Report] Manually Start Report",
    ManuallyStartReportSuccess = "[Report] Manually started report successfully",

    GetManualReportLastRunsManual = "[Reports] Get Manual Sync Last Run",
    GetManualReportLastRunsSuccessful = "[Reports API] Manual Sync Last Run Successful"
}

export class SetupNewMspCreation implements Action {
    readonly type = CompanySettingsActionTypes.setupNewMspCreation;
}

export class SetupPartiallyCreatedMsp implements Action {
    readonly type = CompanySettingsActionTypes.setupPartiallyCreatedMsp;
}

export class LoadCompanySettings implements Action {
    readonly type = CompanySettingsActionTypes.LoadCompanySettings;
}

export class LoadCompanySettingsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.LoadCompanySettingsSuccess;

    constructor(public companySettings: ApiCredentials[]) {
    }
}

export class LoadCompanySettingsFailed implements Action {
    readonly type = CompanySettingsActionTypes.LoadCompanySettingsFailed;
}

// DICKER DATA
// Create
export class CreateDickerDataCredentials implements Action {
    readonly type = CompanySettingsActionTypes.CreateDickerDataDetails;

    constructor(public credential: ApiCredentials) {
    }
}

export class CreateDickerDataCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.CreateDickerDataDetailsSuccess;

    constructor(public credential: ApiCredentials) {
    }
}

// Update
export class UpdateDickerDataCredentials implements Action {
    readonly type = CompanySettingsActionTypes.UpdateDickerDataCredentials;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

export class UpdateDickerDataCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.UpdateDickerDataCredentialsSuccess;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

// CONNECTWISE
// Create
export class CreateConnectwiseCredentials implements Action {
    readonly type = CompanySettingsActionTypes.CreateConnectwiseCredentials;

    constructor(public credential: ApiCredentials) {
    }
}

export class CreateConnectwiseCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.CreateConnectwiseCredentialsSuccess;

    constructor(public credential: ApiCredentials) {
    }
}

// Update
export class UpdateConnectWiseCredentials implements Action {
    readonly type = CompanySettingsActionTypes.UpdateConnectwiseDetails;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

export class UpdateConnectWiseCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.UpdateConnectwiseDetailsSuccess;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

// Microsoft
// Get
export class GetIsPartnerCenterAuthenticated implements Action {
    readonly type = CompanySettingsActionTypes.GetIsPartnerCenterAuthenticated;

    constructor() {
    }
}

// Create
export class CreateMicrosoftCredentials implements Action {
    readonly type = CompanySettingsActionTypes.CreateMicrosoftCredentials;

    constructor(public credential: ApiCredentials) {
    }
}

export class CreateMicrosoftCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.CreateMicrosoftCredentialsSuccess;

    constructor(public credential: ApiCredentials) {
    }
}

// Update
export class UpdateMicrosoftCredentials implements Action {
    readonly type = CompanySettingsActionTypes.UpdateMicrosoftCredentials;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

export class UpdateMicrosoftCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.UpdateMicrosoftCredentialsSuccess;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

// AUTOTASK
// Create
export class CreateAutoTaskCredentials implements Action {
    readonly type = CompanySettingsActionTypes.CreateAutoTaskCredentials;

    constructor(public credential: ApiCredentials) {
    }
}

export class CreateAutoTaskCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.CreateAutoTaskCredentialsSuccess;

    constructor(public credential: ApiCredentials) {
    }
}

// Update
export class UpdateAutoTaskCredentials implements Action {
    readonly type = CompanySettingsActionTypes.UpdateAutoTaskDetails;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

export class UpdateAutoTaskCredentialsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.UpdateAutoTaskDetailsSuccess;

    constructor(public id: number, public changes: Partial<ApiCredentials>) {
    }
}

// Get Autotask Material Codes
export class GetAutotaskMaterialCodes implements Action {
    readonly type = CompanySettingsActionTypes.GetAutotaskMaterialCodes;
    constructor() { }
}

export class GetAutotaskMaterialCodesSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetAutotaskMaterialCodesSuccess;
    constructor(public materialCodes: AutotaskAllocationCode[]) { }
}

export class GetAutotaskMaterialCodesFailed implements Action {
    readonly type = CompanySettingsActionTypes.GetAutotaskMaterialCodesFailed;
    constructor() { }
}

// Get Autotask Config
export class GetAutotaskMspConfig implements Action {
    readonly type = CompanySettingsActionTypes.GetAutotaskMspConfig;
    constructor() { }
}

export class GetAutotaskMspConfigSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetAutotaskMspConfigSuccess;
    constructor(public autotaskConfig: MspAutotaskConfig) { }
}

export class GetAutotaskMspConfigFailed implements Action {
    readonly type = CompanySettingsActionTypes.GetAutotaskMspConfigFailed;
    constructor() { }
}

// Add or Update Autotask Config
export class AddOrUpdateAutotaskMspConfig implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateAutotaskMspConfig;
    constructor(public autotaskConfig: MspAutotaskConfig) { }
}

export class AddOrUpdateAutotaskMspConfigSuccess implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateAutotaskMspConfigSuccess;
    constructor(public autotaskConfig: MspAutotaskConfig) { }
}

export class AddOrUpdateAutotaskMspConfigFailed implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateAutotaskMspConfigFailed;
    constructor() { }
}


// Xero
// Get Contacts
export class GetXeroContacts implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroContacts;

    constructor() {
    }
}

export class GetXeroContactsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroContactsSuccess;

    constructor(public xeroContacts: XeroContact[]) {
    }
}

export class GetXeroContactsFailed implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroContactsFailed;

    constructor() {
    }
}

// Get Tenants
export class GetXeroTenants implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroTenants;

    constructor() {
    }
}

export class GetXeroTenantsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroTenantsSuccess;

    constructor(public xeroTenants: XeroTenant[]) {
    }
}

export class GetXeroTenantsFailed implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroTenantsFailed;

    constructor() {
    }
}

// Get Accounts
export class GetXeroAccounts implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroAccounts;

    constructor() {
    }
}

export class GetXeroAccountsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroAccountsSuccess;

    constructor(public xeroAccounts: XeroAccount[]) {
    }
}

export class GetXeroAccountsFailed implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroAccountsFailed;

    constructor() {
    }
}

// Get Xero Config
export class GetXeroMspConfig implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroMspConfig;

    constructor() {
    }
}

export class GetXeroMspConfigSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroMspConfigSuccess;

    constructor(public xeroConfig: MspXeroConfig) {
    }
}

export class GetXeroMspConfigFailed implements Action {
    readonly type = CompanySettingsActionTypes.GetXeroMspConfigFailed;

    constructor() {
    }
}

// Add or Update Xero Config
export class AddOrUpdateXeroMspConfig implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateXeroMspConfig;

    constructor(public xeroConfig: MspXeroConfig) {
    }
}

export class AddOrUpdateXeroMspConfigSuccess implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateXeroMspConfigSuccess;

    constructor(public xeroConfig: MspXeroConfig) {
    }
}

export class AddOrUpdateXeroMspConfigFailed implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateXeroMspConfigFailed;

    constructor() {
    }
}

// Check if Xero is fully setup
export class IsXeroFullySetup implements Action {
    readonly type = CompanySettingsActionTypes.IsXeroFullySetup;

    constructor() {
    }
}

export class IsXeroFullySetupSuccess implements Action {
    readonly type = CompanySettingsActionTypes.IsXeroFullySetupSuccess;

    constructor(public isFullySetup: boolean) {
    }
}

export class IsXeroFullySetupFailed implements Action {
    readonly type = CompanySettingsActionTypes.IsXeroFullySetupFailed;

    constructor() {
    }
}

// APPLICATION ACCESS
// Test
export class TestApplicationAccess implements Action {
    readonly type = CompanySettingsActionTypes.TestApplicationAccess;

    constructor() {
    }
}

export class TestApplicationAccessSuccess implements Action {
    readonly type = CompanySettingsActionTypes.TestApplicationAccessSuccess;

    constructor() {
    }
}

export class TestApplicationAccessFailed implements Action {
    readonly type = CompanySettingsActionTypes.TestApplicationAccessFailed;

    constructor() {
    }
}

// NCE SYNC SETTINGS
// Update
export class AddOrUpdateMspNceSettings implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateNceSettings;

    constructor(public mspNceSettings: MspNceSettings) {
    }
}

// Update Success
export class AddOrUpdateNceSettingsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.AddOrUpdateNceSettingsSuccess;

    constructor(public mspNceSettings: MspNceSettings) {
    }
}

// MICROSOFT DELEGATE
// Create
export class CreateMicrosoftDelegateAccount implements Action {
    readonly type = CompanySettingsActionTypes.createMicrosoftDelegateDetails;

    constructor(public microsoftAdmin: Partial<MicrosoftDelegatedAdmin>) {
    }
}

export class CreateMicrosoftDelegateAccountSuccess implements Action {
    readonly type = CompanySettingsActionTypes.createMcrosoftDelegateDetailsSuccess;

    constructor(public microsoftAdmin: Partial<MicrosoftDelegatedAdmin>) {
    }
}

// Update

// MSP DETAILS
// Get
export class GetMsp implements Action {
    readonly type = CompanySettingsActionTypes.GetMspDetails;

    constructor() {
    }
}

export class AuthPartnerCenter implements Action {
    readonly type = CompanySettingsActionTypes.AuthPartnerCenter;

    constructor(public redirectUrl: string) {
    }
}

export class AuthXero implements Action {
    readonly type = CompanySettingsActionTypes.AuthXero;

    constructor() {
    }
}

export class AuthXeroSuccess implements Action {
    readonly type = CompanySettingsActionTypes.AuthXeroSuccess;

    constructor() {
    }
}

export class AuthPartnerCenterSuccess implements Action {
    readonly type = CompanySettingsActionTypes.AuthPartnerCenterSuccess;

    constructor() {
    }
}

export class GetMspSuccess implements Action {
    readonly type = CompanySettingsActionTypes.GetMspDetailsSuccess;

    constructor(public msp: Msp) {
    }
}

// Create
export class CreateMsp implements Action {
    readonly type = CompanySettingsActionTypes.CreateMspDetails;

    constructor(public msp: Partial<Msp>) {
    }
}

export class CreateMspSuccess implements Action {
    readonly type = CompanySettingsActionTypes.CreateMspDetailsSuccess;

    constructor(public msp: Partial<Msp>) {
    }
}

export class CreateMicrosoftMsp implements Action {
    readonly type = CompanySettingsActionTypes.CreateMicrosoftMsp;

    constructor(public msp: Partial<MicrosoftMspPostCall>) {
    }
}

export class CreateMicrosoftMspSuccess implements Action {
    readonly type = CompanySettingsActionTypes.CreateMicrosoftMspSuccess;

    constructor(public msp: Partial<MicrosoftMspPostCall>) {
    }
}

// Update
export class UpdateMsp implements Action {
    readonly type = CompanySettingsActionTypes.UpdateMspDetails;

    constructor(public msp: Msp) {
    }
}

export class UpdateMspDetailsSuccess implements Action {
    readonly type = CompanySettingsActionTypes.UpdateMspDetailsSuccess;

    constructor(public msp: Msp) {
    }
}

// USERS

// Get
export class GetUserListSuccessful implements Action {
    readonly type = CompanySettingsActionTypes.GetUserListSuccessful;

    constructor(public userList: any) {
    }
}

// Update
export class UpdateUserList implements Action {
    readonly type = CompanySettingsActionTypes.UpdateAllowedUserList;

    constructor(public users: MspUserLists) {
    }
}

export class UpdateUserListSuccess implements Action {
    readonly type = CompanySettingsActionTypes.UpdateAllowedUserListSuccess;

    constructor(public users: MspUserLists) {
    }
}

export class GetReportNotificationConfigSuccessful implements Action {
    readonly type = CompanySettingsActionTypes.GetReportNotificationConfigSuccessful;

    constructor(public configs: any) {
    }
}

export class SaveReportNotitification implements Action {
    readonly type = CompanySettingsActionTypes.SaveReportNotitification;

    constructor(public configs: ReportNotificationConfig[]) {
    }
}

export class SaveReportNotificationSuccessful implements Action {
    readonly type = CompanySettingsActionTypes.SaveReportNotificationSuccessful;

    constructor() {
    }
}

export class SetStages implements Action {
    readonly type = CompanySettingsActionTypes.SetStages;

    constructor(public stages: string[]) {
    }
}

// BILLING STYLE
export class UpdateBillingStyle implements Action {
    readonly type = CompanySettingsActionTypes.UpdateBillingStyle;

    constructor(public billingStyle: BillingStylePost) {
    }
}

export class UpdateBillingStyleSuccessful implements Action {
    readonly type = CompanySettingsActionTypes.UpdateBillingStyleSuccessful;

    constructor(public billingStyle: BillingStylePost) {
    }
}

export class ManuallyStartReport implements Action {
    readonly type = CompanySettingsActionTypes.ManuallyStartReport;

    constructor(public acronym: string) {
    }
}

export class ManuallyStartReportSuccess implements Action {
    readonly type = CompanySettingsActionTypes.ManuallyStartReportSuccess;

    constructor(public acronym: string) {
    }
}

export class GetManualReportLastRunsSuccessful implements Action {
    readonly type = CompanySettingsActionTypes.GetManualReportLastRunsSuccessful;

    constructor(public manualLastRuns: ManualReportLastRun) {
    }
}

export class GetManualReportLastRunsManual implements Action {
    readonly type = CompanySettingsActionTypes.GetManualReportLastRunsManual;

    constructor() {
    }
}


export type CompanySettingsActions =
    LoadCompanySettings | LoadCompanySettingsSuccess | LoadCompanySettingsFailed |
    CreateDickerDataCredentials | CreateDickerDataCredentialsSuccess |
    UpdateDickerDataCredentials |
    CreateConnectwiseCredentials | CreateConnectwiseCredentialsSuccess |
    UpdateConnectWiseCredentials | UpdateConnectWiseCredentialsSuccess |
    CreateMicrosoftCredentials | CreateMicrosoftCredentialsSuccess |
    UpdateMicrosoftCredentials | UpdateMicrosoftCredentialsSuccess |
    CreateAutoTaskCredentials | CreateAutoTaskCredentialsSuccess |
    UpdateAutoTaskCredentials | UpdateAutoTaskCredentialsSuccess |
    GetAutotaskMaterialCodes | GetAutotaskMaterialCodesSuccess | GetAutotaskMaterialCodesFailed |
    GetAutotaskMspConfig | GetAutotaskMspConfigSuccess | GetAutotaskMspConfigFailed |
    AddOrUpdateAutotaskMspConfig | AddOrUpdateAutotaskMspConfigSuccess | AddOrUpdateAutotaskMspConfigFailed |
    GetXeroContacts | GetXeroContactsSuccess | GetXeroContactsFailed |
    GetXeroTenants | GetXeroTenantsSuccess | GetXeroTenantsFailed |
    GetXeroAccounts | GetXeroAccountsSuccess | GetXeroAccountsFailed |
    GetXeroMspConfig | GetXeroMspConfigSuccess | GetXeroMspConfigFailed |
    AddOrUpdateXeroMspConfig | AddOrUpdateXeroMspConfigSuccess | AddOrUpdateXeroMspConfigFailed |
    IsXeroFullySetup | IsXeroFullySetupSuccess | IsXeroFullySetupFailed |
    TestApplicationAccess | TestApplicationAccessSuccess | TestApplicationAccessFailed |
    CreateMicrosoftDelegateAccount | CreateMicrosoftDelegateAccountSuccess |
    SetupNewMspCreation | CreateMsp | CreateMspSuccess | SetupPartiallyCreatedMsp |
    CreateMicrosoftMsp | CreateMicrosoftMspSuccess |
    GetMsp | GetMspSuccess | UpdateMsp | UpdateMspDetailsSuccess |
    UpdateUserList | UpdateUserListSuccess | GetUserListSuccessful |
    GetReportNotificationConfigSuccessful | SaveReportNotitification | SaveReportNotificationSuccessful |
    AuthPartnerCenter | AuthPartnerCenterSuccess |
    AuthXero | AuthXeroSuccess |
    SetStages |
    AddOrUpdateMspNceSettings | AddOrUpdateNceSettingsSuccess |
    UpdateBillingStyle | UpdateBillingStyleSuccessful | ManuallyStartReport | ManuallyStartReportSuccess |
    GetManualReportLastRunsManual | GetManualReportLastRunsSuccessful;
