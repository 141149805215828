export class SystemMessage {
    id: number;
    mspId: number;
    userId: string;
    displayOption: DisplayOptions;
    expiryDate: Date;
    infoLevel: MessageInformationLevels;
    isViewed: boolean;
    expired: boolean;
    message: string;
}

export enum ExpiryOptions {
    Date,
    Viewed
}

export enum DisplayOptions {
    HeaderMessage,
    Popup
}

export enum MessageInformationLevels {
    Informative,
    Warning,
    Critical
}

