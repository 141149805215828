import { Invoice } from "src/app/invoices/models/invoice.model";

import { MspPsaClientAgreementSyncTaskDto } from "./../../client/models/agreementSyncTaskDto.model";
import { MspNceSettings } from "./msp-nce-settings.model";

export class Msp {
    name: string;
    id: number;
    isMspConfigured: boolean;
    invoiceDescriptionTemplate: string;
    isInvoiceDescriptionEnabled: boolean;
    psaType: number;
    vendorType: VendorTypes;
    partnerCenterIntegrated: boolean;
    billingStyle: BillingStyle;
    isProrateEnabled: boolean;
    mspNceSettings: MspNceSettings;
    featureFlags: FeatureFlags;
}

export class MicrosoftMspPostCall extends Msp {
    token: string;
}

export class MspAdminDto extends Msp {
    createdDate: Date;
    lastSyncDate: Date;
    firstCreatedUserEmail: string;
    emailDomain: string;
    legacySyncTasks: MspPsaClientAgreementSyncTaskDto[];
    nceInvoices: Invoice[];
}

export enum PsaTypes {
    ConnectWise = "ConnectWise",
    Autotask = "AutoTask",
    DickerData = "Dicker Data",
    Microsoft = "Microsoft",
    Xero = "Xero"
}

export enum BillingStyle {
    Advanced = "Advanced",
    Arrears = "Arrears"
}

export enum VendorTypes {
    dickerData,
    microsoft
}

export enum ReportAcronymOption {
    UnassignedUsers = "uu",
    UnassignedLicenses = "ul",
    Pricing = "pr",
    UnmappedClients = "uc",
    InvoiceSync = "is"
}

export class ReportNotificationConfig {
    id: number;
    email: string;
    sendClientReport: boolean;
    sendSubscriptionReport: boolean;
    sendPricingReport: boolean;
    sendUnassignedUserReport: boolean;
    sendInvoiceSyncEmail: boolean;
}

export class ManualReportConfig {
    acronym: string;
}

export class ManualReportLastRun {
    pricingDifferenceLastRun: Date;
    unassignedUsersLastRun: Date;
    unmappedClientsLastRun: Date;
    unmappedLicensesLastRun: Date;
}

export class NotificationConfigDisplay {
    emailType: string;
    emailName: string;
    description: string;
    turnedOn: boolean;
    acronym: string;
    emailList: string[];
}

export class FeatureFlags {
    nceEnabled: boolean;
    nceInvoiceSettingsEnabled: boolean;
}

export enum EmailReportTypes {
    sendPricingReport = "sendPricingReport",
    sendClientReport = "sendClientReport",
    sendSubscriptionReport = "sendSubscriptionReport",
    sendUnassignedUserReport = "sendUnassignedUserReport",
    sendInvoiceSyncEmail = "sendInvoiceSyncEmail"
}

export class BillingStylePost {
    billingStyle: BillingStyle;
    isProrated: boolean;
}

export enum PsaIntegrationType {
    ConnectWise = "ConnectWise",
    Autotask = "AutoTask",
    Xero = "Xero"
}
