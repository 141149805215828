export class ProductProfile {
    id: number;
    profileName: string;
    microsoftLicenseName: string;
    linkedClients: string[];
    price: number;
    margin: number;
    psaProductMappingId: number;
    useMailboxes?: boolean;
    useSharedMailboxes?: boolean;
    isCustomProduct: boolean;
    isDefaultProduct: boolean;
}
