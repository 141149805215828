import {Directive, ElementRef, OnInit, } from "@angular/core";
import {AdminConsoleGuard} from "../guards/admin-console-guard.service";

@Directive({
    selector: "[appAdminDisplay]"
})
export class IsAdminDirective implements OnInit {
    constructor(private adminConsoleGuard: AdminConsoleGuard, private el: ElementRef) {
    }

    ngOnInit(): void {
        this.adminConsoleGuard.canActivate().subscribe(result => {
            if (false === result) {
                this.el.nativeElement.hidden = true;
            }
        });
    }
}
