import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BillingProfile } from "src/app/billing/models/billing-profile.model";
import { AgreementType } from "src/app/billing/models/product-mapping.model";
import { ProductProfile } from "src/app/billing/models/product-profile.model";
import { ClientAgreement } from "src/app/licenses/models/agreementProductProfileDto.model";
import { ProductProfileDisplayDto } from "src/app/licenses/models/productProfileDisplayDto.model";
import { VendorSubscription } from "src/app/licenses/models/subscription.model";

import { DickerDataVendorProduct } from "../../../billing/models/product-mapping.model";
import { ReferenceValue } from "../../../shared/models/reference-value.model";
import { LicensesActions, LicensesActionTypes } from "../actions/licenses.actions";
import { LicenseStatuses } from "./../../../shared/enums/statuses.enum";

export const licensesFeatureKey = "licenses";

export const licensesAdapter = createEntityAdapter<VendorSubscription>({
    selectId: subscription => subscription.subscriptionId
});

export interface LicenseState extends EntityState<VendorSubscription> {
    loading: boolean;
    agreementTypes: AgreementType[];
    products: ProductProfile[];
    mspBillingProfile: BillingProfile;
    clientAgreements: ClientAgreement[];
    dickerDataStockCode: DickerDataVendorProduct[];
    currentStoredAgreement: ReferenceValue;
}

export const initialState: LicenseState = {
    ids: [],
    entities: {},
    loading: false,
    mspBillingProfile: null,
    clientAgreements: [],
    agreementTypes: [],
    products: [],
    dickerDataStockCode: [],
    currentStoredAgreement: null
};

export function licenseReducers(state = initialState, action: LicensesActions): LicenseState {
    switch (action.type) {

        case LicensesActionTypes.LoadLicenses:
            return {
                ...state,
                loading: true
            };

        case LicensesActionTypes.LoadLicensesSuccess:
            return licensesAdapter.addAll(action.payload[0], {
                ...state,
                clientAgreements: action.payload[1],
                dickerDataStockCode: action.payload[2],
                agreementTypes: action.payload[3],
                loading: false,
            });

        case LicensesActionTypes.LoadLicensesFailed:
            return licensesAdapter.removeAll({ ...state, loading: false });

        case LicensesActionTypes.AddSubscriptionSuccess:
            return licensesAdapter.addOne(action.payload, {
                ...state
            });

        case LicensesActionTypes.LoadLicensePageMappingDataSuccess:
            return {
                ...state,
                mspBillingProfile: action.payload[0]
            };

        case LicensesActionTypes.DeleteSubscriptionSuccess:
            return licensesAdapter.removeOne(action.subscriptionId, state);

        case LicensesActionTypes.LicenseMappingSuccess:
            // Add agreement product profile DTO to the agreement table
            // state.clientAgreements.find(x => x.agreementId === action.agreementId);
            const clientAgreementClone: ClientAgreement[] = JSON.parse(JSON.stringify(state.clientAgreements));
            const newClientAgreements = clientAgreementClone.map(agreement => {
                if (agreement.agreementId !== action.payload.agreementProfileId) {
                    return agreement;
                } else {
                    // Check if product already exists
                    if (agreement.productProfiles === null) {
                        agreement.productProfiles = [];
                    }
                    const existingProductProfileIndex = agreement.productProfiles.findIndex(x => x.id === action.payload.id);
                    if (existingProductProfileIndex !== -1) {
                        agreement.productProfiles[existingProductProfileIndex] = action.payload;
                    } else {
                        agreement.productProfiles.push(action.payload);
                    }
                    return agreement;
                }
            });
            return {
                ...state,
                clientAgreements: clientAgreementClone
            };

        case LicensesActionTypes.RemoveLicenseMappingSuccess:
            // Find the license with that agreemnet and remove it + remove from client agreements
            const agreementsClone: ClientAgreement[] = JSON.parse(JSON.stringify(state.clientAgreements));
            const newAgreements = agreementsClone.map(agreement => {
                // Check if product already exists
                if (agreement.productProfiles === null) {
                    agreement.productProfiles = [];
                }

                const existingProductProfileIndex = agreement.productProfiles.findIndex(x => x.id === action.profileId);

                if (existingProductProfileIndex !== -1) {
                    agreement.productProfiles.splice(existingProductProfileIndex, 1);
                }

                return agreement;
            });
            return {
                ...state,
                clientAgreements: newAgreements
            };

        case LicensesActionTypes.AddNewAgreementToStore:
            // Add new agreement to the store
            return {
                ...state,
                clientAgreements: [...state.clientAgreements, action.payload]
            };

        case LicensesActionTypes.AddNewProductMappingToLicenseStore:
            const newBillingProfile: BillingProfile = JSON.parse(JSON.stringify(state.mspBillingProfile));
            newBillingProfile.productMappings.push(action.payload);


            return {
                ...state,
                mspBillingProfile: newBillingProfile
            };

        case LicensesActionTypes.StoreCurrentAgreement:
            return {
                ...state,
                currentStoredAgreement: action.agreement
            };

        default:
            return state;
    }
}
// ---- SELECTORS ----
export const getLicenseState = createFeatureSelector<LicenseState>(licensesFeatureKey);

export const isLoading = createSelector(
    getLicenseState,
    (state) => state.loading
);

export const getAgreementTypes = createSelector(
    getLicenseState,
    (state => state.agreementTypes)
);

export const getProducts = createSelector(
    getLicenseState,
    (state => [].concat(...state.mspBillingProfile.productMappings.map(x => x.productProfiles)))
);

export const getProductMappings = createSelector(
    getLicenseState,
    (state => state.mspBillingProfile.productMappings)
);

export const getBillingProfile = createSelector(
    getLicenseState,
    (state => state.mspBillingProfile)
);

export const getClientAgreementData = createSelector(
    getLicenseState,
    (state => state.clientAgreements)
);

export const getDickerDataStockCodes = createSelector(
    getLicenseState,
    (state => state.dickerDataStockCode)
);

export const getAvaliableProducts = (addonProducts: boolean = false) => createSelector(
    getLicenseState,
    (state: LicenseState) => {
        // Get all products that arnt currently assigned.
        const productsInUse: number[] = [];
        state.clientAgreements.forEach(agreementDetail => {
            if (agreementDetail.productProfiles === null) {
                return;
            }
            agreementDetail.productProfiles.forEach(product => {
                // Check if the agreement detail id is in the agreement types
                productsInUse.push(product.productProfileId);
            });
        });
        const products = ([].concat(...state.mspBillingProfile.productMappings
            .map(x => x.productProfiles)));

        const prodMapCustomProductDict = {};
        state.mspBillingProfile.productMappings.forEach(prodMap => {
            prodMapCustomProductDict[prodMap.id] = prodMap.isCustomProduct;
        });

        let productReturn: ProductProfileDisplayDto[] = [];
        const psaProductMappings = {};

        const psaProductsToGet = products.map(x => x.psaProductMappingId);

        psaProductsToGet.forEach(psaProdId => {
            psaProductMappings[psaProdId] = state.mspBillingProfile.productMappings.find(x => psaProdId === x.id);
        });

        productReturn = products.map(product => {
            const newProduct = new ProductProfileDisplayDto();
            newProduct.display = product.profileName;
            newProduct.agreementProfileId = 0;
            newProduct.id = product.id;
            newProduct.productProfileId = product.psaProductMappingId;
            // tslint:disable-next-line:max-line-length
            newProduct.dickerDataStockCode = state.dickerDataStockCode.find(x => x.vendorProductId === psaProductMappings[product
                .psaProductMappingId].vendorProductId);
            newProduct.isCustomProduct = prodMapCustomProductDict[product.psaProductMappingId];

            return newProduct;
        });

        if (addonProducts === false) {
            productReturn = productReturn.filter(x => false === productsInUse.includes(x.id));
            // Filter on items that arnt addons
            const nonAddonStockCodes = state.dickerDataStockCode.filter(x => x.vendorInfo.addOn === "N")
                .map(x => x.vendorProductId);
            // tslint:disable-next-line: max-line-length
            productReturn = productReturn.filter(x => x.isCustomProduct || (x.dickerDataStockCode && nonAddonStockCodes.includes(x.dickerDataStockCode.vendorProductId)));
        }

        return productReturn;
    });

export const getProductsForVendorCode = (VendorCode: string) => createSelector(
    getLicenseState,
    (state: LicenseState) => {
        let returnProductProfileArray: ProductProfile[] = [];
        // Get product mapping for stock code
        const productMapping = state.mspBillingProfile.productMappings.filter(x => x.vendorProductId === VendorCode);
        if (productMapping) {
            returnProductProfileArray = [].concat(...productMapping.map(x => x.productProfiles));
        }
        // Get all product profiles for that product mapping

        return returnProductProfileArray;
    }
);

export const getCurrentAgreement = createSelector(
    getLicenseState,
    (state: LicenseState) => {
        return state.currentStoredAgreement;
    }
);

export const getAllLicenses = (showRemovedSuspended = false, searchTerm = "") => createSelector(
    getLicenseState,
    (state: LicenseState) => {
        const clientProductAgreementDictionary = {};
        const productProductMappingDictionary = {};
        const clientProductsIds = [];
        // Setup agreement id to array of product psa mapping ids\

        const allAgreements: ReferenceValue[] = [];
        state.agreementTypes.forEach(agreementType => {
            allAgreements.push(...agreementType.agreements);
        });
        state.clientAgreements.forEach(agreementDetail => {
            if (agreementDetail.productProfiles === null) {
                return;
            }
            agreementDetail.productProfiles.forEach(product => {
                const agreement = allAgreements.find(x => x.id === agreementDetail.psaAgreementId);
                if (agreement === undefined) {
                    return;
                }

                productProductMappingDictionary[product.productProfileId] = product.id;
                clientProductsIds.push(product.productProfileId);
                // Pull the name from the agreement itself if it exists.
                clientProductAgreementDictionary[product.productProfileId] = {
                    id: agreementDetail.psaAgreementId,
                    name: agreement.name
                };
            });
        });

        const billingProfile = state.mspBillingProfile;

        const licenses: VendorSubscription[] = [];

        for (const key in state.entities) {
            if (state.entities.hasOwnProperty(key)) {
                const subscription = state.entities[key];

                if (!showRemovedSuspended
                    &&
                    (subscription.status === LicenseStatuses.removed
                        || subscription.status === LicenseStatuses.suspended
                    )) {
                    continue;
                }

                if (!subscription.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    continue;
                }

                // Make a new object based on the dicker data subscription
                const licenseDisplayObj: VendorSubscription = JSON.parse(JSON.stringify(subscription));

                // Get License Stock Code
                const stockCode = subscription.vendorCode;
                // Get Product Mapping for the stock code
                const productMappings = billingProfile.productMappings.find(x => x.vendorProductId === stockCode &&
                    x.productProfiles.findIndex(y => clientProductsIds.includes(y.id)) > -1
                );

                if (productMappings) {
                    // Get Product Profiles relating back to the product mappings
                    const productProfiles = productMappings.productProfiles;
                    // Check client agreement for connected product profiles and add to dicker data products if avaliable.
                    const clientProduct = productProfiles.find(x => clientProductsIds.includes(x.id));
                    if (clientProduct) {
                        // Add product id and agreemnet id to license
                        licenseDisplayObj.agreementReferenceValue = clientProductAgreementDictionary[clientProduct.id];
                        licenseDisplayObj.productProfile = new ProductProfile();
                        licenseDisplayObj.productProfile.price = clientProduct.price;
                        licenseDisplayObj.productProfile.profileName = clientProduct.profileName;
                        licenseDisplayObj.productProfile.psaProductMappingId = clientProduct.psaProductMappingId;
                        licenseDisplayObj.productProfile.id = productProductMappingDictionary[clientProduct.id];
                        licenseDisplayObj.productProfile.microsoftLicenseName = productMappings.microsoftLicenseName;
                    }
                }

                if (subscription.monitoredState) {
                    const toQuantity = `${subscription.monitoredState.toQuantity}.00`;
                    licenseDisplayObj.status = "UPDATING";
                    // Set the quantity to the request quantity
                    licenseDisplayObj.temporaryQuantity = toQuantity;
                    licenseDisplayObj.monitoredState = subscription.monitoredState;
                } else {
                    licenseDisplayObj.temporaryQuantity = licenseDisplayObj.confirmedQuantity;
                }
                licenses.push(licenseDisplayObj);
            }
        }


        const parentSubscriptionArray = licenses.map(license => {
            return license.parentSubscriptionId;
        });
        // Unique Parent Subscriptions
        const parentSubscriptionSet = new Set(parentSubscriptionArray);
        const fullLicensesObject = licenses.filter(x => parentSubscriptionSet.has(x.subscriptionId));

        fullLicensesObject.forEach(x => {
            x.addons = licenses.filter(y => false === parentSubscriptionSet.has(y.subscriptionId) &&
                y.parentSubscriptionId === x.subscriptionId);
        });

        // Remap licenses to have the parent and addon setup
        // Reason for remapping afterwards is that each subscription needs to be fully setup first before we design the mapping.
        console.log(fullLicensesObject);
        return fullLicensesObject;
    }
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = licensesAdapter.getSelectors(getLicenseState);
