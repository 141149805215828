export class LoadingDisplay {
    url: string;
    status: LoadingStatuses;
    display?: string;

    constructor(url: string, status: LoadingStatuses, methodType: string, requestBody: any) {
        this.url = url;
        this.status = status;
        this.display = this.SetupLoadingDisplay(url, methodType, requestBody);
    }

    private SetupLoadingDisplay(url: string, methodType: string, requestBody: any): string {
        let displayString = "";

        switch (methodType) {
            case "PATCH":
            case "PUT":
                displayString += "Updating ";
                break;
            case "POST":
                displayString += "Creating ";
                break;
            case "DELETE":
                displayString += "Deleting ";
                break;
        }

        displayString += "Entity";
        return displayString;

        // Switch case on url includes for the differnet fields
        if (url.includes("client")) {
            displayString += `${requestBody.psaClientName}`;
        } else if (url.includes("billing")) {

        } else {
            return url;
        }

        return displayString;
    }
}

export enum LoadingStatuses {
    processing = "processing",
    success = "success",
    failed = "failed"
}
