import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { map, switchMap } from "rxjs/internal/operators";
import { getMspType, isAuthenticated, LoginState } from "src/app/login/store/reducers/login.reducer";
import { PsaTypes } from "src/app/msp/models/msp.model";



@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {
    constructor(
        private authStore: Store<LoginState>,
        private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const mspTypes: PsaTypes[] = next.data.mspTypes;

        return this.authStore.select(isAuthenticated).pipe(
            switchMap((result) => {
                if (result) {
                    if (mspTypes && mspTypes.length >= 1) {
                        return this.authStore.select(getMspType).pipe(map((mspType) => {
                            return mspTypes.includes(mspType);
                        }));
                    }
                    return of(true);
                } else {
                    // Route away
                    console.log(next, state);
                    this.router.navigateByUrl(`/login?redirectUrl=${state.url}`);
                    return of(false);
                }
            })
        );
    }
}
