import { MatSnackBarConfig } from "@angular/material";
import { Action } from "@ngrx/store";

export enum SnackbarActions {
    SnackbarOpen = "[SnackBar] Open",
    SnackbarClose = "[SnackBar] Close"
}

export class OpenSnackbar implements Action {
    readonly type = SnackbarActions.SnackbarOpen;

    constructor(public payload: {
        message: string,
        action?: string,
        config?: MatSnackBarConfig,
        duration?: number  // Optionally control duration directly
    }) {
        // Default duration if not provided
        if (payload.duration && !payload.config.duration) {
            payload.config = { ...payload.config, duration: payload.duration };
        }
    }
}

export class CloseSnackbar implements Action {
    readonly type = SnackbarActions.SnackbarClose;
}

export type SnackbarAction = OpenSnackbar | CloseSnackbar;
