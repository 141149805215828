import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SystemMessage } from "src/app/admin/models/systemMessage.model";
import { Notification } from "src/app/notification/models/notification.model";

import { NotificationActions, NotificationActionTypes } from "../actions/notification.actions";

export const notificationFeatureKey = "notification";

function sortNotificationsForViewed(n1: Notification, n2: Notification) {
    if (n1.isViewed && !n2.isViewed) {
        return 1;
    } else if (!n1.isViewed && n2.isViewed) {
        return -1;
    } else {
        return 0;
    }
}

export const notificationAdapter = createEntityAdapter<Notification>();

export interface NotificationState extends EntityState<Notification> {
    loading: boolean;
    allNotifications: Notification[];
    systemMessages: SystemMessage[];
}

export const initialState: NotificationState = {
    loading: false,
    ids: [],
    entities: {},
    allNotifications: [],
    systemMessages: []
};

export function reducer(state = initialState, action: NotificationActions): NotificationState {
    switch (action.type) {

        case NotificationActionTypes.LoadShortNotificationList:
            return state;

        case NotificationActionTypes.LoadNotificationsSuccessful:

            const idsAlreadySaved = state.allNotifications.map(x => x.id);
            const notificationsNotAlreadySaved = action.payload.filter(x => false === idsAlreadySaved.includes(x.id));
            return notificationAdapter.addAll(action.payload, {
                ...state,
                allNotifications: state.allNotifications.concat(notificationsNotAlreadySaved),
                loading: false
            });

        case NotificationActionTypes.LoadSystemMessagesSuccessful:
            return {
                ...state,
                systemMessages: action.systemMessages
            };

        case NotificationActionTypes.LoadNotifications:
            return {
                ...state,
                loading: true
            };

        case NotificationActionTypes.MarkNotificationsAsReadSuccessful:
            const updates = [];
            state.ids.forEach(id => {
                updates.push({
                    id,
                    changes: {
                        isViewed: true
                    }
                });
            });

            return notificationAdapter.updateMany(updates, state);

        default:
            return state;
    }
}

export const getNotificationState = createFeatureSelector<NotificationState>(notificationFeatureKey);

export const {
    selectAll,
    selectIds,
    selectEntities,
    selectTotal
} = notificationAdapter.getSelectors(getNotificationState);

export const getShortNotificationArray = createSelector(
    getNotificationState,
    state => {
        const entities = state.entities;
        const returnArray = [];
        for (const key in entities) {
            if (entities.hasOwnProperty(key)) {
                const element = entities[key];
                returnArray.push(element);
            }
        }
        returnArray.sort(sortNotificationsForViewed);

        return returnArray;
    }
);

export const getNotificationsForTable = createSelector(
    getNotificationState,
    state => {
        return state.allNotifications;
    }
);

export const getSystemMessages = createSelector(
    getNotificationState,
    state => {
        return state.systemMessages;
    }
);
