import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IsAlreadyLoggedIn } from "src/app/login/store/actions/login.actions";
import { LoginState } from "src/app/login/store/reducers/login.reducer";
import { LoginCallbackDto } from "src/app/shared/models/login-callback-dto";
import { User } from "src/app/shared/models/user.model";
import { environment } from "src/environments/environment";

import { PortalUser } from "./../../msp/models/user-lists.model";


@Injectable({
    providedIn: "root"
})
export class AuthService {
    baseUrl = `${environment.url}`;

    constructor(private http: HttpClient,
                private store: Store<LoginState>) {
        if (this.getToken()) {
            this.store.dispatch(new IsAlreadyLoggedIn());
        }
    }

    getToken(): string {
        return localStorage.getItem("token");
    }

    login(): Observable<LoginCallbackDto> {
        return this.http.get<LoginCallbackDto>(`${this.baseUrl}/oauth/signin-callback`);
    }

    createUser(): Observable<PortalUser> {
        return this.http.post<PortalUser>(`${this.baseUrl}/api/users/register`, {
            user: null
        });
    }

    getUser(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}/api/me`);
    }

    getUserImage(): Observable<Blob> {
        return this.http
            .get(`${this.baseUrl}/api/me/photo`, { responseType: "blob" });
    }

    logout(): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/oauth/logout`, {});
    }
}
