import { Action } from "@ngrx/store";
import { ProductMapping } from "src/app/billing/models/product-mapping.model";
import {
    AgreementPostFullObject,
    AgreementProductProfileDto,
    NewSubscriptionPostFullObject,
} from "src/app/licenses/models/agreementProductProfileDto.model";

import { ReferenceValue } from "./../../../shared/models/reference-value.model";
import { ClientAgreement } from "./../../models/agreementProductProfileDto.model";

export enum LicensesActionTypes {
    LoadLicenses = "[Licenses View] | Loading Licenses | Ongoing",
        LoadLicensePageMappingData = "[License View] Loading License Page Mapping Data Ongoing",
        LoadLicensePageMappingDataSuccess = "[License API] Loading License Page Mapping Data Success",
        LoadLicensesSuccess = "[Licenses API]| Loading Licenses | Success",
        LoadLicensesFailed = "[Licenses API] | Load Licenses | Failed",
        UpdateLicenseCount = "[License View] Update License View",
        UpdateLicenseCountSuccess = "[License API] Update License Count Successful",
        UpdateLicensesBulk = "[License View] Bulk Update Licenses",
        UpdateLicensesBulkSuccess = "[License API] Bulk Update Licenses Successful",
        DeleteSubscription = "[License View] Delete License",
        DeleteSubscriptionSuccess = "[License API] Delete Subscription Successful",
        AddSubscription = "License View] Add Subscription",
        AddSubscriptionSuccess = "[License API] Add Subscription Success",
        AddNewLicenseMapping = "[Mapping] Add New License Mapping",
        UpdateLicenseMapping = "[Mapping] Update License Mapping",
        LicenseMappingSuccess = "[Mapping API] License Mapping Call Successful",
        RemoveLicenseMapping = "[Mapping] Remove License Mapping",
        RemoveLicenseMappingSuccess = "[Mapping API] Remove License Mapping Success",
        AddNewAgreementToStore = "[Mapping API] New Agreement Added",
        AddNewProductMappingToLicenseStore = "[Mapping API] New Product Mapping Added",
        StoreCurrentAgreement = "[License View] Store Current Agreement"
}

export class LoadLicenses implements Action {
    readonly type = LicensesActionTypes.LoadLicenses;
    constructor(public tenantId: string, public clientId: number, public psaId: string) { }
}

export class LoadLicensePageMappingData implements Action {
    readonly type = LicensesActionTypes.LoadLicensePageMappingData;
    constructor() {}
}

export class LoadLicensePageMappingDataSuccess implements Action {
    readonly type = LicensesActionTypes.LoadLicensePageMappingDataSuccess;
    constructor(public payload: any[]) {}
}

export class LoadLicensesSuccess implements Action {
    readonly type = LicensesActionTypes.LoadLicensesSuccess;
    constructor(public payload: any[]) {}
}

export class LoadLicensesFailed implements Action {
    readonly type = LicensesActionTypes.LoadLicensesFailed;
    constructor() {}
}

export class AddSubscription implements Action {
    readonly type = LicensesActionTypes.AddSubscription;
    constructor(public clientId: number, public payload: NewSubscriptionPostFullObject) {}
}

export class AddSubscriptionSuccess implements Action {
    readonly type = LicensesActionTypes.AddSubscriptionSuccess;
    constructor(public payload: any) {}
}

export class UpdateLicenseCount implements Action {
    readonly type = LicensesActionTypes.UpdateLicenseCount;
    constructor(public payload: any) {}
}

export class UpdateLicenseCountSuccess implements Action {
    readonly type = LicensesActionTypes.UpdateLicenseCountSuccess;
    constructor() {}
}

export class UpdateLicenseBulk implements Action {
    readonly type = LicensesActionTypes.UpdateLicensesBulk;
    constructor(public tenantId: string, public clientId: number, public psaId: string, public payload: any) { }
}

export class UpdateLicenseBulkSuccess implements Action {
    readonly type = LicensesActionTypes.UpdateLicensesBulkSuccess;
    constructor(public tenantId: string, public clientId: number, public psaId: string) { }
}

export class DeleteSubscription implements Action {
    readonly type = LicensesActionTypes.DeleteSubscription;
    constructor(public clientId: string, public payload: any) {}
}

export class DeleteSubscriptionSuccess implements Action {
    readonly type = LicensesActionTypes.DeleteSubscriptionSuccess;
    constructor(public subscriptionId: string) {}
}

export class AddNewLicenseMapping implements Action {
    readonly type = LicensesActionTypes.AddNewLicenseMapping;
    constructor(public clientId: number, public agreementDetails: AgreementPostFullObject) {}
}

export class RemoveLicenseMapping implements Action {
    readonly type = LicensesActionTypes.RemoveLicenseMapping;
    constructor(public clientId: number, public profileId: number) {}
}

export class RemoveLicenseMappingSuccess implements Action {
    readonly type = LicensesActionTypes.RemoveLicenseMappingSuccess;
    constructor(public profileId: number) {}
}

export class LicenseMappingSuccess implements Action {
    readonly type = LicensesActionTypes.LicenseMappingSuccess;
    constructor(public payload: AgreementProductProfileDto) {}
}

export class AddNewAgreementToStore implements Action {
    readonly type = LicensesActionTypes.AddNewAgreementToStore;
    constructor(public payload: ClientAgreement) {}
}

export class AddNewProductMappingToLicenseStore implements Action {
    readonly type = LicensesActionTypes.AddNewProductMappingToLicenseStore;
    constructor(public payload: ProductMapping) {}
}

export class StoreCurrentAgreement implements Action {
    readonly type = LicensesActionTypes.StoreCurrentAgreement;
    constructor(public agreement: ReferenceValue) {}
}


export type LicensesActions = LoadLicenses |
    LoadLicensesSuccess |
    LoadLicensesFailed |
    UpdateLicenseCount |
    LoadLicensePageMappingData |
    LoadLicensePageMappingDataSuccess |
    AddSubscription |
    AddSubscriptionSuccess |
    UpdateLicenseCountSuccess |
    UpdateLicenseBulk |
    UpdateLicenseBulkSuccess |
    DeleteSubscription |
    DeleteSubscriptionSuccess |
    AddNewLicenseMapping |
    RemoveLicenseMapping |
    RemoveLicenseMappingSuccess |
    LicenseMappingSuccess |
    AddNewAgreementToStore |
    AddNewProductMappingToLicenseStore |
    StoreCurrentAgreement;
