import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTableModule,
} from "@angular/material";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NotificationRoutingModule } from "src/app/notification/notification-routing.module";

import { NotificationListViewComponent } from "./components/notification-list-view/notification-list-view.component";
import { NotificationEffects } from "./notification/effects/notification.effects";
import * as fromNotification from "./notification/reducers/notification.reducer";
import { NotificationViewComponent } from "./pages/notification-view/notification-view.component";

@NgModule({
    declarations: [NotificationViewComponent, NotificationListViewComponent],
    imports: [
        CommonModule,
        NotificationRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        ScrollingModule,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatDatepickerModule,
        MatNativeDateModule,
        StoreModule.forFeature(fromNotification.notificationFeatureKey, fromNotification.reducer),
        EffectsModule.forFeature([NotificationEffects])
    ],
    entryComponents: [NotificationListViewComponent]
})
export class NotificationModule {}
