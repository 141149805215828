import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    selector: "app-notification-popup",
    templateUrl: "./notification-popup.component.html",
    styleUrls: ["./notification-popup.component.scss"]
})
export class NotificationPopupComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<NotificationPopupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: NotificationPopupModel) { }

    ngOnInit() { }

    onOkPress(): void {
        this.dialogRef.close(true);
    }
}

export class NotificationPopupModel {
    time: Date;
    clientName: string;
    message: string;
}
