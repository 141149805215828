import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { getUserId, LoginState } from "src/app/login/store/reducers/login.reducer.js";
import { environment } from "src/environments/environment.js";

import { SystemMessage } from "./../../admin/models/systemMessage.model";
import { Notification } from "./../models/notification.model";

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    baseUrl = `${environment.url}/api/notification`;
    userId$ = this.loginStore.select(getUserId);

    constructor(private http: HttpClient, private loginStore: Store < LoginState > ) {}

    getNotifications(): Observable < any > {
        return this.userId$.pipe(
            switchMap(userId => {
                if (userId === null) {
                    return of([]);
                }
                return this.http.get(`${this.baseUrl}/user/${userId}`);
            }));

    }

    getNotificationsForPage(page: number): Observable < any > {
        return this.userId$.pipe(
            switchMap(userId => {
                if (userId === null) {
                    return of([]);
                }
                return this.http.get(`${this.baseUrl}/user/${userId}/?page=${page}`);
            }));
    }

    markNotificationsAsViewed(notifications: Notification[]): Observable < any > {
        return this.userId$.pipe(
            switchMap(userId => {
                return this.http.put(`${this.baseUrl}/user/${userId}/read`, notifications);
            }));
    }

    getSystemMessages(): Observable < SystemMessage[] > {
        return this.http.get<SystemMessage[]>(`${this.baseUrl}/system-messages`);
    }

    markSystemMessageAsRead(id: number) {
        return this.http.put(`${this.baseUrl}/system-messages/${id}/read`, {});
    }
}
