import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PsaClientDto } from "src/app/shared/models/psa-client-dto";

import { PsaClientsItemOverrides } from "../../models/psaClientsItemOverrides.model";
import { ItemActions, ItemActionTypes } from "../actions/item.actions";
import { VendorProductPickListItem } from "./../../../billing/models/product-mapping.model";
import { ItemOverride } from "./../../models/itemOverride.model";
import { ItemProfile } from "./../../models/itemProfile.model";

export const itemFeatureKey = "item";

export const itemAdapter = createEntityAdapter<ItemProfile>();

export interface ItemState extends EntityState<ItemProfile> {
    loading: boolean;
    loadingSave: boolean;
    clientOptions: PsaClientDto[];
    psaClientsItemOverrides: PsaClientsItemOverrides[];
    vendorProducts: VendorProductPickListItem[];
}

export const defaultState: ItemState = {
    loading: false,
    loadingSave: false,
    clientOptions: [],
    psaClientsItemOverrides: [],
    vendorProducts: [],
    ids: [],
    entities: {}
};

export const initialState: ItemState = itemAdapter.getInitialState(defaultState);


export function itemReducer(state = initialState, action: ItemActions): ItemState {
    let newClientsOverrides: PsaClientsItemOverrides[];
    switch (action.type) {
        case ItemActionTypes.GetItemProfiles:
            return {
                ...state,
                loading: true
            };

        case ItemActionTypes.GetItemProfilesSuccess:
            const itemProfiles: ItemProfile[] = action.data[0];
            const psaClientsItemOverrides: PsaClientsItemOverrides[] = action.data[1];
            const clients: PsaClientDto[] = action.data[2];
            const vendorProducts: VendorProductPickListItem[] = action.data[3];

            return itemAdapter.addAll(itemProfiles, {
                ...state,
                loading: false,
                clientOptions: clients,
                psaClientsItemOverrides,
                vendorProducts
            });

        case ItemActionTypes.GetItemProfilesFailed:
            return {
                ...state,
                loading: false
            };

        case ItemActionTypes.AddUpdateDeleteItems:
            return {
                ...state,
                loadingSave: true
            };

        case ItemActionTypes.AddUpdateDeleteItemsSuccess:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.AddUpdateDeleteItemsFailed:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.AddOrUpdateItems:
            return {
                ...state,
                loadingSave: true
            };

        case ItemActionTypes.AddOrUpdateItemsSuccess:
            // Map updated item overrides
            const newItemProfiles = action.itemProfiles.map((itemProfile) => {
                // Note: NgRx doesn't allow to mutate the property as it is READ-ONLY
                // Hence, we create another object to mutate the itemOverrides
                const newItemProfile = { ...itemProfile };
                const existingId = (state.ids as number[]).find(id => id === itemProfile.id);
                if (existingId) {
                    const existingItemProfile: ItemProfile = state.entities[existingId];
                    const existingItemOverrides
                        = existingItemProfile.itemOverrides ? existingItemProfile.itemOverrides : [];

                    // Create array for unchanged item overrides
                    const allNewItemOverrideIds: number[]
                        = itemProfile.itemOverrides.map(x => x.id);
                    let updatedItemOverride: ItemOverride[]
                        = existingItemOverrides.filter(x => !allNewItemOverrideIds.includes(x.id));

                    // Concat the unchanged item overrides with updated item overrides
                    updatedItemOverride = [...updatedItemOverride, ...itemProfile.itemOverrides];

                    newItemProfile.itemOverrides = updatedItemOverride;
                }
                return newItemProfile;
            });

            return itemAdapter.upsertMany(newItemProfiles, {
                ...state,
                loadingSave: false
            });

        case ItemActionTypes.AddOrUpdateItemsFailed:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.DeleteItems:
            return {
                ...state,
                loadingSave: true
            };

        case ItemActionTypes.DeleteItemProfilesSuccess:
            return itemAdapter.removeMany(action.itemProfileIds, {
                ...state,
                loadingSave: false
            });

        case ItemActionTypes.DeleteItemOverridesSuccess:
            const allItemProfileIds: number[] = state.ids as number[];
            const updatedItemProfiles = allItemProfileIds.map((id) => {
                const itemProfile: ItemProfile = { ...state.entities[id] };

                const itemOverrides = itemProfile.itemOverrides ? itemProfile.itemOverrides : [];

                const updatedItemOverrides: ItemOverride[]
                    = itemOverrides.filter(x => !action.itemOverrideIds.includes(x.id));

                itemProfile.itemOverrides = updatedItemOverrides;
                return itemProfile;
            });

            return itemAdapter.upsertMany(updatedItemProfiles, {
                ...state,
                loadingSave: false
            });

        case ItemActionTypes.DeleteItemsFailed:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.AddUpdateDeleteClientsOverrides:
            return {
                ...state,
                loadingSave: true
            };

        case ItemActionTypes.AddUpdateDeleteClientsOverridesSuccess:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.AddUpdateDeleteClientsOverridesFailed:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.AddOrUpdateClientsOverrides:
            return {
                ...state,
                loadingSave: true
            };

        case ItemActionTypes.AddOrUpdateClientsOverridesSuccess:
            newClientsOverrides = [...state.psaClientsItemOverrides];

            for (const clientOverride of action.clientsOverrides) {
                const foundClientOverride = newClientsOverrides.find(x => x.id === clientOverride.id);

                if (foundClientOverride) {
                    const foundIndex = newClientsOverrides.indexOf(foundClientOverride);
                    newClientsOverrides[foundIndex] = clientOverride;
                    continue;
                }

                newClientsOverrides.push(clientOverride);
            }

            return {
                ...state,
                psaClientsItemOverrides: newClientsOverrides,
                loadingSave: false
            };

        case ItemActionTypes.AddOrUpdateClientsOverridesFailed:
            return {
                ...state,
                loadingSave: false
            };

        case ItemActionTypes.DeleteClientsOverrides:
            return {
                ...state,
                loadingSave: true
            };

        case ItemActionTypes.DeleteClientsOverridesSuccess:
            newClientsOverrides = [...state.psaClientsItemOverrides];
            newClientsOverrides = newClientsOverrides.filter(x => !action.clientOverrideIds.includes(x.id));
            return {
                ...state,
                psaClientsItemOverrides: newClientsOverrides,
                loadingSave: false
            };

        case ItemActionTypes.DeleteClientsOverridesFailed:
            return {
                ...state,
                loadingSave: false
            };

        default:
            return state;
    }
}

export const getItemState = createFeatureSelector<ItemState>(itemFeatureKey);

export const loading = (state: ItemState) => state.loading;

export const clientOptions = (state: ItemState) => {
    const clientOptions = [...state.clientOptions];
    const sortedClients = clientOptions.sort((a, b) => {
        if (a.psaClientName < b.psaClientName) {
            return -1;
        }
        if (a.psaClientName > b.psaClientName) {
            return 1;
        }
        return 0;
    });

    return sortedClients;
};

export const vendorProducts = (state: ItemState) => state.vendorProducts;

export const getLoading = createSelector(
    getItemState,
    loading
);

export const getClientOptions = createSelector(
    getItemState,
    clientOptions
);

export const getVendorItems = createSelector(
    getItemState,
    vendorProducts
);

export const getItemOverrides = createSelector(
    getItemState,
    (state: ItemState) => {
        let returnOverrides: ItemOverride[] = [];

        state.ids.forEach(id => {
            const itemProfile: ItemProfile = state.entities[id];

            const itemOverrides = itemProfile.itemOverrides ? itemProfile.itemOverrides : [];

            returnOverrides = [...returnOverrides, ...itemOverrides];
        });

        return returnOverrides;
    }
);

export const getNceVendorItems = createSelector(
    getItemState,
    (state: ItemState) => {
        const returnOverrides = state.vendorProducts.filter(x => x.name.includes(" NCE "));
        return state.vendorProducts;
    }
);

export const getPsaClientsItemOverrides = (itemOverrideId: number) => createSelector(
    getItemState,
    (state: ItemState) => {
        const returnClientsOverrides: PsaClientsItemOverrides[] = state.psaClientsItemOverrides
            .filter(x => x.itemOverrideId === itemOverrideId);

        return returnClientsOverrides;
    }
);

export const getPsaClientsItemOverridesByIds = (itemOverrideIds: number[]) => createSelector(
    getItemState,
    (state: ItemState) => {
        const returnClientsOverrides: PsaClientsItemOverrides[] = state.psaClientsItemOverrides
            .filter(x => itemOverrideIds.includes(x.itemOverrideId));

        return returnClientsOverrides;
    }
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = itemAdapter.getSelectors(getItemState);
