import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";

import { OpenSnackbar, SnackbarActions } from "../actions/snackbar.actions";

@Injectable()
export class SnackbarEffects {

    showSnackbar$ = createEffect(() => this.actions.pipe(
        ofType<OpenSnackbar>(SnackbarActions.SnackbarOpen), // Using the action class type
        tap(action => {
            const { message, action: actionBtn, config } = action.payload;
            this.matSnackBar.open(message, actionBtn, config);
        })
    ), { dispatch: false });

    constructor(
        private actions: Actions,
        private matSnackBar: MatSnackBar
    ) { }
}
