import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DictionaryHolder } from "src/app/msp/models/dictionary.model";
import { PsaTypes, VendorTypes } from "src/app/msp/models/msp.model";
import {
    azureSettingsValidator,
    invoiceFeatureValidator,
    nceSettingsFeatureValidator,
} from "src/app/shared/functions/validators";
import { LoginCallbackDto } from "src/app/shared/models/login-callback-dto";
import { User } from "src/app/shared/models/user.model";

import { SyncPeriods } from "../../../shared/enums/periods.enum";
import { LoginActions, LoginActionTypes } from "../actions/login.actions";
import { ProfileTypes } from "./../../../shared/enums/types.enum";

export const loginFeatureKey = "login";

export interface LoginState {
    isAuthenticated: boolean;
    user: User | null;
    errorMessage: string | null;
    callBackDto: LoginCallbackDto;
    dictionary: DictionaryHolder;
    loading: boolean;
}

export const initialState: LoginState = {
    isAuthenticated: false,
    user: null,
    loading: false,
    dictionary: new DictionaryHolder(),
    errorMessage: null,
    callBackDto: null
};

export function loginReducer(state = initialState, action: LoginActions): LoginState {
    switch (action.type) {
        case LoginActionTypes.loginCallback:
            return {
                ...state,
                loading: true
            };

        case LoginActionTypes.Login:
            return {
                ...state,
                loading: true
            };

        case LoginActionTypes.mspRequiresFurtherSetup:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                callBackDto: action.payload
            };

        case LoginActionTypes.loginCallbackSuccess:
            // updated is authenticated
            // All that jazz
            const newDictionary = new DictionaryHolder();

            switch (action.payload.msp.psaType) {
                case 0:
                    newDictionary.setupAsConnectwiseDictionary();
                    break;
                case 1:
                    newDictionary.setupAsAutotaskDictionary();
                    break;
            }
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                dictionary: newDictionary,
                callBackDto: action.payload
            };

        case LoginActionTypes.loginCallbackFailed:
            return {
                ...state,
                loading: false
            };

        case LoginActionTypes.isAlreadyLoggedIn:
            return {
                ...state,
                isAuthenticated: true
            };

        case LoginActionTypes.setCallbackDto:
            const newCallback = new LoginCallbackDto();
            newCallback.msp = action.payload;
            newCallback.profile = new User();
            newCallback.profile.mspId = newCallback.msp.id;
            if (state.callBackDto) {
                newCallback.mspExists = state.callBackDto.mspExists;
                newCallback.userExists = state.callBackDto.userExists;
                newCallback.profile = state.callBackDto.profile;
            }

            return {
                ...state,
                callBackDto: newCallback
            };

        case LoginActionTypes.loginRedirect:
        case LoginActionTypes.logoutSuccessful:
            return initialState;

        case LoginActionTypes.updateIsAuthenticated:
            return {
                ...state,
                isAuthenticated: action.authenticated
            };

        case LoginActionTypes.getProfileSuccessful:
            return {
                ...state,
                user: action.user
            };

        case LoginActionTypes.updateMspNceSettings:
            if (!state.callBackDto || !state.callBackDto.msp) {
                return state;
            }

            const newCallbackDto: LoginCallbackDto = JSON.parse(JSON.stringify(state.callBackDto));

            newCallbackDto.msp.mspNceSettings = action.mspNceSettings;

            return {
                ...state,
                callBackDto: newCallbackDto
            };

        default:
            return state;
    }
}

export const getLoginState = createFeatureSelector<LoginState>("login");

export const getMspId = createSelector(
    getLoginState,
    (state => {
        return state.callBackDto ? state.callBackDto.profile.mspId : null;
    })
);

export const getUserId = createSelector(
    getLoginState,
    (state => {
        return state.callBackDto ? state.callBackDto.profile.id : null;
    })
);
export const refactorTextForPsa = (text: string) => createSelector(
    getLoginState,
    (state: LoginState) => {
        const dictionary = state.dictionary.dictionary;
        let returnText = text;
        Object.keys(dictionary).forEach(key => {
            returnText = returnText.replace(key, dictionary[key]);
        });

        return returnText;
    }
);

export const isLoading = createSelector(
    getLoginState,
    (state => state.loading)
);

export const getSigninUser = createSelector(
    getLoginState,
    (state) => {
        // Check if callBackDto is null or undefined before accessing its properties
        if (!state.callBackDto) {
            return null;
        }
        return state.callBackDto.profile;
    }
);


export const getUser = createSelector(
    getLoginState,
    (state => state.user)
);

export const getMspName = createSelector(
    getLoginState,
    (state => {
        return state.callBackDto ? state.callBackDto.msp.name : "";
    })
);

export const getVendorType = createSelector(
    getLoginState,
    (state => {
        return state.callBackDto ? state.callBackDto.msp.vendorType : VendorTypes.dickerData;
    })
);

export const isAuthenticated = createSelector(
    getLoginState,
    (state => state.isAuthenticated)
);

export const isInvoiceFeatureEnabled = createSelector(
    getLoginState,
    (state => {
        const msp = state.callBackDto ? state.callBackDto.msp : null;
        if (!msp) {
            return false;
        }
        return invoiceFeatureValidator(msp.featureFlags, msp.mspNceSettings);
    })
);

export const isNceSettingsFeatureEnabled = createSelector(
    getLoginState,
    (state => {
        const msp = state.callBackDto ? state.callBackDto.msp : null;
        // Checking if msp is empty or if the msp is xero
        if (!msp || Object.values(PsaTypes)[msp.psaType] === PsaTypes.Xero) {
            return false;
        }
        return nceSettingsFeatureValidator(msp.featureFlags, msp.mspNceSettings);
    })
);

export const isAzureFeatureEnabled = createSelector(
    getLoginState,
    (state) => {
        const msp = state.callBackDto ? state.callBackDto.msp : null;
        // Additional null check for msp before accessing its properties
        if (!msp) {
            console.log("msp is null");
            return false;
        }
        return azureSettingsValidator(msp.featureFlags, msp.mspNceSettings);
    }
);


export const getMspType = createSelector(
    getLoginState,
    (state => {
        if (state.callBackDto == null) {
            return PsaTypes.DickerData;
        }

        const psaTypeNumber = state.callBackDto.msp.psaType;

        switch (psaTypeNumber) {
            case 0:
                return PsaTypes.ConnectWise;
            case 1:
                return PsaTypes.Autotask;
            case 2:
                return PsaTypes.DickerData;
            case 3:
                return PsaTypes.Microsoft;
            case 4:
                return PsaTypes.Xero;
        }
    })
);

export const isMonthlySync = createSelector(
    getLoginState,
    (state => {
        const msp = state.callBackDto ? state.callBackDto.msp : null;
        if (msp && msp.mspNceSettings) {
            const currentSyncPeriod = Object.values(SyncPeriods)[msp.mspNceSettings.syncPeriod];
            return currentSyncPeriod === SyncPeriods.monthly;
        }
        return false;
    })
);


export const isPartnerCenterSetup = createSelector(
    getLoginState,
    (state) => {
        // Check if callBackDto is null or undefined before accessing its properties
        if (!state.callBackDto || !state.callBackDto.msp) {
            return false;
        }
        return state.callBackDto.msp.partnerCenterIntegrated;
    }
);


export const getProfileType = createSelector(
    getLoginState,
    (state => {
        // Check if callBackDto is null or undefined before accessing its properties
        if (!state.callBackDto || !state.callBackDto.msp) {
            return ProfileTypes.unknown; // or any default value you see fit
        }
        const psaTypeNumber = state.callBackDto.msp.psaType;
        switch (psaTypeNumber) {
            // ConnectWise, AutoTask
            case 0:
            case 1:
                return ProfileTypes.product;

            // Xero
            case 4:
                return ProfileTypes.item;

            default:
                return ProfileTypes.unknown;
        }
    })
);
