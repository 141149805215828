export enum Statuses {
    active = "active",
    processing = "processing",
    syncing = "syncing",
    disabled = "disabled",
    notMapped = "notMapped",
    pb = "pb",
    sa = "sa",
    sb = "sb",
    productMissingMappings = "Product(s) Missing Mappings"
}

export enum LicenseStatuses {
    active = "ACTIVE",
    updating = "UPDATING",
    pending = "PENDING CREATION",
    removed = "REMOVED",
    suspended = "SUSPENDED"
}

export enum InvoiceStatuses {
    pendingSync = "Ready to Sync",
    syncing = "Syncing",
    synced = "Synced"
}

export enum InvoiceSyncAuditLogStatuses {
    success,
    skipped,
    failed
}
