import { Action } from "@ngrx/store";
import { MspPsaClientAgreementSyncTaskDto } from "src/app/client/models/agreementSyncTaskDto.model";
import { Client } from "src/app/client/models/client.model";
import { PsaClientDto } from "src/app/shared/models/psa-client-dto";

export enum ClientsActionTypes {
    LoadClients = "[Clients View Table] | Loading Clients | Ongoing",
    LoadClientsSuccess = "[Client API] | Loading Clients | Success",
    LoadClientsFailed = "[Client API] | Loading Clients | Failed",
    LoadClientPsasSuccess = "[Client API] | Load Clients from PSA | Success",
    StoreClientStatuses = "[Client API] Save Client Statuses",
    CreateClient = "[Clients View Table] Creating Client",
    SetupDelegateAdmin = "[Clients View Table] Setting up delegate admin connection",
    SetupDelegateAdminSuccess = "[Clients View Table] Setting up delegate admin connection Success",
    UpdateClientPsaMapping = "[Clients View Table] Updating Client Psa Connection",
    UpdateClientPsaMappingSuccess = "[Clients View Table] Updating Client Psa Connection Success",
    UpdateClient = "[Clients View Table] Updating Client",
    UpdateClientSuccess = "[Client API] Update Client Success",
    UpdateClientFailure = "[Client API] Update Client Failed",
    DeleteClient = "[Clients View Table] Delete",
    StartManualSync = "[License View Table] Start Manual Sync",
    ManaulSyncStarted = "[Client API] Manual Sync Started",
    SaveClients = "[Client View Table] Save Clients",
    SaveClientsSuccess = "[Clients API] Save Clients API",
    LoadClientSyncStatus = "[Clients View Table] Load Syncing Client Details",
    LoadClientSyncStatusSuccess = "[Clients View Table] Client Sync status load success",
}

export class LoadClients implements Action {
    readonly type = ClientsActionTypes.LoadClients;
}

export class LoadClientsSuccess implements Action {
    readonly type = ClientsActionTypes.LoadClientsSuccess;
    constructor(public clients: Client[]) {}
}

export class LoadClientSyncStatus implements Action {
    readonly type = ClientsActionTypes.LoadClientSyncStatus;
}

export class LoadClientSyncStatusSuccess implements Action {
    readonly type = ClientsActionTypes.LoadClientSyncStatusSuccess;
    constructor(public clients: MspPsaClientAgreementSyncTaskDto[]) {}
}

export class LoadClientsFailed implements Action {
    readonly type = ClientsActionTypes.LoadClientsFailed;
    constructor() {}
}

export class LoadClientPsaSuccess implements Action {
    readonly type = ClientsActionTypes.LoadClientPsasSuccess;
    constructor(public psaClients: PsaClientDto[]) {}
}

export class StoreClientStatuses implements Action {
    readonly type = ClientsActionTypes.StoreClientStatuses;
    constructor(public payload: Client[]) {}
}

export class CreateClient implements Action {
    readonly type = ClientsActionTypes.CreateClient;
    constructor(public client: Client) {}
}

export class SetupDelegateAdmin implements Action {
    readonly type = ClientsActionTypes.SetupDelegateAdmin;
    constructor(public tenantId: string, public mspId: number) {}

}

export class SetupDelegateAdminSuccess implements Action {
    readonly type = ClientsActionTypes.SetupDelegateAdminSuccess;
}

export class UpdateClientPsaMapping implements Action {
    readonly type = ClientsActionTypes.UpdateClientPsaMapping;
    constructor(public payload: Partial < PsaClientDto > ) {}
}

export class UpdateClientPsaMappingSuccess implements Action {
    readonly type = ClientsActionTypes.UpdateClientPsaMappingSuccess;
    constructor(public payload: Partial < PsaClientDto > ) {}
}

export class StartManualSync implements Action {
    readonly type = ClientsActionTypes.StartManualSync;
    constructor(public clientId: number) {}
}

export class ManaulSyncStarted implements Action {
    readonly type = ClientsActionTypes.ManaulSyncStarted;
    constructor() {}
}

export class UpdateClient implements Action {
    readonly type = ClientsActionTypes.UpdateClient;
    constructor(public id: string, public changes: Partial < Client > ) {}
}

export class UpdateClientSuccess implements Action {
    readonly type = ClientsActionTypes.UpdateClientSuccess;
    constructor(public id: string, public changes: Partial < Client > ) {}
}

export class UpdateClientFailed implements Action {
    readonly type = ClientsActionTypes.UpdateClientFailure;
    constructor(public id: string, public changes: Partial < Client > ) {}
}

export class DeleteClient implements Action {
    readonly type = ClientsActionTypes.DeleteClient;
    constructor(public id: string) {}
}

export class SaveClients implements Action {
    readonly type = ClientsActionTypes.SaveClients;
    constructor(public createClients: Partial < PsaClientDto[] >,
                public updateClients: Partial < PsaClientDto[] >,
                public deleteClientMappings: string[] ) {}
}

export class SaveClientsSuccess implements Action {
    readonly type = ClientsActionTypes.SaveClientsSuccess;
    constructor(public createdClients: Partial < PsaClientDto[] >, public updatedClients: Partial < PsaClientDto[] >,
                public deletedClientIds: string[] ) {}
}

export type ClientsActions = LoadClients |
    LoadClientsSuccess |
    LoadClientsFailed |
    LoadClientSyncStatus |
    LoadClientSyncStatusSuccess |
    LoadClientPsaSuccess |
    StoreClientStatuses |
    SetupDelegateAdmin |
    SetupDelegateAdminSuccess |
    UpdateClientPsaMapping |
    UpdateClientPsaMappingSuccess |
    UpdateClient |
    UpdateClientSuccess |
    UpdateClientFailed |
    DeleteClient |
    CreateClient |
    StartManualSync |
    ManaulSyncStarted |
    SaveClients |
    SaveClientsSuccess;
