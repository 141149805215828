import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs/internal/Observable";
import { map, take } from "rxjs/operators";
import { isAzureFeatureEnabled, isInvoiceFeatureEnabled, LoginState } from "src/app/login/store/reducers/login.reducer";


@Injectable({
    providedIn: "root"
})
export class FeatureFlagGuard implements CanActivate {
    constructor(
        private authStore: Store<LoginState>,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const isInvoiceFeatureEnabled$: Observable<boolean> = this.authStore.select(isInvoiceFeatureEnabled);
        const isAzureFeatureEnabled$: Observable<boolean> = this.authStore.select(isAzureFeatureEnabled);
        const routeData = route.data.featureFlag;

        switch (routeData) {
            case "NCE":
                return isInvoiceFeatureEnabled$.pipe(
                    take(1),
                    map((isInvoiceFeatureEnabled) => {
                        console.log("FeatureFlagGuard", isInvoiceFeatureEnabled);
                        return isInvoiceFeatureEnabled;
                    })
                );

            case "Azure":
                return isAzureFeatureEnabled$.pipe(
                    take(1),
                    map((isAzureFeatureEnabled) => {
                        console.log("FeatureFlagGuard", isAzureFeatureEnabled);
                        return isAzureFeatureEnabled;
                    })
                );
        }
    }
}
