import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MspPsaClientAgreementSyncTaskDto } from "src/app/client/models/agreementSyncTaskDto.model";
import { Statuses } from "src/app/shared/enums/statuses.enum";
import { PsaClientDto } from "src/app/shared/models/psa-client-dto";
import { StatusReference } from "src/app/shared/models/status-reference.model";

import * as actions from "../actions/clients.actions";
import { Client } from "./../../models/client.model";

export const clientsFeatureKey = "clients";

function sortByCompanyName(c1: Client, c2: Client) {
    return c1.companyName.toLowerCase()
        .localeCompare(c2.companyName.toLowerCase());
}

export const clientAdaper = createEntityAdapter<Client>({
    sortComparer: sortByCompanyName,
    selectId: client => client.tenantId
});

export interface ClientState extends EntityState<Client> {
    loading: boolean;
    loadingPsaClients: boolean;
    psaClients: PsaClientDto[];
    dataRetrevialDate?: Date;
    clientStatuses: StatusReference[];
    clientSyncingTasks: MspPsaClientAgreementSyncTaskDto[];
}

export const defaultClients: ClientState = {
    ids: [],
    entities: {},
    loading: false,
    loadingPsaClients: false,
    psaClients: [],
    dataRetrevialDate: null,
    clientStatuses: [],
    clientSyncingTasks: []
};

export const initialState: ClientState = clientAdaper.getInitialState(defaultClients);

export function clientReducer(state = initialState, action: actions.ClientsActions): ClientState {
    switch (action.type) {

        case actions.ClientsActionTypes.LoadClients:
            // Call effect to get clients
            return {
                ...state,
                loading: true,
                loadingPsaClients: true,
            };

        case actions.ClientsActionTypes.LoadClientsSuccess:
            return clientAdaper.addAll(action.clients, {
                ...state,
                loading: false,
                dataRetrevialDate: new Date()
            });

        case actions.ClientsActionTypes.LoadClientSyncStatusSuccess:
            return {
                ...state,
                clientSyncingTasks: action.clients
            };

        case actions.ClientsActionTypes.LoadClientsFailed:
            // Call effect to get clients
            return {
                ...state,
                loading: false
            };

        case actions.ClientsActionTypes.LoadClientPsasSuccess:
            return {
                ...state,
                psaClients: action.psaClients,
                loadingPsaClients: false
            };

        case actions.ClientsActionTypes.StoreClientStatuses:
            const statuses: StatusReference[] = action.payload.map(x => {
                const status: StatusReference = {
                    id: x.tenantId,
                    status: Statuses.active
                };
                // Switch based on status
                if (x.psaClientReference === null || typeof x.psaClientReference === undefined) {
                    status.status = Statuses.notMapped;
                }
                return status;
            });
            return {
                ...state,
                clientStatuses: statuses
            };

        case actions.ClientsActionTypes.CreateClient:
            return clientAdaper.addOne(action.client, state);

        case actions.ClientsActionTypes.UpdateClientPsaMapping:
            // Update status to processing
            const newClientStatus = state.clientStatuses.map(x => {
                if (x.id === action.payload.cspId) {
                    return {
                        id: x.id,
                        status: Statuses.processing
                    };
                } else {
                    return x;
                }
            });

            return {
                ...state,
                clientStatuses: newClientStatus
            };

        case actions.ClientsActionTypes.SaveClientsSuccess:
            const updates = [];
            action.createdClients.concat(action.updatedClients).forEach(psaClientDto => {
                const partialClient: Partial<Client> = {
                    psaClientReference: {
                        psaClientName: psaClientDto.psaClientName,
                        companyName: psaClientDto.companyName,
                        psaId: psaClientDto.psaId,
                        cspId: psaClientDto.cspId,
                        id: psaClientDto.id,
                        psaType: psaClientDto.psaType,
                        inheritMspInvoiceDescriptionEnabled: psaClientDto.inheritMspInvoiceDescriptionEnabled,
                        inheritMspInvoiceDescriptionTemplate: psaClientDto.inheritMspInvoiceDescriptionTemplate,
                        invoiceDescriptionTemplate: psaClientDto.invoiceDescriptionTemplate,
                        isInvoiceDescriptionEnabled: psaClientDto.isInvoiceDescriptionEnabled

                    },
                    inheritMspInvoiceDescriptionEnabled: psaClientDto.inheritMspInvoiceDescriptionEnabled,
                    inheritMspInvoiceDescriptionTemplate: psaClientDto.inheritMspInvoiceDescriptionTemplate,
                    invoiceDescriptionTemplate: psaClientDto.invoiceDescriptionTemplate,
                    isInvoiceDescriptionEnabled: psaClientDto.isInvoiceDescriptionEnabled,
                    status: Statuses.active
                };
                updates.push({
                    id: psaClientDto.cspId,
                    changes: partialClient
                });
            });

            action.deletedClientIds.forEach(deletedClientId => {
                const deletedClientUpdates: Partial<Client> = {
                    psaClientReference: null,
                    status: Statuses.notMapped,
                };

                updates.push({
                    id: deletedClientId,
                    changes: deletedClientUpdates
                });
            });

            return clientAdaper.updateMany(updates, state);

        case actions.ClientsActionTypes.UpdateClientPsaMappingSuccess:
            const client: Partial<Client> = {
                psaClientReference: {
                    psaClientName: action.payload.psaClientName,
                    companyName: action.payload.companyName,
                    psaId: action.payload.psaId,
                    cspId: action.payload.cspId,
                    id: action.payload.id,
                    psaType: action.payload.psaType,
                    inheritMspInvoiceDescriptionEnabled: action.payload.inheritMspInvoiceDescriptionEnabled,
                    inheritMspInvoiceDescriptionTemplate: action.payload.inheritMspInvoiceDescriptionTemplate,
                    invoiceDescriptionTemplate: action.payload.invoiceDescriptionTemplate,
                    isInvoiceDescriptionEnabled: action.payload.isInvoiceDescriptionEnabled
                },
                status: Statuses.active
            };

            const completedStatuses = state.clientStatuses.map(x => {
                if (x.id === action.payload.cspId) {
                    return {
                        id: x.id,
                        status: Statuses.active
                    };
                } else {
                    return x;
                }
            });

            // Update status to active
            return clientAdaper.updateOne({
                id: action.payload.cspId,
                changes: client
            }, {
                ...state,
                clientStatuses: completedStatuses
            });

        case actions.ClientsActionTypes.UpdateClient:
        case actions.ClientsActionTypes.UpdateClientSuccess:
        case actions.ClientsActionTypes.UpdateClientFailure:
            return clientAdaper.updateOne({
                id: action.id,
                changes: action.changes
            }, state);

        case actions.ClientsActionTypes.DeleteClient:
            return clientAdaper.removeOne(action.id, state);

        default:
            return state;
    }
}
// ---- SELECTORS ----
export const getClientState = createFeatureSelector<ClientState>(clientsFeatureKey);

export const isLoading = (state: ClientState) => state.loading;

export const loadingPsaClients = (state: ClientState) => state.loadingPsaClients;

export const psaClients = (state: ClientState) => {
    const xeroContacts = [...state.psaClients];
    const sortedContacts = xeroContacts.sort((a, b) => {
        const aName = a.psaClientName.toLowerCase();
        const bName = b.psaClientName.toLowerCase();

        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    });

    return sortedContacts;

};

export const dataRetrevialDate = (state: ClientState) => state.dataRetrevialDate;

export const isClientLoading = createSelector(
    getClientState,
    isLoading
);

export const getLastDataRetrevialDate = createSelector(
    getClientState,
    dataRetrevialDate
);

export const getPsaClients = createSelector(
    getClientState,
    psaClients
);

export const isPsaClientsLoading = createSelector(
    getClientState,
    loadingPsaClients
);

export const getAvaliablePsaClients = (tenantId: string) => createSelector(
    getClientState,
    (state: ClientState) => {
        const idsArray = [];
        const idsInUse: {
            [key: number]: boolean
        } = {};
        // tslint:disable-next-line:forin
        for (const key in state.entities) {
            const client = state.entities[key];

            if (client.tenantId !== tenantId &&
                client.psaClientReference &&
                client.status.toLowerCase() === Statuses.active.toLowerCase()) {
                idsArray.push(client.psaClientReference.psaId);
                idsInUse[client.psaClientReference.psaId] = true;
            }
        }

        const returnPsas = state.psaClients.filter(psa => (false === idsArray.includes(psa.psaId)) || (false === idsInUse[psa.psaId]))
            .sort((a, b) => {
                const nameA = a.psaClientName.toLowerCase();
                const nameB = b.psaClientName.toLowerCase();
                if (nameA > nameB) {
                    return 1;
                } else if (nameA > nameB) {
                    return -1;
                } else {
                    return 0;
                }
            });

        return returnPsas;
    }
);

export const isClientSyncing = (id: number) => createSelector(
    getClientState,
    (state: ClientState) => {
        return state.clientSyncingTasks.findIndex(x => x.mspPsaClientId === id) > -1;
    }
);

export const getFilteredClients = (companyName: string) => createSelector(
    getClientState,
    (state: ClientState) => {
        const returnClientArray: Client[] = [];
        // tslint:disable-next-line:forin
        for (const key in state.entities) {
            const client = state.entities[key];
            companyName = companyName.toLowerCase();
            const clientName = client.companyName.toLowerCase();

            if (companyName !== "" && false === clientName.includes(companyName)) {
                if (client.psaClientReference &&
                    client.psaClientReference.psaClientName.toLowerCase().includes(companyName)) {
                    returnClientArray.push(client);
                } else {
                    continue;
                }
            } else {
                returnClientArray.push(client);
            }
        }

        return returnClientArray;
    }
);

export const getClientStatuses = createSelector(
    getClientState,
    (state: ClientState) => {
        const dict = {};
        state.clientStatuses.forEach(status => {
            dict[status.id] = status.status;
        });
        return dict;
    }
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = clientAdaper.getSelectors(getClientState);
