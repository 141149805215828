export enum SyncTypes {
    legacy,
    nce
}

export enum ProfileTypes {
    product,
    item,
    unknown
}

export enum UserTypes {
    MspAdmin,
    MspUser,
    MspClientUser
}

export enum nceProductIdPrefixes {
    p1y = "p1y",
    p1m = "p1m"
}
