import { AgreementProductProfileDto } from "src/app/licenses/models/agreementProductProfileDto.model";

import { ProductMapping } from "./../../billing/models/product-mapping.model";
import { AgreementPostDto } from "./../../licenses/models/agreementPostDto.model";

export class InvoiceProductMapping {
    stockCode: string;
    profileId: number;
    errorDescription: string;
    method: InvoiceProductMappingMethod;
    productDto: ProductMapping;
    psaAgreementId: number;
    agreementProductProfileDto: AgreementProductProfileDto;
    newAgreement: AgreementPostDto;
}

export enum InvoiceProductMappingMethod {
    addProductProfile,
    mapProductAgreement,
    updateProductAgreement,
    fullMapping,
    unknownMethod
}
