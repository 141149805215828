import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PsaTypes } from "./msp/models/msp.model";
import { AdminConsoleGuard } from "./shared/guards/admin-console-guard.service";
import { AuthGuard } from "./shared/guards/auth-guard.service";
import { FeatureFlagGuard } from "./shared/guards/feature-flag-guard.service";
import { InvoiceType } from "./invoices/models/invoice.model";

const routes: Routes = [{
    path: "clients",
    loadChildren: () => import("./client/client.module")
        .then(m => m.ClientModule),
    canActivate: [AuthGuard],
    data: { mspTypes: [PsaTypes.Autotask, PsaTypes.ConnectWise, PsaTypes.Xero] }
}, {
    path: "licenses",
    loadChildren: () => import("./licenses/licenses.module")
        .then(m => m.LicensesModule),
    canActivate: [AuthGuard],
    data: { mspTypes: [PsaTypes.Autotask, PsaTypes.ConnectWise] }
}, {
    path: "products",
    loadChildren: () => import("./billing/billing.module")
        .then(m => m.BillingModule),
    canActivate: [AuthGuard],
    data: { mspTypes: [PsaTypes.Autotask, PsaTypes.ConnectWise] }
}, {
    path: "items",
    loadChildren: () => import("./item/item.module")
        .then(m => m.ItemModule),
    canActivate: [AuthGuard],
    data: { mspTypes: [PsaTypes.Xero] }
}, {
    path: "login",
    loadChildren: () => import("./login/login.module")
        .then(m => m.LoginModule)
}, {
    path: "msp",
    loadChildren: () => import("./msp/msp.module")
        .then(m => m.MspModule)
}, {
    path: "audit",
    loadChildren: () => import("./audit/audit.module")
        .then(m => m.AuditModule),
    canActivate: [AuthGuard],
    data: { mspTypes: [PsaTypes.Autotask, PsaTypes.ConnectWise] }
}, {
    path: "invoices",
    loadChildren: () => import("./invoices/invoices.module")
        .then(m => m.InvoicesModule),
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
        featureFlag: "NCE",
        invoiceType: InvoiceType.NCE,
        mspTypes: [PsaTypes.Autotask, PsaTypes.ConnectWise, PsaTypes.Xero]
    }
}, {
    path: "azure",
    loadChildren: () => import("./azure/azure.module")
        .then(m => m.AzureModule),
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
        featureFlag: "Azure",
        invoiceType: InvoiceType.Azure,
        mspTypes: [PsaTypes.Autotask, PsaTypes.ConnectWise, PsaTypes.Xero]
    }
}, {
    path: "admin",
    loadChildren: () => import("./admin/admin.module")
        .then(m => m.AdminModule),
    canActivate: [AuthGuard, AdminConsoleGuard]
}, {
    path: "notifications",
    loadChildren: () => import("./notification/notification.module")
        .then(m => m.NotificationModule),
    canActivate: [AuthGuard]
}, {
    path: "",
    redirectTo: "login",
    pathMatch: "prefix"
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
