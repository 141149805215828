import { Component, OnInit } from "@angular/core";
import { LoadingService } from "src/app/shared/services/loading.service";

@Component({
    selector: "app-loading",
    templateUrl: "./loading.component.html",
    styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent implements OnInit {
    public loadingArray = [];

    constructor(private loadingService: LoadingService) {
        this.loadingArray = this.loadingService.loadingTextArray;
     }

    ngOnInit() {
    }
}
