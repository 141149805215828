import { Action } from "@ngrx/store";
import { ItemProfile } from "src/app/item/models/itemProfile.model";
import { PsaClientsItemOverrides } from "src/app/item/models/psaClientsItemOverrides.model";
import { ProfileTypes } from "src/app/shared/enums/types.enum";
import { PsaClientDto } from "src/app/shared/models/psa-client-dto";

import { InvoiceProductMapping } from "../../models/invoiceProductMapping.model";
import { Invoice, InvoiceType } from "./../../models/invoice.model";

export enum InvoicesActionTypes {
    LoadInvoices = "[Invoices View] | Loading Invoices | Ongoing",
    LoadInvoicesSuccess = "[Invoice API] | Loading Invoices | Success",
    LoadInvoicesFailed = "[Invoice API] | Loading Invoices | Failed",
    LoadInvoiceDetails = "[Invoices View] | Loading Invoice Details | Ongoing",
    LoadInvoiceDetailsSuccess = "[Invoice API] | Loading Invoice Details | Success",
    LoadInvoiceDetailsFailed = "[Invoice API] | Loading Invoice Details | Failed",
    SyncInvoice = "[Invoices View] | Syncing Invoice | Ongoing",
    SyncInvoiceSuccess = "[Invoice API] | Syncing Invoice | Success",
    SyncInvoiceFailed = "[Invoice API] | Syncing Invoice | Failed",
    SaveClients = "[Invoices View] | Save Clients | Ongoing",
    SaveClientsSuccess = "[Invoice API] | Save Clients | Success",
    SaveClientsFailed = "[Invoice API] | Save Clients | Failed",
    LoadProductMapping = "[Invoice API] | Load Product Mapping | Ongoing",
    LoadProductMappingSuccess = "[Invoice API] | Load Product Mapping | Success",
    LoadProductMappingFailed = "[Invoice API] | Load Product Mapping | Failed",
    SaveInvoiceProductMapping = "[Invoice API] | Save Product Mapping | Ongoing",
    SaveInvoiceProductMappingSuccess = "[Invoice API] | Save Product Mapping | Success",
    SaveInvoiceProductMappingFailed = "[Invoice API] | Save Product Mapping | Failed",
    RemoveItemOverrides = "[Invoice API] | Remove Item Overrides | Ongoing",
    RemoveItemOverridesSuccess = "[Invoice API] | Remove Item Overrides | Success",
    RemoveItemOverridesFailed = "[Invoice API] | Remove Item Overrides | Failed",
    DeleteInvoiceProductMapping = "[Invoice API] | Delete Product Mapping | Ongoing",
    DeleteInvoiceProductMappingSuccess = "[Invoice API] | Delete Product Mapping | Success",
    DeleteInvoiceProductMappingFailed = "[Invoice API] | Delete Product Mapping | Failed",
    LoadInvoiceSyncLogs = "[Invoice API] | Load Invoice Sync Logs | Ongoing",
    LoadInvoiceSyncLogsSuccess = "[Invoice API] | Load Invoice Sync Logs | Success",
    LoadInvoiceSyncLogsFailed = "[Invoice API] | Load Invoice Sync Logs | Failed",
    SaveMappedItems = "[Invoices View] | Save Invoice Mapped Items | Ongoing",
    SaveMappedItemsSuccess = "[Invoices View] | Save Invoice Mapped Items | Success",
    SaveMappedItemsFailed = "[Invoices View] | Save Invoice Mapped Items | Failed",
    AddOrUpdateInvoiceClientsOverrides = "[Item API] | Add or Update Invoice ClientsOverrides | Ongoing",
    AddOrUpdateInvoiceClientsOverridesSuccess = "[Item API] | Add or Update Invoice ClientsOverrides | Success",
    AddOrUpdateInvoiceClientsOverridesFailed = "[Item API] | Add or Update Invoice ClientsOverrides | Failed"
}

export class LoadInvoices implements Action {
    readonly type = InvoicesActionTypes.LoadInvoices;
    constructor(public invoiceType: InvoiceType) {
    }
}

export class LoadInvoicesSuccess implements Action {
    readonly type = InvoicesActionTypes.LoadInvoicesSuccess;

    constructor(public data: any[]) {
    }
}

export class LoadInvoicesFailed implements Action {
    readonly type = InvoicesActionTypes.LoadInvoicesFailed;

    constructor() {
    }
}

export class LoadInvoiceDetails implements Action {
    readonly type = InvoicesActionTypes.LoadInvoiceDetails;

    constructor(public invoiceId: number) {
    }
}

export class LoadInvoiceDetailsSuccess implements Action {
    readonly type = InvoicesActionTypes.LoadInvoiceDetailsSuccess;

    constructor(public profileType: ProfileTypes, public payload: any[]) {
    }
}

export class LoadInvoicesDetailsFailed implements Action {
    readonly type = InvoicesActionTypes.LoadInvoiceDetailsFailed;

    constructor() {
    }
}

export class SyncInvoice implements Action {
    readonly type = InvoicesActionTypes.SyncInvoice;

    constructor(public invoiceId: number) {
    }
}

export class SyncInvoiceSuccess implements Action {
    readonly type = InvoicesActionTypes.SyncInvoiceSuccess;

    constructor(public invoice: Invoice) {
    }
}

export class SyncInvoiceFailed implements Action {
    readonly type = InvoicesActionTypes.SyncInvoiceFailed;

    constructor() {
    }
}

export class SaveClients implements Action {
    readonly type = InvoicesActionTypes.SaveClients;

    constructor(public invoiceId: number, public clients: Partial<PsaClientDto[]>) {
    }
}

export class SaveClientsSuccess implements Action {
    readonly type = InvoicesActionTypes.SaveClientsSuccess;

    constructor(public invoiceId: number, public payload: PsaClientDto[]) {
    }
}

export class SaveClientsFailed implements Action {
    readonly type = InvoicesActionTypes.SaveClientsFailed;

    constructor() {
    }
}

export class LoadProductMapping implements Action {
    readonly type = InvoicesActionTypes.LoadProductMapping;

    constructor(public psaId: string, public clientId: number) {
    }
}

export class LoadProductMappingSuccess implements Action {
    readonly type = InvoicesActionTypes.LoadProductMappingSuccess;

    constructor(public payload: any[]) {
    }
}

export class LoadProductMappingFailed implements Action {
    readonly type = InvoicesActionTypes.LoadProductMappingFailed;

    constructor() {
    }
}

export class SaveInvoiceProductMapping implements Action {
    readonly type = InvoicesActionTypes.SaveInvoiceProductMapping;

    constructor(public invoiceId: number, public clientId: number, public postObj: InvoiceProductMapping[]) {
    }
}

export class SaveInvoiceProductMappingSuccess implements Action {
    readonly type = InvoicesActionTypes.SaveInvoiceProductMappingSuccess;

    constructor(public invoiceId: number, public clientId: number, public payload: InvoiceProductMapping[]) {
    }
}

export class SaveInvoiceProductMappingFailed implements Action {
    readonly type = InvoicesActionTypes.SaveInvoiceProductMappingFailed;

    constructor() {
    }
}

export class RemoveItemOverrides implements Action {
    readonly type = InvoicesActionTypes.RemoveItemOverrides;
    constructor(
        public itemOverrideIds: number[]
    ) { }
}

export class RemoveItemOverridesSuccess implements Action {
    readonly type = InvoicesActionTypes.RemoveItemOverridesSuccess;
    constructor(
        public itemOverrideIds: number[]
    ) { }
}

export class RemoveItemOverridesFailed implements Action {
    readonly type = InvoicesActionTypes.RemoveItemOverridesFailed;
    constructor() {
    }
}

export class DeleteInvoiceProductMapping implements Action {
    readonly type = InvoicesActionTypes.DeleteInvoiceProductMapping;

    constructor(public invoiceId: number, public clientId: number, public profileId: number) {
    }
}

export class DeleteInvoiceProductMappingSuccess implements Action {
    readonly type = InvoicesActionTypes.DeleteInvoiceProductMappingSuccess;

    constructor(public invoiceId: number, public clientId: number, public profileId: number) {
    }
}

export class DeleteInvoiceProductMappingFailed implements Action {
    readonly type = InvoicesActionTypes.DeleteInvoiceProductMappingFailed;

    constructor() {
    }
}

export class LoadInvoiceSyncLogs implements Action {
    readonly type = InvoicesActionTypes.LoadInvoiceSyncLogs;

    constructor(public invoiceId: number, public invoiceSyncHistoryId: number) {
    }
}

export class LoadInvoiceSyncLogsSuccess implements Action {
    readonly type = InvoicesActionTypes.LoadInvoiceSyncLogsSuccess;

    constructor(public data: any[]) {
    }
}

export class LoadInvoiceSyncLogsFailed implements Action {
    readonly type = InvoicesActionTypes.LoadInvoiceSyncLogsFailed;

    constructor() {
    }
}

export class SaveMappedItems implements Action {
    readonly type = InvoicesActionTypes.SaveMappedItems;

    constructor(public invoiceId: number, public itemProfiles: ItemProfile[]) {
    }
}

export class SaveMappedItemsSuccess implements Action {
    readonly type = InvoicesActionTypes.SaveMappedItemsSuccess;

    constructor(public invoiceId: number, public itemProfiles: ItemProfile[]) {
    }
}

export class SaveMappedItemsFailed implements Action {
    readonly type = InvoicesActionTypes.SaveMappedItemsFailed;

    constructor() {
    }
}

export class AddOrUpdateInvoiceClientsOverrides implements Action {
    readonly type = InvoicesActionTypes.AddOrUpdateInvoiceClientsOverrides;

    constructor(
        public clientsOverrides: PsaClientsItemOverrides[]
    ) {
    }
}

export class AddOrUpdateInvoiceClientsOverridesSuccess implements Action {
    readonly type = InvoicesActionTypes.AddOrUpdateInvoiceClientsOverridesSuccess;

    constructor(
        public clientsOverrides: PsaClientsItemOverrides[]
    ) {
    }
}

export class AddOrUpdateInvoiceClientsOverridesFailed implements Action {
    readonly type = InvoicesActionTypes.AddOrUpdateInvoiceClientsOverridesFailed;

    constructor() {
    }
}

export type InvoicesActions = LoadInvoices |
    LoadInvoicesSuccess |
    LoadInvoicesFailed |
    LoadInvoiceDetails |
    LoadInvoiceDetailsSuccess |
    LoadInvoicesDetailsFailed |
    SyncInvoice |
    SyncInvoiceSuccess |
    SyncInvoiceFailed |
    SaveClients |
    SaveClientsSuccess |
    SaveClientsFailed |
    SaveClientsFailed |
    LoadProductMapping |
    LoadProductMappingSuccess |
    LoadProductMappingFailed |
    SaveInvoiceProductMapping |
    SaveInvoiceProductMappingSuccess |
    SaveInvoiceProductMappingFailed |
    RemoveItemOverrides |
    RemoveItemOverridesSuccess |
    RemoveItemOverridesFailed |
    DeleteInvoiceProductMapping |
    DeleteInvoiceProductMappingSuccess |
    DeleteInvoiceProductMappingFailed |
    LoadInvoiceSyncLogs |
    LoadInvoiceSyncLogsSuccess |
    LoadInvoiceSyncLogsFailed |
    SaveMappedItems |
    SaveMappedItemsSuccess |
    SaveMappedItemsFailed |
    AddOrUpdateInvoiceClientsOverrides |
    AddOrUpdateInvoiceClientsOverridesSuccess |
    AddOrUpdateInvoiceClientsOverridesFailed;
