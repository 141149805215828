import { FormControl } from "@angular/forms";

import { SyncPeriods } from "../enums/periods.enum";

export const noWhitespaceValidator = (control: FormControl) => {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
};

export const isNumberValidator = (control: FormControl) => {
    let controlValue = control.value;

    if (controlValue === null) {
        // Note: Number(null) === 0
        controlValue = undefined;
    }

    const isNumber = !Number.isNaN(Number(controlValue));

    return isNumber ? null : { number: true };
};

export const invoiceFeatureValidator = (featureFlags, mspNceSettings) => {
    const currentSyncPeriod = Object.values(SyncPeriods)[mspNceSettings.syncPeriod];

    if (
        featureFlags !== null
        && featureFlags.nceEnabled
        && mspNceSettings !== null
        && currentSyncPeriod === SyncPeriods.monthly
    ) {
        return true;
    }

    return false;
};

export const nceSettingsFeatureValidator = (featureFlags, mspNceSettings) => {
    return featureFlags !== null
        && featureFlags.nceInvoiceSettingsEnabled;
};

export const azureSettingsValidator = (featureFlags, mspNceSettings) => {
    console.log("azureSettingsValidator");
    return featureFlags !== null
        && featureFlags.azureBillingEnabled;
};
