import { Component, Input, OnInit } from "@angular/core";

import { InvoiceStatuses, InvoiceSyncAuditLogStatuses, Statuses } from "./../../enums/statuses.enum";

@Component({
    selector: "app-status-display",
    templateUrl: "./status-display.component.html",
    styleUrls: ["./status-display.component.scss"]
})
export class StatusDisplayComponent implements OnInit {
    @Input()
    status: string;

    Processing = Statuses.processing;

    displayOptions: {
        [key: string]: StatusDisplay
    } = {
            [Statuses.active]: {
                text: "✓ Active",
                class: "active"
            },
            [Statuses.processing]: {
                text: "! Processing",
                class: "processing"

            },
            [Statuses.syncing]: {
                text: "⮂ Syncing",
                class: "syncing"
            },
            [Statuses.notMapped]: {
                text: "⛌ Client Not Setup",
                class: "not-mapped"
            },
            [Statuses.pb]: {
                text: "Pending BIlling",
                class: "not-mapped"
            },
            [Statuses.sa]: {
                text: "SA",
                class: "not-mapped"
            },
            [Statuses.sb]: {
                text: "SB",
                class: "not-mapped"
            },
            [InvoiceStatuses.syncing]: {
                text: "⮂ Syncing",
                class: "syncing"
            },
            [InvoiceStatuses.pendingSync]: {
                text: "! Ready to Sync",
                class: ""
            },
            [InvoiceStatuses.synced]: {
                text: "✓ Synced",
                class: "active"
            },
            [Statuses.productMissingMappings]: {
                text: "⛌ Products Not Setup",
                class: "not-mapped"
            },
            [InvoiceSyncAuditLogStatuses.success]: {
                text: "✓ Success",
                class: "active"
            },
            [InvoiceSyncAuditLogStatuses.skipped]: {
                text: "! Skipped",
                class: "processing"
            },
            [InvoiceSyncAuditLogStatuses.failed]: {
                text: "⛌ Failed",
                class: "not-mapped"
            }
        };

    constructor() { }

    ngOnInit() { }

}

export class StatusDisplay {
    text: string;
    class: string;
}
