import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "price"
})

// make sure it always has 2 decimal places and negative is at the front of dollar price
export class PricePipe implements PipeTransform {
    transform(price: number | string): string {
        if (price === "-") {
            return price;
        }
        let returnPrice = "$";
        if (price as number < 0) {
            returnPrice = "-" + returnPrice;
        }

        const roundedPrice = Math.abs(Math.round(price as number * 100) / 100);

        returnPrice += roundedPrice.toFixed(2);

        return returnPrice;
    }
}
