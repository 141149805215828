import { DickerDataVendorProduct } from "../../billing/models/product-mapping.model";


export class ProductProfileDisplayDto {
    id: number;
    productProfileId: number;
    agreementProfileId: number;
    display: string;
    dickerDataStockCode: DickerDataVendorProduct;
    isCustomProduct: boolean;
}
