import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ErrorStateMatcher, MAT_DATE_LOCALE, ShowOnDirtyErrorStateMatcher } from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { CookieService } from "ngx-cookie-service";
import { CookieAuthInterceptor } from "src/app/shared/interceptors/cookieAuth.interceptor";
import { HttpLoadingInteceptor } from "src/app/shared/interceptors/httpLoading.interceptor";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppEffects } from "./app.effects";
import { LoginEffects } from "./login/store/effects/login.effects";
import { metaReducers, reducers } from "./reducers";
import { NotificationPopupComponent } from "./shared/components/notification-popup/notification-popup.component";
import { SystemMessagePopupComponent } from "./shared/components/system-message-display/system-message-display.component";
import { HttpFailedInterceptor } from "./shared/interceptors/httpFailed.interceptor";
import { SharedModule } from "./shared/shared.module";
import {VirtualScrollerModule} from "ngx-virtual-scroller";



@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        RouterModule,
        VirtualScrollerModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        RouterModule.forRoot([]),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        }),
        EffectsModule.forRoot([AppEffects, LoginEffects]),
    ],
    providers: [{
        provide: ErrorStateMatcher,
        useClass: ShowOnDirtyErrorStateMatcher
    }, CookieService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CookieAuthInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpLoadingInteceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpFailedInterceptor,
        multi: true
    }, {
        provide: MAT_DATE_LOCALE,
        useValue: "en-AU"
    },
        NgbActiveModal
    ],
    bootstrap: [AppComponent],
    entryComponents: [NotificationPopupComponent, SystemMessagePopupComponent]
})
export class AppModule { }
