import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { billingReducer } from "src/app/billing/store/reducers/billing.reducer";
import { clientReducer } from "src/app/client/store/reducers/clients.reducer";
import { licenseReducers } from "src/app/licenses/store/reducers/licenses.reducer";
import { loginReducer } from "src/app/login/store/reducers/login.reducer";
import { companySettingsReducer } from "src/app/msp/store/reducers/company-settings.reducer";

import { environment } from "../../environments/environment";
import { InvoicesReducer } from "../invoices/store/reducers/invoices.reducer";
import { itemReducer } from "../item/store/reducers/item.reducer";

export const reducers: ActionReducerMap<any> = {
    clients: clientReducer,
    companySettings: companySettingsReducer,
    billingView: billingReducer,
    licenses: licenseReducers,
    login: loginReducer,
    invoices: InvoicesReducer,
    item: itemReducer
};

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];
