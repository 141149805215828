import {CookieService} from "./../../../shared/services/cookies.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { OpenSnackbar } from "src/app/shared/store/actions/snackbar.actions";
import { environment } from "src/environments/environment";

import * as fromActions from "../../store/actions/login.actions";
import { State } from "./../../../shared/store/reducers/snackbar.reducer";
import { AuthService } from "./../../services/login.service";




@Injectable()
export class LoginEffects {


    @Effect({
        dispatch: false
    })
    login$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.Login),
        map((action: fromActions.Login) => action.payload),
        map(() => {
            window.location.href = `${environment.url}/oauth/signin-microsoft?redirectUrl=${window.location.href}`;
        })
    );

    @Effect()
    loginCallback$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.loginCallback),
        switchMap(() => this.loginService.login()
            .pipe(
                map(result => {
                    if (result.mspExists) {

                        if (result.userExists) {
                            if (result.msp.isMspConfigured === false) {
                                return new fromActions.MspNotFullySetup(result);
                            } else {
                                return new fromActions.LoginCallbackSuccess(result);
                            }
                        } else {
                            return new fromActions.CreateUser(result);
                        }
                    } else {
                        // Route to create MSP page
                        return new fromActions.RequireMspCreation(result.msp);
                    }

                }), catchError((error: HttpErrorResponse) => {
                    return of(new fromActions.LoginCallbackFailed(error.error));
                }))));

    @Effect()
    createUser$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.createUser),
        switchMap((action) => this.loginService.createUser()
            .pipe(
                map(() => new fromActions.LoginCallbackSuccess(action.payload)),
                catchError((error: HttpErrorResponse) => of(new OpenSnackbar({
                    message: error.message
                })))
            ))
    );

    @Effect({
        dispatch: false
    })
    createMspReroute$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.requireMspCreation),
        map((action) => {
            this.route.queryParams
                .subscribe(params => {
                    if (params.token) {
                        this.router.navigate(["msp/create"], { queryParams: { token: params.token, mspName: action.msp.name } });
                    } else {
                        // tslint:disable-next-line: max-line-length
                        this.router.navigate(["msp/create"], { queryParams: { mspName: action.msp.name } });
                    }
                });
        })
    );

    @Effect({
        dispatch: false
    })
    finishMspSetup$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.mspRequiresFurtherSetup),
        map((action) => {
            this.route.queryParams
                .subscribe(params => {
                    if (params.token) {
                        this.router.navigate(["msp/create"], {
                            queryParams: {
                                token: params.token,
                            }
                        });
                    } else {
                        // tslint:disable-next-line: max-line-length
                        this.router.navigate(["msp/create"]);
                    }
                });
        })
    );

    @Effect({
        dispatch: false
    })
    loginSuccess$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.LoginSuccess),
        tap((user) => {
            localStorage.setItem("token", user.payload.token);
        })
    );

    @Effect({
        dispatch: false
    })
    loginFailed$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.loginCallbackFailed),
        map((action) => {
            if ("string" === typeof (action.message)) {
                this.sharedStore.dispatch(new OpenSnackbar({
                    message: action.message,
                    action: "close"
                }));
            }
        }));

    @Effect()
    public getProfile$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.getProfile),
        switchMap(() => this.loginService.getUser()),
        map(result => new fromActions.GetProfileSuccessful(result))
    );

    @Effect()
    public logout$ = this.actions$.pipe(
        ofType(fromActions.LoginActionTypes.Logout),
        switchMap(() => {
            return this.loginService.logout().pipe(
                map(() => {
                    this.cookieService.clearAllCookies();
                    return new fromActions.LogoutSuccessful();
                }),
                catchError(error => {
                    console.error("Logout failed", error);
                    return of(new fromActions.LogoutFailed());
                }),
                tap(() => {
                    this.router.navigate([""]);
                })
            );
        })
    );


    constructor(
        private actions$: Actions<fromActions.LoginActions>,
        private cookieService: CookieService,
        private loginService: AuthService,
        private sharedStore: Store<State>,
        private router: Router,
        private route: ActivatedRoute) { }

}
