import { HttpClient } from "@angular/common/http";
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { propertyExists } from "src/app/shared/functions/filters";

@Component({
    selector: "app-selection-list",
    templateUrl: "./selection-list.component.html",
    styleUrls: ["./selection-list.component.scss"],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectionListComponent),
        multi: true
    }]
})
export class SelectionListComponent implements OnInit, OnChanges, ControlValueAccessor {

    @Input()
    data$: Observable < any[] > ;

    @Input()
    fieldToDisplay: string;

    @Input()
    hint: string;

    filterListData$: Observable < any[] > ;

    public disabled = false;

    public touched = false;

    editing = false;

    public value: any;

    selectedItemInList: any;

    form: FormGroup;

    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.form = new FormGroup({
            search: new FormControl("")
        });

        this.form.get("search").valueChanges.pipe(
            tap((searchString: string) => {
                this.filterListData$ = this.data$.pipe(
                    // tslint:disable-next-line:max-line-length
                    map((data) => data = data.filter(x => propertyExists(x, this.fieldToDisplay, searchString))));
            })
        ).subscribe();

        // this.value = referenceValues[0];
        this.selectedItemInList = this.value;
        this.filterListData$ = this.data$;
    }

    onChange = (delta: any) => {};

    onTouched = () => {
        this.touched = true;
    }

    ngOnChanges(changes: SimpleChanges): void {}

    writeValue(obj: any): void {
        this.value = obj;
        this.onChange(this.value);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState ?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    selectItem(item: any) {
        this.selectedItemInList = item;
    }

    saveSelection() {
        this.writeValue(this.selectedItemInList);
        this.editing = false;
    }

    cancelSelection() {
        this.editing = false;
        this.selectedItemInList = this.value;
    }

    remove() {
        this.writeValue("");
        this.editing = !this.editing;
    }
}
