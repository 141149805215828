import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-mat-table-header",
  templateUrl: "./mat-table-header.component.html",
  styleUrls: ["./mat-table-header.component.scss"]
})
export class MatTableHeaderComponent implements OnInit {

  constructor() { }
  @Input()
  tooltip: string;

  ngOnInit() {
  }

}
