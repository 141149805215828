import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { GetProfile, Logout } from "src/app/login/store/actions/login.actions";
import { LoginState } from "src/app/login/store/reducers/login.reducer";

import { AuthService } from "../../../login/services/login.service";
import { getUser } from "./../../../login/store/reducers/login.reducer";

@Component({
    selector: "app-user-mini-display",
    templateUrl: "./user-mini-display.component.html",
    styleUrls: ["./user-mini-display.component.scss"]
})
export class UserMiniDisplayComponent implements OnInit {
    user$ = this.store.select(getUser);
    imageToShow: any;

    constructor(private store: Store<LoginState>, private authService: AuthService, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.store.dispatch(new GetProfile());

        this.authService.getUserImage().subscribe(blob => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.imageToShow = reader.result;
            }, false);

            if (blob) {
                reader.readAsDataURL(blob);
            }
        });
    }

    logout() {
        this.store.dispatch(new Logout());
    }
}
