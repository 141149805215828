export enum apiTypes {
    ConnectWise = 0,
    AutoTask = 1,
    DickerData = 2,
    Microsoft = 3,
    Xero = 4
}

export class DickerData {
    accessCode: string;
    accountCode: string;
    mpnId: string;
}

export class AutoTask {
    authenticationId: string;
    authenticationKey: string;
    materialCode: string;
}

export class ConnectWise {
    serviceUrl: string;
    companyName: string;
    publicKey: string;
    privateKey: string;
    companyNameInCw: string;
}

export class ApiCredentials {
    id: number;
    apiType: apiTypes;
    apiConfiguration: AutoTask | ConnectWise | DickerData | MicrosoftCredentials;
}

export class MicrosoftCredentials {
    applicationId: string;
    applicationSecret: string;
}
