import { Action } from "@ngrx/store";
import { Msp } from "src/app/msp/models/msp.model";

import { MspNceSettings } from "./../../../msp/models/msp-nce-settings.model";
import { User } from "./../../../shared/models/user.model";

export enum LoginActionTypes {
    Login = "[Login Page] Login",
    LoginSuccess = "[Login API] Login Successful",
    LoginFailed = "[Login API] Login Failed",
    loginRedirect = "[Login API] Login Redirect",
    Logout = "[Login] Logout",
    isAlreadyLoggedIn = "[Startup] Login Exists",
    loginCallback = "[Login] Login Callback",
    loginCallbackSuccess = "[Login API] Login Callback Success",
    loginCallbackFailed = "[Login API] Login Callback Failed",
    logoutSuccessful = "[Logout API] Logout Successful",
    Logoutfailed = "[Logout API] Logout Failed",
    loginUserNotAllowed = "[Login API] Login User Not Allowed",
    createUser = "[Login] Create User",
    updateIsAuthenticated = "[Login] Update Is Authenticated",
    requireMspCreation = "[Login] No MSP Found",
    mspRequiresFurtherSetup = "[Login] MSP Not Fully Setup",
    setCallbackDto = "[Register MSP] Set Callback Dto",
    getProfile = "[Profile API] Get Profile",
    getProfileSuccessful = "[Profile API] Get Profile Successful",
    updateMspNceSettings = "[Msp API] Update Msp Nce Settings"
}

export class Login implements Action {
    readonly type = LoginActionTypes.Login;
    constructor(public payload: any) { }
}

export class LoginSuccess implements Action {
    readonly type = LoginActionTypes.LoginSuccess;
    constructor(public payload: any) { }
}

export class LoginFailed implements Action {
    readonly type = LoginActionTypes.LoginSuccess;
    constructor(public payload: any) { }
}

export class LoginRedirect implements Action {
    readonly type = LoginActionTypes.loginRedirect;
}

export class Logout implements Action {
    readonly type = LoginActionTypes.Logout;
}

export class LogoutSuccessful implements Action {
    readonly type = LoginActionTypes.logoutSuccessful;
}

export class LogoutFailed implements Action {
    readonly type = LoginActionTypes.Logoutfailed;
}

export class IsAlreadyLoggedIn implements Action {
    readonly type = LoginActionTypes.isAlreadyLoggedIn;
}

export class LoginCallback implements Action {
    readonly type = LoginActionTypes.loginCallback;
}

export class CreateUser implements Action {
    readonly type = LoginActionTypes.createUser;
    constructor(public payload: any) { }
}

export class UpdateIsAuthenticated implements Action {
    readonly type = LoginActionTypes.updateIsAuthenticated;
    constructor(public authenticated: boolean) { }
}

export class LoginCallbackSuccess implements Action {
    readonly type = LoginActionTypes.loginCallbackSuccess;
    constructor(public payload: any) { }
}

export class LoginCallbackFailed implements Action {
    readonly type = LoginActionTypes.loginCallbackFailed;
    constructor(public message: string) { }
}

export class LoginUserNotAllowed implements Action {
    readonly type = LoginActionTypes.loginUserNotAllowed;
    constructor(public payload: any) { }
}

export class RequireMspCreation implements Action {
    readonly type = LoginActionTypes.requireMspCreation;
    constructor(public msp: Msp) { }
}

export class MspNotFullySetup implements Action {
    readonly type = LoginActionTypes.mspRequiresFurtherSetup;
    constructor(public payload: any) { }
}

export class SetCallbackDto implements Action {
    readonly type = LoginActionTypes.setCallbackDto;
    constructor(public payload: any) { }
}

export class GetProfile implements Action {
    readonly type = LoginActionTypes.getProfile;
    constructor() { }
}

export class GetProfileSuccessful implements Action {
    readonly type = LoginActionTypes.getProfileSuccessful;
    constructor(public user: User) { }
}

export class UpdateMspNceSettings implements Action {
    readonly type = LoginActionTypes.updateMspNceSettings;
    constructor(public mspNceSettings: MspNceSettings) { }
}

export type LoginActions =
    Login |
    LoginSuccess |
    LoginFailed |
    LoginRedirect |
    Logout |
    IsAlreadyLoggedIn |
    LoginCallback |
    LoginCallbackSuccess |
    LoginCallbackFailed |
    LoginUserNotAllowed |
    LogoutSuccessful |
    LogoutFailed |
    CreateUser |
    RequireMspCreation |
    MspNotFullySetup |
    SetCallbackDto |
    UpdateIsAuthenticated |
    GetProfile |
    GetProfileSuccessful |
    UpdateMspNceSettings;
