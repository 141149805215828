import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { map, skip } from "rxjs/operators";
import { LicenseState } from "src/app/licenses/store/reducers/licenses.reducer";
import { LoadNotifications } from "src/app/notification/notification/actions/notification.actions";
import { getNotificationsForTable } from "src/app/notification/notification/reducers/notification.reducer";

import { Notification } from "./../../models/notification.model";

@Component({
    selector: "app-notification-view",
    templateUrl: "./notification-view.component.html",
    styleUrls: ["./notification-view.component.scss"]
})
export class NotificationViewComponent implements OnInit {

    constructor(private store: Store < LicenseState > ) {}

    form: FormGroup;

    notifications$ = this.store.select(getNotificationsForTable);

    @ViewChild(CdkVirtualScrollViewport, {static: false}) viewport: CdkVirtualScrollViewport;

    currentPage = 1;

    endOfList = false;

    ngOnInit() {
        this.notifications$.pipe(
            skip(1),
            map(result => {
            if (this.currentPage * 10 === result.length) {
                this.currentPage++;
                this.getNextBatch(true);
            } else {
                this.endOfList = true;
            }
        })).subscribe();
    }

    getNotifications() {
        this.store.dispatch(new LoadNotifications(this.currentPage));
    }

    getNextBatch(event: any) {
        if (this.endOfList) {
            return;
        }
        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();
        if (end === total) {
            this.getNotifications();
        }
    }

    getNotificationDisplayText(notification: Notification) {
        let text = notification.message;
        if (notification.mspPsaClientName) {
            text += ` for ${notification.mspPsaClientName}`;
        }
        return text;
    }

}
