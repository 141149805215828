import {Component, Inject, Input, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from "@angular/material";
import {DisplayOptions, MessageInformationLevels, SystemMessage} from "src/app/admin/models/systemMessage.model";
import {NotificationService} from "src/app/notification/services/notification.service";

@Component({
    selector: "app-system-message-popup",
    templateUrl: "system-message-popup.html",
    styleUrls: ["./system-message-display.component.scss"]
})

export class SystemMessagePopupComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public displayMessage: DisplayMessage) {
    }
}


export class DisplayMessage {
    id: number;
    message: string;
    icon: string;
    class: string;
}

@Component({
    selector: "app-system-message-display",
    templateUrl: "./system-message-display.component.html",
    styleUrls: ["./system-message-display.component.scss"]
})
export class SystemMessageDisplayComponent implements OnInit {
    @Input()
    systemMessages: SystemMessage[];

    headerMessages: SystemMessage[];
    popupMessages: SystemMessage[];
    popupDisplayMessages: DisplayMessage[];

    displayMessages: DisplayMessage[];

    constructor(private notificationService: NotificationService,
                public dialog: MatDialog) {
    }

    ngOnInit() {
        // Split up messages into popup and header\
        this.headerMessages = this.systemMessages.filter(x => x.displayOption === DisplayOptions.HeaderMessage);

        this.popupMessages = this.systemMessages.filter(x => x.displayOption === DisplayOptions.Popup);

        this.setupDisplayMessages();

        if (this.popupDisplayMessages.length > 0) {
            this.popupMessage();
        }

    }

    getIcon(systemMessage: SystemMessage) {
        switch (systemMessage.infoLevel) {
            case MessageInformationLevels.Informative:
                return "info";
            case MessageInformationLevels.Warning:
                return "priority_high";
            case MessageInformationLevels.Critical:
                return "cancel";
        }
    }

    getCssClass(systemMessage: SystemMessage) {
        switch (systemMessage.infoLevel) {
            case MessageInformationLevels.Informative:
                return "informative";
            case MessageInformationLevels.Warning:
                return "warning";
            case MessageInformationLevels.Critical:
                return "critical";
        }
    }

    setupDisplayMessages() {
        this.displayMessages = [];
        this.headerMessages.forEach(message => {
            const displayMessage = new DisplayMessage();
            displayMessage.message = message.message;
            displayMessage.icon = this.getIcon(message);
            displayMessage.class = `message_${this.getCssClass(message)}`;
            this.displayMessages.push(displayMessage);
        });

        this.popupDisplayMessages = [];
        this.popupMessages.forEach(message => {
            const displayMessage = new DisplayMessage();
            displayMessage.id = message.id;
            displayMessage.message = message.message;
            displayMessage.icon = this.getIcon(message);
            displayMessage.class = `message_${this.getCssClass(message)}`;
            this.popupDisplayMessages.push(displayMessage);
        });
    }

    popupMessage() {
        const message = this.popupDisplayMessages[this.popupDisplayMessages.length - 1];
        console.log(message);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            data: message
        };


        const dialogRef = this.dialog.open(SystemMessagePopupComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            // Save message as marked
            this.notificationService.markSystemMessageAsRead(message.id).subscribe();
            // Clear message from popup messages
            this.popupDisplayMessages.splice(this.popupDisplayMessages.length - 1);
            // Recall popup messages if there is another one.
            if (this.popupDisplayMessages.length > 0) {
                this.popupMessage();
            }
        });
    }
}



