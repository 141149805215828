import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { LoginState } from "src/app/login/store/reducers/login.reducer";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

    constructor(private authStore: Store < LoginState > , private router: Router) {}
    ngOnInit(): void {
        if (false === location.href.includes("localhost") && location.protocol !== "https:") {
            location.href = "https:" + window.location.href.substring(window.location.protocol.length);
        }
    }
}
