
export class DictionaryHolder  {
    dictionary: {[id: string]: string};

    setupAsAutotaskDictionary() {
        this.dictionary = {
            PSA: "Autotask",
            Agreement: "Contract",
            Product: "Service"
        };
    }

    setupAsConnectwiseDictionary() {
        this.dictionary = {
            PSA: "ConnectWise",
            Agreement: "Agreement",
            Product: "Product"
        };
    }
}
