import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { BehaviorSubject } from "rxjs";

import { LoadingDisplay, LoadingStatuses } from "./../models/loading-display.model";

@Injectable({
    providedIn: "root"
})
export class LoadingService {
    public loadingArray: LoadingDisplay[] = [];
    public loadingSubject = new BehaviorSubject<LoadingDisplay[]>([]);
    successDisplayTime = 2000;
    public loadingTextArray = [];

    constructor(actions$: Actions) {
        actions$.subscribe(action => {
            // Add or remove existing actions based on text
            // Split action on |
            // First part is area, second part is message, third part is status
            const splitAction = action.type.split("|");
            if (splitAction.length !== 3) {
                return;
            }

            const actionText = splitAction[1].trim();
            const actionStatus = splitAction[2].trim().toLowerCase();

            switch (actionStatus) {
                case "ongoing": {
                    if (this.loadingTextArray.find(x => x === actionText) === undefined) {
                        this.loadingTextArray.push(actionText);
                    }
                    break;
                }
                case "failed":
                case "success": {
                    // Find connected item
                    const index = this.loadingTextArray.findIndex(x => x === actionText);
                    if (index > -1) {
                        this.loadingTextArray.splice(index, 1 );
                    }
                    break;
                }
            }
        });
    }

    public addNewLoadingItem(loadingItem: LoadingDisplay) {
        // Check for duplicates
        const existingItem = this.loadingArray.find(x => x.display === loadingItem.display);
        if (existingItem) {
            this.removeLoadingItem(loadingItem);
        }

        this.loadingArray.push(loadingItem);
        this.loadingSubject.next(this.loadingArray);
    }

    public removeLoadingWithSuccess(loadingItem: LoadingDisplay) {
        const item = this.loadingArray.find(x => x === loadingItem);
        if (item !== undefined) {
            item.status = LoadingStatuses.success;
            setTimeout(() => {
                this.removeLoadingItem(item);
                // Call Mat Snackbar for success Message
            }, this.successDisplayTime);
        }
    }

    public updateLoadingToFailed(loadingItem: LoadingDisplay) {
        const item = this.loadingArray.find(x => x === loadingItem);
        if (item !== undefined) {
            item.status = LoadingStatuses.failed;
            // Call Mat Snackbar for failure message
        }
    }

    public removeLoadingItem(loadingItem: LoadingDisplay) {
        const index = this.loadingArray.findIndex(x => x === loadingItem);

        if (index > -1) {
            this.loadingArray.splice(index, 1);
            this.loadingSubject.next(this.loadingArray);
        }
    }
}
