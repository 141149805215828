import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { InvoiceVariable } from "../../models/invoice-variable.model";

@Component({
    selector: "app-invoice-description-edit-view",
    templateUrl: "./invoice-description-edit-view.component.html",
    styleUrls: ["./invoice-description-edit-view.component.scss"]
})
export class InvoiceDescriptionEditViewComponent implements OnInit {
    @Input()
    invoiceText = "";

    @Input()
    invoiceVariables: InvoiceVariable[] = [];

    @Input()
    isModal = false;

    @Output()
    saveClicked: EventEmitter<string> = new EventEmitter();

    form: FormGroup;

    caretPosition: number;

    isInTextArea = false;

    variablesDisplayedColumns = ["key", "example"];
    variableDataSource: MatTableDataSource<InvoiceVariable>;
    constructor(private modal: NgbActiveModal) { }

    ngOnInit() {
        this.variableDataSource = new MatTableDataSource(this.invoiceVariables);

        this.setupForm();
    }

    setupForm() {
        this.form = new FormGroup({
            invoiceText: new FormControl(this.invoiceText, Validators.maxLength(250)),
            invoiceDisplay: new FormControl("")
        });

        this.form.get("invoiceText")
            .valueChanges.subscribe(result => {
                this.generateDisplayInvoiceText();
            });

        this.generateDisplayInvoiceText();
    }

    saveCaretPosition() {
        const textArea = document.getElementById("description-input") as HTMLInputElement;
        if (document.activeElement !== textArea) {
            this.isInTextArea = false;
            return;
        }
        this.isInTextArea = true;
        this.caretPosition = textArea.selectionEnd;
    }

    setCaretPositionForTextArea(variable: string) {
        const textArea = document.getElementById("description-input") as HTMLInputElement;
        const end = textArea.selectionEnd + variable.length;
        textArea.focus();
        setTimeout(() => {
            textArea.selectionStart = end;
            textArea.selectionEnd = end;
        }, 0);
    }

    addVariable(variableRow: InvoiceVariable) {
        let invoiceText = this.form.get("invoiceText")
            .value as string;
        if (this.isInTextArea) {
            // Add invoice text to form
            // tslint:disable-next-line: max-line-length
            invoiceText = invoiceText.substring(0, this.caretPosition) + variableRow.key + invoiceText.substring(this.caretPosition,
                invoiceText.length);
            this.setCaretPositionForTextArea(variableRow.key);
        } else {
            invoiceText += variableRow.key;
        }

        this.form.get("invoiceText")
            .setValue(invoiceText);
    }

    generateDisplayInvoiceText() {
        let invoiceText = this.form.get("invoiceText")
            .value as string;

        const variableNames = this.invoiceVariables.map(x => x.key);

        variableNames.forEach(variable => {
            const replacement = new RegExp(variable, "g");
            invoiceText = invoiceText.replace(replacement, this.invoiceVariables.find(x => x.key === variable).example);
        });

        this.form.get("invoiceDisplay")
            .setValue(invoiceText);
    }

    save() {
        const invoiceText = this.form.get("invoiceText")
            .value;

        if (this.isModal) {
            this.modal.close(invoiceText);
        } else {
            this.saveClicked.emit(invoiceText);
        }
    }

    reset() {
        this.form.get("invoiceText")
            .setValue(this.invoiceText);
    }
}


