import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {BillingProfile} from "src/app/billing/models/billing-profile.model";
import {AgreementType, Product} from "src/app/billing/models/product-mapping.model";
import {ItemProfile} from "src/app/item/models/itemProfile.model";
import {AgreementProductProfileDto} from "src/app/licenses/models/agreementProductProfileDto.model";
import {InvoiceSyncAuditLogStatuses} from "src/app/shared/enums/statuses.enum";
import {ProfileTypes} from "src/app/shared/enums/types.enum";
import {PsaClientDto} from "src/app/shared/models/psa-client-dto";

import {InvoiceProductMapping, InvoiceProductMappingMethod} from "../../models/invoiceProductMapping.model";
import {InvoiceSyncAuditLog} from "../../models/invoiceSyncAuditLog.model";
import {SubscriptionPsaMappingData} from "../../models/subscriptionPsaMappingData.model";
import * as actions from "../actions/invoices.actions";
import {ProductMapping} from "./../../../billing/models/product-mapping.model";
import {ItemOverride} from "./../../../item/models/itemOverride.model";
import {PsaClientsItemOverrides} from "./../../../item/models/psaClientsItemOverrides.model";
import {ClientAgreement} from "./../../../licenses/models/agreementProductProfileDto.model";
import {Invoice, InvoiceClient, MappedInvoiceDetailLineItems} from "./../../models/invoice.model";
import {InvoiceSyncHistory} from "./../../models/invoiceSyncHistory";
import {InvoicesActionTypes} from "./../actions/invoices.actions";

export const invoicesFeatureKey = "invoices";

export const invoiceAdapter = createEntityAdapter<Invoice>({});

export interface InvoiceState extends EntityState<Invoice> {
    loading: boolean;
    loadingProductMapping: boolean;
    agreements: AgreementType[];
    billingProfile: BillingProfile;
    existingAgreements: ClientAgreement[];
    existingClientsOverrides: PsaClientsItemOverrides[];
    invoiceSyncAuditLogs: InvoiceSyncAuditLog[];
    invoiceSyncHistories: InvoiceSyncHistory[];
    itemOverrideOptions: ItemOverride[];
    psaClientOptions: PsaClientDto[];
    psaProducts: Product[];
}

export const defaultInvoices: InvoiceState = {
    ids: [],
    entities: {},
    loading: false,
    loadingProductMapping: false,
    agreements: [],
    billingProfile: null,
    existingAgreements: [],
    existingClientsOverrides: [],
    invoiceSyncAuditLogs: [],
    invoiceSyncHistories: [],
    itemOverrideOptions: [],
    psaClientOptions: [],
    psaProducts: []
};

export const initialState: InvoiceState = invoiceAdapter.getInitialState(defaultInvoices);

export function InvoicesReducer(state = initialState, action: actions.InvoicesActions): InvoiceState {
    let invoice: Invoice;
    let newClientsOverrides: PsaClientsItemOverrides[];

    switch (action.type) {
        case InvoicesActionTypes.LoadInvoices:
            return invoiceAdapter.removeAll({
                ...state,
                loading: true
            });

        case InvoicesActionTypes.LoadInvoicesSuccess:
            const invoices: Invoice[] = action.data[0];
            const syncHistories: InvoiceSyncHistory[] = action.data[1];

            return invoiceAdapter.addAll(invoices, {
                ...state,
                invoiceSyncHistories: syncHistories,
                loading: false
            });

        case InvoicesActionTypes.LoadInvoiceDetails:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.LoadInvoiceDetailsSuccess:
            invoice = action.payload[0];
            const psaClientOptions: PsaClientDto[] = action.payload[1];

            let billingProfile: BillingProfile = null;
            let psaProducts: Product[] = [];

            let itemOverrideOptions: ItemOverride[] = [];
            let clientOverrides: PsaClientsItemOverrides[] = [];

            switch (action.profileType) {
                case ProfileTypes.product:
                    billingProfile = action.payload[2];
                    psaProducts = action.payload[3];
                    break;
                case ProfileTypes.item:
                    itemOverrideOptions = action.payload[2];
                    clientOverrides = action.payload[3];
                    break;
            }

            return invoiceAdapter.upsertOne(invoice, {
                ...state,
                loading: false,
                billingProfile,
                existingClientsOverrides: clientOverrides,
                itemOverrideOptions,
                psaClientOptions,
                psaProducts
            });

        case InvoicesActionTypes.SyncInvoice:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.SyncInvoiceSuccess:
            return invoiceAdapter.upsertOne(action.invoice, {
                ...state,
                loading: false
            });

        case InvoicesActionTypes.SyncInvoiceFailed:
            return {
                ...state,
                loading: false
            };

        case InvoicesActionTypes.SaveClients:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.SaveClientsSuccess:
            // Create new invoice to update client's psaClientDetails
            invoice = JSON.parse(JSON.stringify(state.entities[action.invoiceId]));
            const newPsaClientList: PsaClientDto[] = action.payload;
            newPsaClientList.forEach(newPsaClient => {
                const tenantId: string = newPsaClient.cspId;
                for (const invoiceClient of invoice.clients) {
                    if (invoiceClient.dickerDataClientDetails.tenantId === tenantId) {
                        invoiceClient.psaClientDetails = newPsaClient;
                        break;
                    }
                }
            });

            return invoiceAdapter.upsertOne(invoice, {
                ...state,
                loading: false
            });

        case InvoicesActionTypes.SaveClientsFailed:
            return {
                ...state,
                loading: false
            };

        case InvoicesActionTypes.LoadProductMapping:
            return {
                ...state,
                agreements: [],
                existingAgreements: [],
                loadingProductMapping: true
            };

        case InvoicesActionTypes.LoadProductMappingSuccess:
            return {
                ...state,
                agreements: action.payload[0],
                existingAgreements: action.payload[1],
                loadingProductMapping: false
            };

        case InvoicesActionTypes.LoadProductMappingFailed:
            return {
                ...state,
                loadingProductMapping: false
            };

        case InvoicesActionTypes.SaveInvoiceProductMapping:
            return {
                ...state,
                loadingProductMapping: true
            };

        case InvoicesActionTypes.SaveInvoiceProductMappingSuccess:
            // Create new invoice to update the productMappings
            invoice = JSON.parse(JSON.stringify(state.entities[action.invoiceId]));
            const invoiceClient: InvoiceClient = invoice.clients.find(x => x.psaClientDetails && (x.psaClientDetails.id === action.clientId));
            const mappedItems: MappedInvoiceDetailLineItems[] = invoiceClient.mappedInvoiceDetailLineItems;
            const invoiceProductMappings: InvoiceProductMapping[] = action.payload;
            let newBillingProfile = state.billingProfile;

            for (const mappedItem of mappedItems) {
                let productMapping: ProductMapping;

                const foundInvoiceProductMapping = invoiceProductMappings.find(x => x.stockCode === mappedItem.stockCode);

                if (!foundInvoiceProductMapping) {
                    continue;
                }

                switch (foundInvoiceProductMapping.method) {
                    case (InvoiceProductMappingMethod.addProductProfile):
                    case (InvoiceProductMappingMethod.fullMapping):
                        const productDto: ProductMapping = foundInvoiceProductMapping.productDto;
                        productMapping = newBillingProfile.productMappings.find(x =>
                            x.id === productDto.id);

                        // Update billingProfile
                        if (!productMapping) {
                            newBillingProfile = {
                                ...newBillingProfile,
                                productMappings: newBillingProfile.productMappings.concat(productDto)
                            };
                        }

                        if (foundInvoiceProductMapping.method === InvoiceProductMappingMethod.addProductProfile) {
                            break;
                        }
                        break;

                    case (InvoiceProductMappingMethod.mapProductAgreement):
                    case (InvoiceProductMappingMethod.fullMapping):
                    case (InvoiceProductMappingMethod.updateProductAgreement):
                        const agreementProductProfileDto: AgreementProductProfileDto = foundInvoiceProductMapping.agreementProductProfileDto;

                        productMapping = newBillingProfile.productMappings.find(x =>
                            x.vendorProductId === mappedItem.stockCode);

                        const productProfile = productMapping.productProfiles.find(x =>
                            x.id === agreementProductProfileDto.productProfileId);

                        if (!productMapping) {
                            break;
                        }

                        // Get PsaAgreementName
                        let foundPsaAgreementName = "";

                        const allAgreementTypes = state.agreements;
                        for (const agreementType of allAgreementTypes) {
                            if (agreementType.agreements) {
                                const foundPsaAgreement = agreementType.agreements.find(x =>
                                    x.id === foundInvoiceProductMapping.psaAgreementId);
                                if (foundPsaAgreement) {
                                    foundPsaAgreementName = foundPsaAgreement.name;
                                    break;
                                }
                            }
                        }

                        // Build SubscriptionPsaMappingData model
                        mappedItem.subscriptionPsaMappingData = {
                            psaAgreementId: foundInvoiceProductMapping.psaAgreementId,
                            psaAgreementName: foundPsaAgreementName,
                            psaProductId: productMapping.productId,
                            productProfileAgreementId: agreementProductProfileDto.id,
                            productName: productMapping.productName,
                            price: productProfile.price,
                            profileId: productProfile.id,
                            margin: productProfile.margin
                        };

                        break;
                }
            }

            return invoiceAdapter.upsertOne(invoice, {
                ...state,
                billingProfile: newBillingProfile,
                loadingProductMapping: false
            });

        case InvoicesActionTypes.SaveInvoiceProductMappingFailed:
            return {
                ...state,
                loadingProductMapping: false
            };

        case InvoicesActionTypes.DeleteInvoiceProductMapping:
            return {
                ...state,
                loadingProductMapping: true
            };

        case InvoicesActionTypes.DeleteInvoiceProductMappingSuccess:
            // Clone the invoice to avoid mutating the state directly
            invoice = JSON.parse(JSON.stringify(state.entities[action.invoiceId]));
            // Find the client within the invoice
            const invoiceClientToDelete = invoice.clients.find(x => x.psaClientDetails && (x.psaClientDetails.id === action.clientId));
            if (invoiceClientToDelete) {
                const mappedItemIndex = invoiceClientToDelete.mappedInvoiceDetailLineItems.findIndex(
                    x => x.subscriptionPsaMappingData && x.subscriptionPsaMappingData.productProfileAgreementId === action.profileId);
                // Check if the mapped item is found and set its subscriptionPsaMappingData to null
                if (mappedItemIndex !== -1) {
                    invoiceClientToDelete.mappedInvoiceDetailLineItems[mappedItemIndex].subscriptionPsaMappingData = null;
                }
            }

            return invoiceAdapter.upsertOne(invoice, {
                ...state,
                loadingProductMapping: false
            });

        case InvoicesActionTypes.DeleteInvoiceProductMappingFailed:
            return {
                ...state,
                loadingProductMapping: false
            };

        case InvoicesActionTypes.RemoveItemOverrides:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.RemoveItemOverridesSuccess:
            newClientsOverrides = [...state.existingClientsOverrides];
            newClientsOverrides = newClientsOverrides.filter(x => !action.itemOverrideIds.includes(x.id));
            return {
                ...state,
                existingClientsOverrides: newClientsOverrides,
                loading: false
            };


        case InvoicesActionTypes.RemoveItemOverridesFailed:
            return {
                ...state,
                loading: false
            };
        case InvoicesActionTypes.LoadInvoiceSyncLogs:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.LoadInvoiceSyncLogsSuccess:
            invoice = action.data[0];
            const invoiceSyncAuditLogs: InvoiceSyncAuditLog[] = action.data[1];

            return invoiceAdapter.upsertOne(invoice, {
                ...state,
                loading: false,
                invoiceSyncAuditLogs
            });

        case InvoicesActionTypes.LoadInvoiceSyncLogsFailed:
            return {
                ...state,
                loading: false
            };

        case InvoicesActionTypes.SaveMappedItems:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.SaveMappedItemsSuccess:
            // Create new invoice to update client's mappedItems
            invoice = JSON.parse(JSON.stringify(state.entities[action.invoiceId]));

            // VendorProductId as key and ItemProfile for value
            const itemProfileMap: Map<string, ItemProfile> = new Map(
                action.itemProfiles.map(itemProfile => [itemProfile.vendorProductId, itemProfile])
            );

            invoice.clients.forEach((client: InvoiceClient) => {
                client.mappedInvoiceDetailLineItems.forEach((mappedItem: MappedInvoiceDetailLineItems) => {
                    const itemProfile = itemProfileMap.get(mappedItem.stockCode);

                    if (itemProfile) {
                        const subscription: SubscriptionPsaMappingData = {
                            profileId: itemProfile.id,
                            productName: itemProfile.profileName,
                            price: itemProfile.price,
                            margin: itemProfile.margin,

                            // Not used for Item Profile
                            productProfileAgreementId: 0,
                            psaProductId: 0,
                            psaAgreementId: 0,
                            psaAgreementName: ""
                        };

                        mappedItem.subscriptionPsaMappingData = subscription;
                    }
                });
            });

            return invoiceAdapter.upsertOne(invoice, {
                ...state,
                loading: false
            });

        case InvoicesActionTypes.SaveMappedItemsFailed:
            return {
                ...state,
                loading: false
            };

        case InvoicesActionTypes.AddOrUpdateInvoiceClientsOverrides:
            return {
                ...state,
                loading: true
            };

        case InvoicesActionTypes.AddOrUpdateInvoiceClientsOverridesSuccess:
            const newExistingClientsOverrides = [...state.existingClientsOverrides, ...action.clientsOverrides];

            return {
                ...state,
                loading: false,
                existingClientsOverrides: newExistingClientsOverrides
            };

        case InvoicesActionTypes.AddOrUpdateInvoiceClientsOverridesFailed:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}

// ---- SELECTORS ----
export const getInvoiceState = createFeatureSelector<InvoiceState>(invoicesFeatureKey);

export const agreements = (state: InvoiceState) => state.agreements;

export const billingProfile = (state: InvoiceState) => state.billingProfile;

export const existingAgreements = (state: InvoiceState) => state.existingAgreements;

export const existingClientsOverrides = (state: InvoiceState) => state.existingClientsOverrides;

export const invoiceSyncAuditLogs = (state: InvoiceState) => state.invoiceSyncAuditLogs;

export const invoiceSyncHistories = (state: InvoiceState) => state.invoiceSyncHistories;

export const itemOverrideOptions = (state: InvoiceState) => state.itemOverrideOptions;

export const psaClientOptions = (state: InvoiceState) => {
    const clientOptions = [...state.psaClientOptions];
    const sortedClients = clientOptions.sort((a, b) => {
        if (a.psaClientName < b.psaClientName) {
            return -1;
        }
        if (a.psaClientName > b.psaClientName) {
            return 1;
        }
        return 0;
    });

    return sortedClients;
};

export const psaProducts = (state: InvoiceState) => state.psaProducts;

export const getAgreements = createSelector(
    getInvoiceState,
    agreements
);

export const getBillingProfile = createSelector(
    getInvoiceState,
    billingProfile
);

export const getExistingAgreements = createSelector(
    getInvoiceState,
    existingAgreements
);

export const getExistingClientsOverrides = (psaClientId: number, canGetAll: boolean = false) => createSelector(
    getInvoiceState,
    (state: InvoiceState) => {
        let filteredClientsOverrides = state.existingClientsOverrides;

        if (!canGetAll) {
            filteredClientsOverrides = filteredClientsOverrides.filter(x => x.psaClientId === psaClientId);
        }

        return filteredClientsOverrides;
    }
);

export const getInvoiceSyncAuditLogs = createSelector(
    getInvoiceState,
    invoiceSyncAuditLogs
);

export const getInvoiceSyncHistories = createSelector(
    getInvoiceState,
    invoiceSyncHistories
);

export const getItemOverrideOptions = createSelector(
    getInvoiceState,
    itemOverrideOptions
);

export const getPsaClientOptions = createSelector(
    getInvoiceState,
    psaClientOptions
);

export const getPsaProducts = createSelector(
    getInvoiceState,
    psaProducts
);

export const isLoading = createSelector(
    getInvoiceState,
    (state) => state.loading
);

export const isLoadingProductMapping = createSelector(
    getInvoiceState,
    (state) => state.loadingProductMapping
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = invoiceAdapter.getSelectors(getInvoiceState);

export const selectInvoiceEntity = invoiceId => createSelector(
    selectEntities,
    entities => entities[invoiceId]
);


export const getInvoiceClient = (invoiceId, tenantId) => createSelector(
    getInvoiceState,
    (state: InvoiceState) => {
        const invoice: Invoice = state.entities[invoiceId];
        return invoice.clients.find(invoiceClient =>
            invoiceClient.dickerDataClientDetails.tenantId === tenantId);
    }
);

export const getInvoiceSyncPsaClientLogs = (tenantId: string, showFailedResults: boolean) => createSelector(
    getInvoiceState,
    (state: InvoiceState) => {
        let syncLogs: InvoiceSyncAuditLog[] = [];
        syncLogs = state.invoiceSyncAuditLogs.filter(x =>
            (x.tenantId === tenantId)
            && (!showFailedResults || x.status === InvoiceSyncAuditLogStatuses.failed)
        );

        return syncLogs;
    }
);

// Aim to filter out the clientArray with searchText
const getInvoiceClientArray = (searchText: string, clientArray: InvoiceClient[]) => {
    searchText = searchText.toLowerCase().trim();

    clientArray = clientArray.filter(x => {
        return (
            x.psaClientDetails && x.psaClientDetails.psaClientName.toLowerCase().includes(searchText) ||
            x.dickerDataClientDetails.name.toLowerCase().includes(searchText)
        );
    });

    clientArray = clientArray.sort((n1, n2) => {
        const invoiceClientOneName = n1.psaClientDetails ? n1.psaClientDetails.psaClientName : n1.dickerDataClientDetails.name;
        const invoiceClientTwoName = n2.psaClientDetails ? n2.psaClientDetails.psaClientName : n2.dickerDataClientDetails.name;

        if (invoiceClientOneName > invoiceClientTwoName) {
            return 1;
        }

        if (invoiceClientOneName < invoiceClientTwoName) {
            return -1;
        }

        return 0;
    });

    return clientArray;
};

export const getFailedInvoiceSyncLogs = (invoiceId: number, showFailedResults: boolean, searchText: string) => createSelector(
    getInvoiceState,
    (state: InvoiceState) => {
        let returnClientArray: InvoiceClient[] = [];
        const invoiceClients: InvoiceClient[] = state.entities[invoiceId].clients;
        if (showFailedResults) { // filter customers that have failed invoice sync logs
            for (const invoiceClient of invoiceClients) {
                const failedSyncLogs: InvoiceSyncAuditLog[] = state.invoiceSyncAuditLogs.filter(x =>
                    x.tenantId === invoiceClient.dickerDataClientDetails.tenantId
                    && x.status === InvoiceSyncAuditLogStatuses.failed);

                if (failedSyncLogs.length > 0) {
                    returnClientArray.push(invoiceClient);
                }
            }
        } else { // show all customers
            returnClientArray = invoiceClients;
        }

        returnClientArray = getInvoiceClientArray(searchText, returnClientArray);

        return returnClientArray;
    }
);

export const getUnmappedCustomers = (invoiceId: number, showUnmappedCustomers: boolean, searchText: string) => createSelector(
    getInvoiceState,
    (state: InvoiceState) => {
        let returnClientArray: InvoiceClient[] = [];
        const invoiceClients: InvoiceClient[] = state.entities[invoiceId].clients;
        if (showUnmappedCustomers) { // filter unmapped customers
            for (const invoiceClient of invoiceClients) {
                // check if the client is unmapped
                if (invoiceClient.psaClientDetails === null) {
                    returnClientArray.push(invoiceClient);
                    continue;
                }

                // check if one of the products is unmapped
                const items: MappedInvoiceDetailLineItems[] = invoiceClient.mappedInvoiceDetailLineItems;
                for (const item of items) {
                    if (item.subscriptionPsaMappingData === null) {
                        returnClientArray.push(invoiceClient);
                        break;
                    }
                }
            }
        } else { // show all customers
            returnClientArray = invoiceClients;
        }

        returnClientArray = getInvoiceClientArray(searchText, returnClientArray);

        return returnClientArray;
    }
);
