import { Pipe, PipeTransform } from "@angular/core";
import { Client } from "src/app/client/models/client.model.js";

@Pipe({
  name: "clientNameFilter"
})

export class ClientNameFilterPipe implements PipeTransform {
  transform(clients: Client[], filter: any): any {
    filter = filter.toLowerCase();
    const matchingClientsViaName =  clients.filter(client => client.companyName.toLowerCase().includes(filter));

    // Check in the psa client reference names
    const clientsWithPsaReferences = clients.filter(client => client.psaClientReference !== null);

    const matchingClientsViaReference = clientsWithPsaReferences.filter(
        client => client.psaClientReference.psaClientName.toLowerCase().includes(filter)
        && false === matchingClientsViaName.includes(client)
    );

    return matchingClientsViaName.concat(matchingClientsViaReference);
  }
}
